export default {
    purchaseAmount: {
        'ui:title': 'Purchase Amount',
    },
    purchaseDate: {
        'ui:title': 'Purchase Date',
        classNames: 'field-date',
        'ui:widget': 'alt-date',
        'ui:options': {
            hideNowButton: true,
            hideClearButton: true,
        },
    },
    anticipatedValuationMin: {
        'ui:title': 'Anticipated Valuation (Lowest $)',
    },
    anticipatedValuationMax: {
        'ui:title': 'Anticipated Valuation (Highest $)',
    },
    condition: {
        classNames: 'field-select',
        'ui:title': 'Overall condition',
        'ui:placeholder': 'Select a vehicle condition',
        'ui:widget': 'select',
    },
};
