export default {
    type: 'object',
    required: [
        'whatHappened',
        'discovery',
        'address',
        'lastSeen',
        'housed',
        'locked',
        'keys',
        'keysNow',
        'police',
        'suspicions',
    ],
    properties: {
        whatHappened: {
            type: 'string',
        },
        date: {
            type: 'string',
            format: 'date-time-local',
        },
        // time: {
        //     type: 'string',
        // },
        discovery: {
            type: 'string',
        },
        address: {
            type: 'string',
        },
        lastSeen: {
            type: 'string',
        },
        housed: {
            type: 'object',
            required: ['housedValue'],
            properties: {
                housedValue: {
                    type: 'string',
                    enum: ['Garage', 'Carport', 'Driveway', 'Parking Area', 'Roadside', 'Other'],
                },
            },
            dependencies: {
                housedValue: {
                    oneOf: [
                        {
                            properties: {
                                housedValue: {
                                    enum: [
                                        'Garage',
                                        'Carport',
                                        'Driveway',
                                        'Parking Area',
                                        'Roadside',
                                    ],
                                },
                            },
                        },
                        {
                            required: ['details'],
                            properties: {
                                housedValue: {
                                    enum: ['Other'],
                                },
                                details: {
                                    type: 'string',
                                },
                            },
                        },
                    ],
                },
            },
        },
        locked: {
            type: 'boolean',
        },
        keys: {
            type: 'string',
        },
        keysNow: {
            type: 'string',
        },
        parts: {
            type: 'string',
            // properties: {
            //     onlyParts: {
            //         type: 'boolean',
            //     },
            // },
            // dependencies: {
            //     onlyParts: {
            //         oneOf: [
            //             {
            //                 properties: {
            //                     onlyParts: {
            //                         enum: [false],
            //                     },
            //                 },
            //             },
            //             {
            //                 properties: {
            //                     onlyParts: {
            //                         enum: [true],
            //                     },
            //                     details: {
            //                         type: 'string',
            //                     },
            //                 },
            //             },
            //         ],
            //     },
            // },
            // required: ['onlyParts'],
        },
        police: {
            type: 'object',
            required: ['reported'],
            properties: {
                reported: {
                    type: 'boolean',
                },
            },
            dependencies: {
                reported: {
                    oneOf: [
                        {
                            properties: {
                                reported: {
                                    enum: [false],
                                },
                            },
                        },
                        {
                            properties: {
                                reported: {
                                    enum: [true],
                                },
                                officerName: {
                                    type: 'string',
                                },
                                officerNumber: {
                                    type: 'string',
                                },
                                reportNumber: {
                                    type: 'string',
                                },
                                notified: {
                                    format: 'date',
                                    type: 'string',
                                },
                            },
                            required: ['reportNumber', 'notified'],
                        },
                    ],
                },
            },
        },

        suspicions: {
            type: 'object',
            required: ['haveOffenderSuspicions'],
            properties: {
                haveOffenderSuspicions: {
                    type: 'boolean',
                },
            },
            dependencies: {
                haveOffenderSuspicions: {
                    oneOf: [
                        {
                            properties: {
                                haveOffenderSuspicions: {
                                    enum: [false],
                                },
                            },
                        },
                        {
                            required: ['details'],
                            properties: {
                                haveOffenderSuspicions: {
                                    enum: [true],
                                },
                                details: {
                                    type: 'string',
                                },
                                offenderRelationship: {
                                    type: 'string',
                                },
                            },
                        },
                    ],
                },
            },
        },

        sketches2: {
            type: 'object',
            properties: {
                stepHeading: { type: 'null' },
                stepDescription: { type: 'null' },
                listOfFiles: {
                    type: 'array',
                    title: 'A list of files',
                    minItems: 0,
                    maxItems: 3,
                    items: {
                        type: 'object',
                        properties: {
                            file: {
                                type: 'string',
                                format: 'data-url',
                                title: 'A file',
                            },
                        },
                    },
                },
            },
        },
    },
};
