import 'isomorphic-fetch';
import React, { useState, useEffect } from 'react';

import { Notice, Details, Policy, Ownership, Disclosures, Payment } from './steps';
import { StepProgress } from './components';

import FormDownload from './components/FormDownload';
import Submitting from './components/Submitting';
import formatData from '../../../modules/dataFormater';

const DealerQuoteForm = () => {
    const [formError, setFormError] = useState();
    const [quoteId, setQuoteId] = useState(null);
    const [localData, setLocalData] = useState(null);
    const [step, setStep] = useState(0);
    const [formCreated, setFormCreated] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [sentToSecuredSigning, setSentToSecuredSigning] = useState(false);

    const steps = ['Notice', 'Details', 'Policy', 'Ownership', 'Disclosures', 'Payment'];

    const makeUid = () =>
        Math.random()
            .toString(36)
            .substring(2, 7) +
        Math.random()
            .toString(36)
            .substring(2, 7);

    const stepTitles = [
        'Important notice',
        'Vehicle details',
        'Policy coverage',
        'Ownership',
        'Disclosures',
        'Finish',
    ];

    const getQuoteData = async (currentStep = 5) => {
        const local = JSON.parse(window.localStorage.getItem(`dealerQuote-${quoteId}`));
        let body = formatData({ ...local, quote_id: quoteId });

        console.log('body', body);

        try {
            const response = await fetch('/api/dealer/quote', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...body, step: currentStep }),
            });

            if (response.status === 401) {
                console.log('dealer_id is missing, redirecting to login...');
                window.location = '/dealer';
                return false;
            }

            return response.json();
        } catch (e) {
            console.log('ERROR: ', e);
        }

        return false;
    };

    useEffect(() => {
        if (step > 0 && step !== 6) getQuoteData(step);
    }, [step]);

    const handleError = error => {
        console.log('Error');
        if (error) {
            setFormError();
            console.error(error); // eslint-disable-line no-console
        }
    };

    // const getQuoteData = id => {
    //     // get your own token buddy
    //     const token =
    //         'eyJraWQiOiJHb2pNODlZdlNlNjZlOHJ0bF9rUk8zME9oZm0yR05hZDkyRmJkSktvdkZrIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULnROcmxCUnFLMHY5U2xRX0YxdS1BTzVGWEoxWHNDQmNmMFhRWDdVZGVLeFkiLCJpc3MiOiJodHRwczovL2Rldi0yMzYzMzUub2t0YS5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiJhcGk6Ly9kZWZhdWx0IiwiaWF0IjoxNjExMDg5MjE0LCJleHAiOjE2MTEwOTI4MTQsImNpZCI6IjBvYTEzcHB5ajk0MVE0Q2dvNHg3Iiwic2NwIjpbInBlcnNvbl9hcGkiXSwic3ViIjoiMG9hMTNwcHlqOTQxUTRDZ280eDcifQ.ZqrBPpqT8itehLvnVcT5rW2W6oTtUiyqWe3wkJzpLwLsIemwsckw1nipOAUi1pKqIVgQqUXqvTZTxaTyy9_MOawNO5Zz4svVE4GUtVZDzC9xtseDbQQyky9Kfy0AljPv1bfq6zW9Mp3uSiYzn0bZnefg4o9WhMFEo2AhwLad2beOJkqYG_V3xe72UyPiaB_WH4VAgooVgfI29WaXUQPdBPitluK718uUKZHtnQDDA55Y0Hlo9lccfX0UQEULlH64hum07FR8d87qZLmNfsIdDnUDVE1Y8NTz_2AgH6ABccRG1CGaUD4gz7vHtCTtexMkH9fpdMcE7cLAXWJoQVz12Q';
    //     try {
    //         fetch('/api/dealer/viewquote', {
    //             method: 'POST',
    //             headers: {
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             // body: JSON.stringify({ firstName: person.firstName, lastName: person.lastName }),
    //             body: JSON.stringify({ id }),
    //         }).then(response => {
    //             console.log('response', response.json());
    //             return response.json();
    //         });
    //     } catch (error) {
    //         console.log('ERROR: ', error);
    //         handleError(error);
    //     }
    // };

    useEffect(() => {
        if (localData && localData.step && localData.step < step) {
            setStep(localData.step);
        }
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        let formId = id;

        if (id) {
            setQuoteId(id);
            // getQuoteData(id);
        } else {
            // const uid = uuidv4();
            const uid = makeUid();
            setQuoteId(uid);
            formId = uid;
        }

        setLocalData(JSON.parse(window.localStorage.getItem(`dealerQuote-${formId}`)));
    }, []);

    // const back = () => {
    //   if (step > 0) setStep(step - 1);
    // };

    const saveLocalData = async data => {
        const local = JSON.parse(window.localStorage.getItem(`dealerQuote-${quoteId}`));

        window.localStorage.setItem(
            `dealerQuote-${quoteId}`,
            JSON.stringify({
                ...local,
                ...data,
                step: step + 1,
            })
        );

        setLocalData({
            ...local,
            ...data,
            step: step + 1,
        });
    };

    const saveContinue = () => {
        setStep(step + 1);
        window.scrollTo(0, 0);
    };

    const saveFinish = () => {
        window.scrollTo(0, 0);
        setIsSubmitting(true);

        // const localData = JSON.parse(window.localStorage.getItem(localDataKey));
        const { ownership } = JSON.parse(window.localStorage.getItem(`dealerQuote-${quoteId}`));

        const personData = [];

        ownership.forEach(person => {
            personData.push({
                Title: person.title,
                Gender: person.gender,
                FirstName: person.firstName,
                LastName: person.lastName,
                DateOfBirth: `${person.dob_year}-${person.dob_month}-${person.dob_day}`,
                Address: person.streetAddress,
                Suburb: person.suburb,
                City: person.city,
                Postcode: person.postalCode,
                MobilePhone: person.mobile,
                CountryCode: person.mobileCountryCode,
                Email: person.email,
                LicenceNumber: person.licenseId,
                LicenceType: person.licenseType,
                LicenceDate: `${person.date_issued_year}-${person.date_issued_month}-${person.date_issued_day}`,
                LicenceHeld: person.yearsLicenseHeld,
                RegisteredOwner:
                    person.roleType === 'registered_and_main' ||
                    person.roleType === 'registered_and_addition',
                MainDriver: person.roleType === 'registered_and_main' || person.roleType === 'main',
                AdditionalDriver:
                    person.roleType === 'registered_and_addition' ||
                    person.roleType === 'additional_driver',
                DriverUsage: person.percentageUsed,
            });
        });

        const local = JSON.parse(window.localStorage.getItem(`dealerQuote-${quoteId}`));
        const formData = formatData({ ...local, quote_id: quoteId });

        // get your own token buddy
        const token =
            'eyJraWQiOiJHb2pNODlZdlNlNjZlOHJ0bF9rUk8zME9oZm0yR05hZDkyRmJkSktvdkZrIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULnROcmxCUnFLMHY5U2xRX0YxdS1BTzVGWEoxWHNDQmNmMFhRWDdVZGVLeFkiLCJpc3MiOiJodHRwczovL2Rldi0yMzYzMzUub2t0YS5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiJhcGk6Ly9kZWZhdWx0IiwiaWF0IjoxNjExMDg5MjE0LCJleHAiOjE2MTEwOTI4MTQsImNpZCI6IjBvYTEzcHB5ajk0MVE0Q2dvNHg3Iiwic2NwIjpbInBlcnNvbl9hcGkiXSwic3ViIjoiMG9hMTNwcHlqOTQxUTRDZ280eDcifQ.ZqrBPpqT8itehLvnVcT5rW2W6oTtUiyqWe3wkJzpLwLsIemwsckw1nipOAUi1pKqIVgQqUXqvTZTxaTyy9_MOawNO5Zz4svVE4GUtVZDzC9xtseDbQQyky9Kfy0AljPv1bfq6zW9Mp3uSiYzn0bZnefg4o9WhMFEo2AhwLad2beOJkqYG_V3xe72UyPiaB_WH4VAgooVgfI29WaXUQPdBPitluK718uUKZHtnQDDA55Y0Hlo9lccfX0UQEULlH64hum07FR8d87qZLmNfsIdDnUDVE1Y8NTz_2AgH6ABccRG1CGaUD4gz7vHtCTtexMkH9fpdMcE7cLAXWJoQVz12Q';

        try {
            getQuoteData(6).then(res => {
                fetch('/api/quote/submitQuote', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        quote_id: quoteId,
                        representedDealer: formData.representedDealer,
                    }),
                })
                    .then(response => {
                        return response.json();
                    })
                    .then(response => {
                        if (!response.path) throw Error('No path returned');

                        setFormCreated(response.path);
                        setIsSubmitting(false);
                        setSentToSecuredSigning(!!response.upload);
                    });
            });
        } catch (error) {
            // TODO: REMOVE ONCE API HOOKED UP
            setIsSubmitting(false);
            setSubmissionError(true);
            console.log('ERROR: ', error);
            handleError(error);
        }
    };

    const transformErrors = errors =>
        errors.map(error => {
            /* eslint-disable */
            if (error.name === 'required') error.message = 'Required';
            if (error.name === 'enum') error.message = 'More information required';
            if (error.name === 'oneOf') error.message = '';

            if (error.name === 'format' && error.params.format === 'email') {
                error.message = 'Invalid email address';
            }

            if (error.name === 'maximum' && error.params.limit === 60000) {
                error.message =
                    'should be <= 60000. Please call quote line 0800 111 801 for terms and conditions.';
            }

            /* eslint-enable */

            return error;
        });

    const renderFormStep = () => {
        if (quoteId === null) return <span />;

        switch (step) {
            case 0:
                return (
                    <Notice
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                        quoteId={quoteId}
                    />
                );
            case 1:
                return (
                    <Details
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                        quoteId={quoteId}
                    />
                );
            case 2:
                return (
                    <Policy
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                        quoteId={quoteId}
                    />
                );
            case 3:
                return (
                    <Ownership
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                        quoteId={quoteId}
                    />
                );
            case 4:
                return (
                    <Disclosures
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                        quoteId={quoteId}
                    />
                );
            case 5:
                if (isSubmitting || submissionError) {
                    return <Submitting submissionError={submissionError} />;
                } else if (formCreated) {
                    return (
                        <FormDownload
                            path={formCreated}
                            quoteId={quoteId}
                            sentToSecuredSigning={sentToSecuredSigning}
                        />
                    );
                }

                return (
                    <Payment
                        saveContinue={saveFinish}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                        quoteId={quoteId}
                        getQuoteData={getQuoteData}
                    />
                );
            default:
                return <div>Form complete</div>;
        }
    };

    return (
        <div className="l-multi-step-form">
            <div className="c-wysiwyg">
                <h3 className="c-heading c-heading--h3">{stepTitles[step]}</h3>
                {step === 0 && (
                    <>
                        <p>
                            The following information must be confirmed with any prospective
                            insured. If any of the circumstances apply you must not proceed further
                            with this quote. Please contact Go Cover on{' '}
                            <a href="tel:0800111801">0800 111 801</a> to confirm the cover (if any)
                            we are able to provide.
                        </p>
                        <p>
                            As part of our quality service commitment, we take pride in being
                            upfront and transparent in our dealings.
                        </p>
                    </>
                )}
            </div>
            <StepProgress
                steps={steps}
                currentStep={step}
                setStep={
                    isSubmitting || sentToSecuredSigning
                        ? null
                        : targetStep => () => setStep(targetStep)
                }
            />
            {step === 0 && (
                <p className="notice-note">
                    We take this time to advise that we are unable to provide cover via this online
                    quoting tool for:
                </p>
            )}
            <div
                className={`l-multi-step-form__wrapper ${
                    step === 3 ? 'l-multi-step-form__wrapper_ownership' : undefined
                }`}
            >
                {formError && (
                    <div>
                        <strong>Error:</strong> {formError}
                    </div>
                )}
                {renderFormStep()}
                {/* <div className="fineprint">
                    If any of the above circumstances do apply to the potential insurance cover to
                    be quoted please contact Go Cover on 0800 111 801 to discuss any cover options
                    we may be able to provide.
                </div> */}
                {/* {step > 0 && <button type="button" onClick={back}>Go Back</button>} */}
            </div>
            <StepProgress
                steps={steps}
                currentStep={step}
                setStep={
                    isSubmitting || sentToSecuredSigning
                        ? null
                        : targetStep => () => setStep(targetStep)
                }
            />
        </div>
    );
};

export default DealerQuoteForm;
