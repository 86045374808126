import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';

const Notice = ({ saveContinue, saveLocalData, transformErrors, quoteId }) => {
    const localData = JSON.parse(window.localStorage.getItem(`dealerQuote-${quoteId}`));
    const formData = localData && localData.notice ? localData.notice : {};

    function debounce(func, wait) {
        let timeout;
        return function(...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }

    const validateEmail = email =>
        email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

    const getCurrentUserGroup = async () => {
        try {
            const response = await fetch('/api/dealer/groups', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            });

            return response.json();
        } catch (e) {
            console.log('ERROR: ', e);
        }

        return false;
    };

    const insertAfter = (newElement, referenceElement) => {
        referenceElement.parentNode.insertBefore(newElement, referenceElement.nextSibling);
    };

    const fetchDealerDetails = email => {
        if (!email || email.length < 5) {
            if (document.querySelector('.dealer-details')) {
                document.querySelector('.dealer-details').remove();
                document.getElementById('root_representedDealer').classList.remove('success');
                document.getElementById('root_representedDealer').classList.remove('error');
            }
            return;
        } else if (!validateEmail(email)) {
            updateDealerUI(null, null, true, true);
            return;
        } else if (!email || !email.length) {
            updateDealerUI(null, null, true);
            return;
        }

        fetch('/api/dealer/getDealerDetails', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
            }),
        })
            .then(response => {
                return response.json();
            })
            .then(res => {
                updateDealerUI(res['name'], res['email']);
            });
    };

    const updateDealerUI = (name, email, isEmpty, invalidEmail) => {
        if (!document.getElementById('root_representedDealer')) return;

        if (invalidEmail) {
            if (document.querySelector('.dealer-details')) {
                document.querySelector('.dealer-details').remove();
                document.getElementById('root_representedDealer').classList.remove('success');
                document.getElementById('root_representedDealer').classList.add('error');
            }

            if (document.querySelector('.dealer-details')) {
                document.querySelector(
                    '.dealer-details'
                ).innerHTML = `<p><strong>Error:</strong> Invalid email</p>`;
            } else {
                const iDiv = document.createElement('div');
                iDiv.id = 'block';
                iDiv.className = 'dealer-details';
                iDiv.innerHTML = `<p><strong>Error:</strong> Invalid email</p>`;

                insertAfter(iDiv, document.getElementById('root_representedDealer'));
            }

            return;
        }

        if (!name || !email) {
            if (isEmpty) {
                if (document.querySelector('.dealer-details')) {
                    document.querySelector('.dealer-details').remove();
                    document.getElementById('root_representedDealer').classList.remove('success');
                    document.getElementById('root_representedDealer').classList.remove('error');
                }
            } else if (document.getElementById('root_representedDealer')) {
                document.getElementById('root_representedDealer').classList.remove('success');
                document.getElementById('root_representedDealer').classList.add('error');

                if (document.querySelector('.dealer-details')) {
                    document.querySelector(
                        '.dealer-details'
                    ).innerHTML = `<p><strong>Dealer:</strong> No dealer found</p>`;
                } else {
                    const iDiv = document.createElement('div');
                    iDiv.id = 'block';
                    iDiv.className = 'dealer-details';
                    iDiv.innerHTML = `<p><strong>Dealer:</strong> No dealer found</p>`;

                    insertAfter(iDiv, document.getElementById('root_representedDealer'));
                }
            }

            return;
        }

        if (document.querySelector('.dealer-details')) {
            document.getElementById('root_representedDealer').classList.add('success');
            document.getElementById('root_representedDealer').classList.remove('error');
            document.querySelector(
                '.dealer-details'
            ).innerHTML = `<p><strong>Dealer:</strong> ${name} (${email})</p>`;
        } else {
            const iDiv = document.createElement('div');
            iDiv.id = 'block';
            iDiv.className = 'dealer-details';
            iDiv.innerHTML = `<p><strong>Dealer:</strong> ${name} (${email})</p>`;

            insertAfter(iDiv, document.getElementById('root_representedDealer'));
        }
    };

    useEffect(() => {
        getCurrentUserGroup().then(data => {
            if (data.gocover_member && document.querySelector('.representedDealer-wrapper')) {
                document.querySelector('.representedDealer-wrapper').classList.add('visible');
                document.querySelector('.notice-note').classList.add('hide-notice');

                if (localData && localData.notice.representedDealer)
                    fetchDealerDetails(localData.notice.representedDealer);
            }
        });
    }, []);

    const onSubmit = data => {
        const notice = data.formData;
        // const note = document.getElementById('referral-note');

        window.dataLayer.push({
            event: 'dealerQuoteSubmission',
            step: 1,
        });

        saveLocalData({ notice });

        if (
            notice.criminalRecord === true ||
            notice.insuredLastFiveYears === true ||
            notice.vehicleType === true
        ) {
            window.location.href = '/referral';
            // note.classList.add('alert');
            // window.scrollTo(note.offsetLeft, note.offsetTop);
        } else {
            saveContinue();
        }
    };

    return (
        <>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
                onChange={debounce(value => {
                    if (value.formData) fetchDealerDetails(value.formData.representedDealer);
                }, 250)}
            >
                <p id="referral-note" className="note">
                    If any of the above circumstances apply, this client will need to be referred.
                    Please contact Go&nbsp;Cover on <a href="tel:0800111801">0800 111 801</a> to
                    discuss options.
                </p>
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Notice.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Notice.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
    quoteId: PropTypes.string.isRequired,
};

export default Notice;
