export default {
    berths: {
        'ui:title': 'Number of berths',
    },

    toilet: {
        'ui:title': 'Toilet (Cassette/Flush/Portable)',
        'ui:widget': 'radio',
    },

    shower: {
        'ui:title': 'Shower',
        'ui:widget': 'radio',
    },
};
