const dateRange = (start, end) =>
    Array.from({ length: (end - start) / 1 + 1 }, (_, i) => start + i * 1).reverse();

export default {
    type: 'object',
    required: ['make', 'model', 'cc', 'registration', 'manufactureYear', 'odometer'],
    properties: {
        make: {
            type: 'string',
        },
        model: {
            type: 'string',
        },
        cc: {
            type: 'integer',
        },
        registration: {
            type: 'string',
        },
        manufactureYear: {
            type: 'integer',
            enum: dateRange(1900, new Date().getFullYear()),
        },
        wofCOF: {
            type: 'string',
        },
        odometer: {
            type: 'integer',
        },
    },
};
