import React from 'react';

export default {
    vehicleInformation: {
        require: ['informationType', 'quoteInformation'],
        informationType: {
            require: [
                'identityNumber',
                // 'registration',
                'registrationNumber',
                'year',
                'make',
                'model',
                'type',
                'ccRating',
                'purchasePrice',
            ],
            classNames: 'field-parent vehicle-info-wrapper',
            // hasInformationType: {
            //     classNames: 'field-parent',
            //     'ui:title': (
            //         <span>
            //             <b>01.</b> Vehicle information*
            //         </span>
            //     ),
            //     'ui:widget': 'radio',
            // },
            identityNumber: {
                classNames: 'search-input',
                'ui:title': (
                    <span>
                        <b>01.</b> Vehicle information*
                    </span>
                ),
                'ui:placeholder': 'Enter the vehicle identifier*',
            },
            registration: {
                'ui:placeholder': 'Registration',
                classNames: 'auto-field reg',
            },
            registrationNumber: {
                'ui:title': 'Enter the vehicle identifier*',
            },
            year: {
                'ui:placeholder': 'Year*',
                classNames: 'auto-field year',
            },
            make: {
                'ui:placeholder': 'Make*',
                classNames: 'auto-field make',
            },
            model: {
                'ui:placeholder': 'Model*',
                classNames: 'auto-field model',
            },
            type: {
                'ui:placeholder': 'Type*',
                classNames: 'auto-field type',
            },
            ccRating: {
                'ui:placeholder': 'CC Rating*',
                classNames: 'auto-field cc',
            },
            purchasePrice: {
                classNames: 'purchasePrice-wrapper',
                'ui:placeholder': 'Purchase price*',
            },
        },
    },
    quoteInformation: {
        require: ['storage', 'hasTurbo', 'financingCompany'],
        storage: {
            require: ['storageDetails'],
            storageType: {
                classNames: 'field-parent field-select',
                'ui:title': (
                    <span>
                        <b>02.</b> Where will the vehicle be stored overnight?*
                    </span>
                ),
                'ui:placeholder': 'Please select',
                'ui:widget': 'radio',
            },
            storageDetails: {
                'ui:title': 'Please provide details*',
            },
        },
        hasTurbo: {
            hasTurboValue: {
                classNames: 'field-select field-parent',
                'ui:title': (
                    <span>
                        <b>03.</b> Does the vehicle have a turbo*
                    </span>
                ),
                'ui:widget': 'radio',
            },
            stepSubheading: {
                classNames: 'field-step-note-content',
                'ui:title':
                    'If the driver/s of this vehicle are under 25 years old, this deal will need to be referred to Go Cover for underwriting. Please call us on 0800 111 801 to discuss.',
            },
        },
        fittings: {
            'ui:title': (
                <span>
                    <b>04.</b> Is the vehicle fitted with
                </span>
            ),
            'ui:widget': 'checkboxes',
        },
        financingCompanyDetails: {
            financingCompany: {
                classNames: 'mb-sm',
                'ui:title': 'Who is the financing company?*',
                'ui:placeholder': 'Please choose a financing company',
            },
            otherFinancingCompany: {
                classNames: 'hide-label mt-0',
                'ui:title':
                    "Couldn't find a financing company in this list? Please enter a financing company below.",
                'ui:placeholder': 'Please enter a financing company',
            },
        },
        modificationDetails: {
            modificationOverAGrand: {
                'ui:title': 'Any modifications over $1,000?',
                'ui:description':
                    'Any modification over $1,000 dollars could result in an additional excess during the underwriting process. Call us on 0800 111 801 for more information.',
                'ui:widget': 'checkboxes',
            },
            modificationOverAGrandNote: {
                classNames: 'field-step-note-content modificationOverAGrandNote hide',
                'ui:title': 'Please provide details*',
            },
        },
    },
};
