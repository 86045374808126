export default {
    heading: {
        classNames: 'field-step-subheading',
        'ui:title': 'Please supply at least 3 photographs of the vehicle:',
    },
    photo1: {
        classNames: 'field-upload',
        'ui:title': 'Upload file (JPG, PNG OR PDF)',
        'ui:description': 'Outside, front, showing number plate',
        'ui:options': {
            accept: ['.pdf', '.jpg', '.jpeg', '.png'],
        },
    },
    photo2: {
        classNames: 'field-upload',
        'ui:title': 'Upload file (JPG, PNG OR PDF)',
        'ui:description': 'Inside looking to the rear',
        'ui:options': {
            accept: ['.pdf', '.jpg', '.jpeg', '.png'],
        },
    },
    photo3: {
        classNames: 'field-upload',
        'ui:title': 'Upload file (JPG, PNG OR PDF)',
        'ui:description': 'Inside looking to the front',
        'ui:options': {
            accept: ['.pdf', '.jpg', '.jpeg', '.png'],
        },
    },
    photo4: {
        classNames: 'field-upload',
        'ui:title': 'Upload file (JPG, PNG OR PDF)',
        'ui:description': 'Photo 4',
        'ui:options': {
            accept: ['.pdf', '.jpg', '.jpeg', '.png'],
        },
    },
    photo5: {
        classNames: 'field-upload',
        'ui:title': 'Upload file (JPG, PNG OR PDF)',
        'ui:description': 'Photo 5',
        'ui:options': {
            accept: ['.pdf', '.jpg', '.jpeg', '.png'],
        },
    },
    photo6: {
        classNames: 'field-upload',
        'ui:title': 'Upload file (JPG, PNG OR PDF)',
        'ui:description': 'Photo 6',
        'ui:options': {
            accept: ['.pdf', '.jpg', '.jpeg', '.png'],
        },
    },
};
