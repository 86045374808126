import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';
import { PHONE_NUMBERS_VALIDATION_MESSAGE } from '../../../../../config';

const Driver = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('accident'));
    const formData = localData && localData.driver ? localData.driver : {};

    const onSubmit = data => {
        const driver = data.formData;

        saveLocalData({ driver });

        window.dataLayer.push({
            event: 'accidentClaimSubmission',
            step: 3,
            driver_incharge: true,
            driver_name: driver.driver.fullName,
            driver_date_of_birth: driver.driver.dob,
            // driver_vehicle_appropriate_license: driver.licence.appropriateClass,
            // driver_endorsed: driver.licence.appropriateEndorsements,
            driver_license_conditions: driver.licence.conditions.conditionsBool,
            driver_license_conditions_details: driver.licence.conditions.conditionsDetails || '',
            driver_license_type: driver.licence.type,
            driver_license_country: driver.licence.country,
        });

        saveContinue();
    };

    const validate = (data, errors) => {
        const { driver } = data;

        if (driver.phone && (driver.phone.length < 8 || driver.phone.length > 15)) {
            errors.driver.phone.addError(PHONE_NUMBERS_VALIDATION_MESSAGE);
        }

        return errors;
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    3.1. Person driving the insured vehicle (driver)
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
                validate={validate}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Driver.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Driver.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Driver;
