export default {
    definitions: {
        policyHolderBasicInfo: {
            type: 'object',
            required: ['firstName', 'lastName', 'dob'],
            properties: {
                stepSubheading: { type: 'null' },
                firstName: {
                    type: 'string',
                },
                lastName: {
                    type: 'string',
                },
                dob: {
                    type: 'string',
                    format: 'date',
                },
            },
        },
    },
    type: 'object',
    properties: {
        stepDescription: { type: 'null' },
        policyHolder: {
            type: 'object',
            required: ['addressLine1', 'suburb', 'city', 'postCode', 'email', 'phone'],
            properties: {
                basicInfo: {
                    $ref: '#/definitions/policyHolderBasicInfo',
                },
                addressLine1: {
                    type: 'string',
                },
                addressLine2: {
                    type: 'string',
                },
                suburb: {
                    type: 'string',
                },
                city: {
                    type: 'string',
                },
                postCode: {
                    type: 'integer',
                },
                storedAtSameLocation: {
                    type: 'object',
                    // required: ['storedAtSameLocationRequired'],
                    properties: {
                        storedAtSameLocationRequired: {
                            type: 'boolean',
                        },
                    },
                    dependencies: {
                        storedAtSameLocationRequired: {
                            oneOf: [
                                {
                                    required: [
                                        'storedAtSameLocation_addressLine1',
                                        'storedAtSameLocation_suburb',
                                        'storedAtSameLocation_city',
                                        'storedAtSameLocation_postCode',
                                    ],
                                    properties: {
                                        storedAtSameLocationRequired: {
                                            enum: [false],
                                        },
                                        storedAtSameLocation_addressLine1: {
                                            type: 'string',
                                        },
                                        storedAtSameLocation_addressLine2: {
                                            type: 'string',
                                        },
                                        storedAtSameLocation_suburb: {
                                            type: 'string',
                                        },
                                        storedAtSameLocation_city: {
                                            type: 'string',
                                        },
                                        storedAtSameLocation_postCode: {
                                            type: 'string',
                                        },
                                    },
                                },
                                {
                                    properties: {
                                        storedAtSameLocationRequired: {
                                            enum: [true],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                email: {
                    type: 'string',
                    format: 'email',
                },
                phone: {
                    type: 'string',
                },
            },
        },
        additionalHolder: {
            type: 'object',
            required: ['additionalHolderRequired'],
            properties: {
                additionalHolderRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                additionalHolderRequired: {
                    oneOf: [
                        {
                            properties: {
                                additionalHolderRequired: {
                                    enum: [true],
                                },
                                additionalPolicyHolders: {
                                    type: 'array',
                                    minItems: 1,
                                    maxItems: 2,
                                    items: {
                                        $ref: '#/definitions/policyHolderBasicInfo',
                                    },
                                },
                            },
                        },
                        {
                            properties: {
                                additionalHolderRequired: {
                                    enum: [false],
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
};
