export default {
    type: 'object',
    required: ['photo1', 'photo2', 'photo3'],
    properties: {
        heading: { type: 'null' },
        photo1: {
            type: 'string',
            format: 'data-url',
        },
        photo2: {
            type: 'string',
            format: 'data-url',
        },
        photo3: {
            type: 'string',
            format: 'data-url',
        },
        photo4: {
            type: 'string',
            format: 'data-url',
        },
        photo5: {
            type: 'string',
            format: 'data-url',
        },
        photo6: {
            type: 'string',
            format: 'data-url',
        },
    },
};
