/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { WidgetInput } from 'react-addressfinder';
import { Slider } from 'rsuite';

const Entry = ({
    index,
    updateEntry,
    deleteEntry,
    toggleShowSaveButton,
    defaultValues,
    numberOfEntries,
    AllEntries,
    enableButton,
    disableButton,
}) => {
    const [open, toggleOpen] = useState(true);
    const [values, setValues] = useState(defaultValues);
    const [sliderValue, setSliderValue] = React.useState(defaultValues.percentageUsed);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [yearsRange, setYearsRange] = useState([]);
    const [updateSlider, setUpdateSlider] = useState(false);
    const [licenseDateError, setLicenseDateError] = useState(false);

    const calculateMaxRangeValue = () => {
        let max = 100;

        AllEntries.forEach(ent => {
            if (ent.id !== defaultValues.id) {
                if (
                    ent.roleType === 'registered_and_main' ||
                    ent.roleType === 'additional_driver' ||
                    ent.roleType === 'main' ||
                    ent.roleType === 'registered_and_addition'
                ) {
                    max -= ent.percentageUsed;
                }
            }
        });

        return max;
    };

    const updateOtherDriversPercentageUsed = updateValue => {
        let driverToUpdate;

        if (AllEntries.length <= 2) {
            driverToUpdate = AllEntries.filter(
                e =>
                    e.id !== values.id &&
                    (e.roleType === 'registered_and_main' ||
                        e.roleType === 'additional_driver' ||
                        e.roleType === 'main' ||
                        e.roleType === 'registered_and_addition')
            );
        } else {
            driverToUpdate = AllEntries.filter(
                e =>
                    e.id !== values.id &&
                    (e.roleType === 'registered_and_main' ||
                        e.roleType === 'additional_driver' ||
                        e.roleType === 'main' ||
                        e.roleType === 'registered_and_addition')
            );
        }

        // max = max - updateValue;

        if (!driverToUpdate.length) return null;
        driverToUpdate = driverToUpdate.sort((a, b) =>
            a.roleType !== 'additional_driver' ? 1 : -1
        );

        let drivers = [];

        for (let i = 0; i < driverToUpdate.length; i++) {
            let max = 100;
            // max = max - updateValue;
            const driver = driverToUpdate[i];

            AllEntries.forEach(ent => {
                if (driver.id !== ent.id) {
                    if (
                        ent.roleType === 'registered_and_main' ||
                        ent.roleType === 'additional_driver' ||
                        ent.roleType === 'main' ||
                        ent.roleType === 'registered_and_addition'
                    ) {
                        max -= ent.percentageUsed;
                        console.log('*******', max);
                    }
                }
            });

            if (max <= 0) {
                drivers.push({
                    percentageUsed: 0,
                    id: driver.id,
                });

                continue;
            } else {
                drivers.push({
                    percentageUsed: max,
                    id: driver.id,
                });

                break;
            }
        }

        return drivers;
    };

    useEffect(() => {
        document.getElementById(`roleType_0`).focus();

        setSliderValue(calculateMaxRangeValue());

        if (yearsRange === [] || yearsRange.length < 1) {
            const dateNow = new Date();
            const endYear = dateNow.getFullYear();
            const startYear = endYear - 35;
            const options = [];
            // eslint-disable-next-line no-plusplus
            for (let i = startYear; i <= endYear; i++) {
                options.push(i);
            }

            setYearsRange(options);
        }
    }, []);

    useEffect(() => {
        const resetSlider = defaultValues.percentageUsed !== values.percentageUsed;
        setValues(defaultValues);

        if (resetSlider) {
            setUpdateSlider(true);
            setTimeout(() => setUpdateSlider(false), 1000);
        }
        // setSliderValue(calculateMaxRangeValue());
    }, [AllEntries]);

    useEffect(() => {
        const updatedDriver = updateOtherDriversPercentageUsed();

        if (updatedDriver) {
            updatedDriver.forEach(d => {
                if (d) {
                    updateEntry(
                        {
                            percentageUsed: d.percentageUsed,
                        },
                        d.id
                    );
                }
            });
        }
    }, [sliderValue]);

    useEffect(() => {
        console.log('Errors', errors);
    }, [errors]);

    // validation
    // const requiredFileValidation = (fieldName, fieldValue) => {
    //     if (!fieldValue) {
    //         return `${fieldName} is required`;
    //     }

    //     // if (fieldValue.type !== 'image/png' || fieldValue.type !== 'image/jpeg') {
    //     //     return `${fieldName} is must be png or jpg`;
    //     // }

    //     return null;
    // };

    const requiredValidation = (fieldName, fieldValue) => {
        if (!fieldValue) {
            if (!fieldName) {
                return 'Required';
            }

            return `${fieldName} is required`;
        }

        if (fieldValue.trim() === '') {
            if (!fieldName) {
                return 'Required';
            }

            return `${fieldName} is required`;
        }

        return null;
    };

    const emailValidation = email => {
        if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return null;
        }
        if (!email) {
            return `email is required`;
        }
        if (email.trim() === '') {
            return 'Email is required';
        }
        return 'Please enter a valid email';
    };

    const dobValidation = (fieldName, fieldValue) => {
        requiredValidation(fieldName, fieldValue);

        if (values.dob_day && values.dob_month && values.dob_year) {
            const dob = new Date(`${values.dob_day}/${values.dob_month}/${values.dob_year}`);
            // calculate month difference from current date in time
            const monthDiff = Date.now() - dob.getTime();

            // convert the calculated difference in date format
            const ageDt = new Date(monthDiff);

            // extract year from date
            const year = ageDt.getUTCFullYear();

            // now calculate the age of the user
            const age = Math.abs(year - 1970);

            if (age > 90 || age < 18) {
                return "Please call us, we don't quote this age group";
            }
        }
        return null;
    };

    const futureDateValidation = (fieldName, fieldValue) => {
        if (requiredValidation(fieldName, fieldValue)) {
            setLicenseDateError('You cannot select a date in the future');
            return '';
        }

        if (values.date_issued_day && values.date_issued_month && values.date_issued_year) {
            let now = new Date();
            now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000);

            let licenseDate = new Date(
                `${values.date_issued_month}/${values.date_issued_day}/${values.date_issued_year}`
            );
            licenseDate.setTime(
                licenseDate.getTime() + licenseDate.getTimezoneOffset() * 60 * 1000
            );

            if (licenseDate > now) {
                setLicenseDateError('You cannot select a date in the future');
                return '';
            } else {
                setLicenseDateError(false);
            }
        }

        return null;
    };

    const mobileValidation = (fieldName, fieldValue) => {
        const required = requiredValidation(fieldName, fieldValue);

        if (required) {
            return required;
        }

        const regex = /^(\+|\d)[0-9]{7,16}$/;
        if (!regex.test(fieldValue)) {
            return 'Invalid mobile number. It must start with either "+" or a digit, followed by 7 to 16 digits.';
        }

        return null;
    };

    const validate = driverType => {
        return {
            id: value => requiredValidation('Id', value),
            // grade_notes: value => requiredValidation('grade Notes', value),
            firstName: value => requiredValidation('First Name', value),
            lastName: value => requiredValidation('Last Name', value),
            // mobile:
            //     driverType === 'additional_driver'
            //         ? () => null
            //         : value => requiredValidation('Mobile', value),
            yearsLicenseHeld: () => null,
            // driverType === 'registered_and_main' ||
            //     driverType === 'main' ||
            //     driverType === 'registered_and_addition' ||
            //     driverType === 'additional_driver'
            //     ? value => requiredValidation('Licence held value', value)
            //     : () => null,
            dob_year: value => requiredValidation(undefined, value),
            dob_month: value => requiredValidation(undefined, value),
            dob_day: value => requiredValidation(undefined, value),
            // dob_year: value => dobValidation('dob_year', value),
            // dob_month: () => null,
            // dob_day: () => null,
            roleType: value => requiredValidation('Role type', value),
            addressSearch: () => null,
            fullAddress: () => null,
            streetAddress:
                driverType === 'registered_and_main' ||
                driverType === 'registered_and_addition' ||
                driverType === 'main' ||
                driverType === 'registered_and_addition'
                    ? value => requiredValidation('Street address', value)
                    : () => null,
            // suburb: value => requiredValidation('Suburb', value), //TODO This should not be required
            city:
                driverType === 'registered_and_main' ||
                driverType === 'registered_and_addition' ||
                driverType === 'main' ||
                driverType === 'registered_and_addition'
                    ? value => requiredValidation('City', value)
                    : () => null,
            postalCode:
                driverType === 'registered_and_main' ||
                driverType === 'registered_and_addition' ||
                driverType === 'main' ||
                driverType === 'registered_and_addition'
                    ? value => requiredValidation('Postal', value)
                    : () => null,
            licenseType:
                driverType === 'registered_and_main' || driverType === 'registered_and_addition'
                    ? value => requiredValidation('License type', value)
                    : () => null,
            // licenseId: value => requiredValidation('License id', value),
            licenseId: () => null,
            title: value => requiredValidation(undefined, value),
            // gender: value => requiredValidation('Gender', value),
            // fileUpload: value => requiredFileValidation('File', value),
            // percentageUsed: value => requiredValidation('Percentage', value),
            percentageUsed: () => null,
            // date_issued_year: value => futureDateValidation('date_issued_year', value),
            // date_issued_month: value => futureDateValidation('date_issued_month', value),
            // date_issued_day: value => futureDateValidation('date_issued_day', value),
            date_issued_year: () => null,
            date_issued_month: () => null,
            date_issued_day: () => null,
            email:
                driverType === 'registered_and_main' ||
                driverType === 'registered_and_addition' ||
                driverType === 'main' ||
                driverType === 'registered_and_addition'
                    ? emailValidation
                    : () => null,
            mobile:
                driverType === 'registered_and_main' ||
                driverType === 'registered_and_addition' ||
                driverType === 'main' ||
                driverType === 'registered_and_addition'
                    ? value => mobileValidation('Mobile', value)
                    : () => null,
        };
    };

    const handleChange = (newValues, stateValues = values) => {
        Object.keys(newValues).forEach(item => {
            setTouched({
                ...touched,
                [item]: true,
            });
        });

        // updateEntry(newValues, defaultValues.id);
        setValues({ ...stateValues, ...newValues });
    };

    const handleBlur = ({ name, value }) => {
        const { [name]: removedError, ...rest } = errors;

        setTouched({
            ...touched,
            [name]: true,
        });

        const error = validate(values.roleType)[name](value);

        setErrors({
            ...rest,
            ...(error && { [name]: true && error }),
        });
    };

    // const handleFileChange = input => {
    //     handleBlur({
    //         name: 'fileUpload',
    //         value: input,
    //     });

    //     // const reader = new FileReader();
    //     // const url = reader.readAsDataURL(input);
    //     setValues({ ...values, fileUpload: input });
    // };

    // eslint-disable-next-line consistent-return
    const loop = (range, callback) => {
        const options = [];
        // eslint-disable-next-line no-plusplus
        for (let i = range[0]; i <= range[1]; i++) {
            options.push(callback(range, i));
        }

        return options;
    };

    const validateRoleTypeSelect = () => {
        const validOptions = {
            main: false,
            registered_and_main: false,
            additional_driver: false,
            registered_and_addition: false,
        };
        const mainDriver = AllEntries.find(o => o.roleType === 'main');
        const registeredAndMainDriver = AllEntries.find(o => o.roleType === 'registered_and_main');
        const registeredOwner = AllEntries.find(o => o.roleType === 'registered_and_addition');
        const registeredAndAddition = AllEntries.find(
            o => o.roleType === 'registered_and_addition'
        );

        if (registeredAndMainDriver) {
            if (registeredAndMainDriver?.id === defaultValues.id) {
                validOptions.main = false;
                validOptions.registered_and_main = false;
                validOptions.registered_and_addition = false;
                validOptions.registered_and_addition = false;
            } else {
                validOptions.main = true;
                validOptions.registered_and_main = true;
                validOptions.registered_and_addition = true;
                validOptions.registered_and_addition = true;
            }
        }

        if (mainDriver) {
            if (registeredAndMainDriver) {
                validOptions.main = true;
                validOptions.registered_and_main = true;
                validOptions.registered_and_addition = true;
                validOptions.registered_and_addition = true;
            } else if (mainDriver?.id === defaultValues.id) {
                validOptions.main = false;
                validOptions.registered_and_main = false;
            } else {
                validOptions.main = true;
                validOptions.registered_and_main = true;
            }
        }

        if (registeredOwner) {
            if (registeredAndMainDriver?.id !== defaultValues.id) {
                validOptions.registered_and_main = true;
            }

            if (registeredOwner?.id === defaultValues.id) {
                validOptions.registered_and_addition = false;
                validOptions.registered_and_addition = false;
            } else {
                validOptions.registered_and_addition = true;
                validOptions.registered_and_addition = true;
            }
        }

        if (registeredAndAddition) {
            if (registeredAndMainDriver?.id !== defaultValues.id) {
                validOptions.registered_and_main = true;
            }

            if (registeredAndAddition?.id === defaultValues.id) {
                validOptions.registered_and_addition = false;
                validOptions.registered_and_addition = false;
            } else {
                validOptions.registered_and_addition = true;
                validOptions.registered_and_addition = true;
            }
        }

        return validOptions;
    };

    const truncateString = (str, num) => {
        if (!str) return '';

        if (str?.length > num) {
            return str.slice(0, num) + '...';
        } else {
            return str;
        }
    };

    const renderReadableType = type => {
        switch (type) {
            case 'registered_and_main':
                return 'Registered and main';
            case 'main':
                return 'Main';
            case 'additional_driver':
                return 'Additional';
            case 'registered_and_addition':
                return 'Registered';
            case 'registered_and_addition':
                return 'Registered and addition';
            default:
                return 'NA';
        }
    };

    return (
        <li className={`ownership-entry ${open ? 'opened' : undefined}`}>
            <div className="overview">
                <span className="index">0{index + 1}</span>

                <div className="overview-col">
                    <div className="overview-label">
                        <h5>Driver name</h5>
                    </div>
                    <div className="overview-value">
                        <p>{truncateString(values.firstName, 20) ?? 'NA'}</p>
                    </div>
                </div>
                <div className="overview-col">
                    <div className="overview-label">
                        <h5>Role</h5>
                    </div>
                    <div className="overview-value">
                        <p>{renderReadableType(values.roleType)}</p>
                    </div>
                </div>
                <div className="overview-col">
                    <div className="overview-label">
                        <h5>Usage</h5>
                    </div>
                    <div className="overview-value">
                        <p>
                            {values.roleType === 'registered_and_main' ||
                            values.roleType === 'additional_driver' ||
                            values.roleType === 'main' ||
                            values.roleType === 'registered_and_addition'
                                ? `${values.percentageUsed.toFixed(0)}%`
                                : '-'}
                        </p>
                    </div>
                </div>
                <div className="overview-col">
                    <div className="overview-label">
                        <h5>Action</h5>
                    </div>
                    <div className="overview-value overview-value__row">
                        <button
                            type="button"
                            onClick={() => {
                                toggleShowSaveButton(open);
                                toggleOpen(!open);
                                disableButton();
                            }}
                        >
                            {open ? 'Close' : 'Edit'}
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                let _values = values;
                                const updatedDriver = updateOtherDriversPercentageUsed(0);
                                _values.percentageUsed = 0;

                                updateEntry(_values, defaultValues.id);
                                if (updatedDriver) {
                                    updatedDriver.forEach(d => {
                                        if (d) {
                                            updateEntry(
                                                {
                                                    percentageUsed:
                                                        d.percentageUsed +
                                                        sliderValue / updatedDriver.length,
                                                },
                                                d.id
                                            );
                                        }
                                    });
                                }

                                handleChange({ percentageUsed: 0 });

                                setTimeout(() => {
                                    toggleShowSaveButton(true);
                                    toggleOpen(false);
                                    deleteEntry();
                                }, 500);
                            }}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
            <div className="entry-fields">
                <input type="hidden" name="id" value={defaultValues.id} />
                <input type="hidden" name="grade_notes" value={defaultValues.grade_notes} />
                <div className="entry-fields__row leading">
                    <div className="input-wrapper no-left-padding">
                        <div className="select-wrapper">
                            <select
                                name={`roleType_${index}`}
                                id={`roleType_${index}`}
                                onChange={({ target }) => {
                                    setErrors({});
                                    setTouched({});
                                    handleChange({ roleType: target.value });
                                }}
                                onBlur={({ target }) =>
                                    handleBlur({
                                        name: 'roleType',
                                        value: target.value,
                                    })
                                }
                            >
                                {/* <option value="" disabled>
                                        Is this person the…*
                                    </option> */}
                                <option
                                    value="registered_and_main"
                                    selected={values.roleType === 'registered_and_main'}
                                    disabled={validateRoleTypeSelect().registered_and_main}
                                >
                                    Registered Owner + Main Driver
                                </option>
                                <option
                                    value="main"
                                    selected={values.roleType === 'main'}
                                    disabled={validateRoleTypeSelect().main}
                                >
                                    Main Driver
                                </option>
                                <option
                                    value="registered_and_addition"
                                    selected={values.roleType === 'registered_and_addition'}
                                    disabled={validateRoleTypeSelect().registered_and_addition}
                                >
                                    Registered Owner
                                </option>
                                <option
                                    value="additional_driver"
                                    selected={values.roleType === 'additional_driver'}
                                    disabled={validateRoleTypeSelect().additional_driver}
                                >
                                    Additional Driver
                                </option>
                                <option
                                    value="registered_and_addition"
                                    selected={values.roleType === 'registered_and_addition'}
                                    disabled={validateRoleTypeSelect().registered_and_addition}
                                >
                                    Registered Owner + Additional Driver
                                </option>
                            </select>
                            {touched.roleType && errors.roleType && (
                                <small className="error-message">{errors.roleType}</small>
                            )}
                        </div>
                    </div>

                    {(values.roleType === 'registered_and_main' ||
                        values.roleType === 'additional_driver' ||
                        values.roleType === 'main' ||
                        values.roleType === 'registered_and_addition') && (
                        <div className="input-wrapper no-right-padding">
                            <p className="label sm-label">
                                <strong>Percentage of use:&nbsp;</strong>
                                How often will this person drive the vehicle?*
                                {calculateMaxRangeValue() < 0 ? '*' : undefined}
                            </p>
                            <div className="slider-wrapper">
                                {true && (
                                    <Slider
                                        progress
                                        min={0}
                                        max={100}
                                        value={values.percentageUsed}
                                        defaultValue={values.percentageUsed}
                                        step={10}
                                        tooltip={false}
                                        handleTitle={
                                            <span className="range-value">
                                                {values.percentageUsed}%
                                            </span>
                                        }
                                        onChange={value => {
                                            let _values = values;
                                            // const updatedDriver = updateOtherDriversPercentageUsed(
                                            //     value
                                            // );
                                            values.percentageUsed = value;

                                            setSliderValue(value);
                                            updateEntry(_values, defaultValues.id);
                                            handleChange({ percentageUsed: value });

                                            // if (updatedDriver) {
                                            //     updatedDriver.forEach(d => {
                                            //         if (d) {
                                            //             updateEntry(
                                            //                 {
                                            //                     percentageUsed: d.percentageUsed,
                                            //                 },
                                            //                 d.id
                                            //             );
                                            //         }
                                            //     });
                                            // }
                                        }}
                                    />
                                )}
                            </div>
                            {/* {touched.percentageUsed && (
                                <small className="error-message push-down">
                                    {errors.percentageUsed}
                                </small>
                            )} */}
                        </div>
                    )}
                </div>
                <div className="entry-fields__row middle">
                    <div className="input-wrapper">
                        <div className="entry-fields__row">
                            <div className="select-wrapper">
                                <select
                                    name={`title${index}`}
                                    id={`title${index}`}
                                    onChange={({ target }) => handleChange({ title: target.value })}
                                    onBlur={({ target }) =>
                                        handleBlur({ name: 'title', value: target.value })
                                    }
                                >
                                    <option value="" disabled selected={values.title === undefined}>
                                        Title*
                                    </option>
                                    <option value="Mr" selected={values.title === 'Mr'}>
                                        Mr
                                    </option>
                                    <option value="Mrs" selected={values.title === 'Mrs'}>
                                        Mrs
                                    </option>
                                    <option value="Ms" selected={values.title === 'Ms'}>
                                        Ms
                                    </option>
                                    <option value="Miss" selected={values.title === 'Miss'}>
                                        Miss
                                    </option>
                                </select>
                                {touched.title && errors.title && (
                                    <small className="error-message">{errors.title}</small>
                                )}
                            </div>
                            {touched.gender && errors.gender && (
                                <small className="error-message">{errors.gender}</small>
                            )}
                        </div>
                    </div>

                    <div className="input-wrapper">
                        <div className="form-group field field-string  field-date date-of-birth">
                            <ul className="list-inline">
                                <li>
                                    <select
                                        className="form-control"
                                        name={`dob_day_${index}`}
                                        id={`root_date_day_${index}`}
                                        onChange={({ target }) =>
                                            handleChange({ dob_day: target.value })
                                        }
                                        onBlur={({ target }) =>
                                            handleBlur({
                                                name: 'dob_day',
                                                value: target.value,
                                            })
                                        }
                                    >
                                        <option value="">DD*</option>
                                        <option value="1" selected={values.dob_day === '1'}>
                                            01
                                        </option>
                                        <option value="2" selected={values.dob_day === '2'}>
                                            02
                                        </option>
                                        <option value="3" selected={values.dob_day === '3'}>
                                            03
                                        </option>
                                        <option value="4" selected={values.dob_day === '4'}>
                                            04
                                        </option>
                                        <option value="5" selected={values.dob_day === '5'}>
                                            05
                                        </option>
                                        <option value="6" selected={values.dob_day === '6'}>
                                            06
                                        </option>
                                        <option value="7" selected={values.dob_day === '7'}>
                                            07
                                        </option>
                                        <option value="8" selected={values.dob_day === '8'}>
                                            08
                                        </option>
                                        <option value="9" selected={values.dob_day === '9'}>
                                            09
                                        </option>
                                        <option value="10" selected={values.dob_day === '10'}>
                                            10
                                        </option>
                                        <option value="11" selected={values.dob_day === '11'}>
                                            11
                                        </option>
                                        <option value="12" selected={values.dob_day === '12'}>
                                            12
                                        </option>
                                        <option value="13" selected={values.dob_day === '13'}>
                                            13
                                        </option>
                                        <option value="14" selected={values.dob_day === '14'}>
                                            14
                                        </option>
                                        <option value="15" selected={values.dob_day === '15'}>
                                            15
                                        </option>
                                        <option value="16" selected={values.dob_day === '16'}>
                                            16
                                        </option>
                                        <option value="17" selected={values.dob_day === '17'}>
                                            17
                                        </option>
                                        <option value="18" selected={values.dob_day === '18'}>
                                            18
                                        </option>
                                        <option value="19" selected={values.dob_day === '19'}>
                                            19
                                        </option>
                                        <option value="20" selected={values.dob_day === '20'}>
                                            20
                                        </option>
                                        <option value="21" selected={values.dob_day === '21'}>
                                            21
                                        </option>
                                        <option value="22" selected={values.dob_day === '22'}>
                                            22
                                        </option>
                                        <option value="23" selected={values.dob_day === '23'}>
                                            23
                                        </option>
                                        <option value="24" selected={values.dob_day === '24'}>
                                            24
                                        </option>
                                        <option value="25" selected={values.dob_day === '25'}>
                                            25
                                        </option>
                                        <option value="26" selected={values.dob_day === '26'}>
                                            26
                                        </option>
                                        <option value="27" selected={values.dob_day === '27'}>
                                            27
                                        </option>
                                        <option value="28" selected={values.dob_day === '28'}>
                                            28
                                        </option>
                                        <option value="29" selected={values.dob_day === '29'}>
                                            29
                                        </option>
                                        <option value="30" selected={values.dob_day === '30'}>
                                            30
                                        </option>
                                        <option value="31" selected={values.dob_day === '31'}>
                                            31
                                        </option>
                                    </select>
                                </li>
                                <li>
                                    <select
                                        className="form-control"
                                        name={`dob_month_${index}`}
                                        id={`root_date_month_${index}`}
                                        onChange={({ target }) =>
                                            handleChange({ dob_month: target.value })
                                        }
                                        onBlur={({ target }) =>
                                            handleBlur({
                                                name: 'dob_month',
                                                value: target.value,
                                            })
                                        }
                                    >
                                        <option value="">MM*</option>
                                        <option value="1" selected={values.dob_month === 1}>
                                            01
                                        </option>
                                        <option value="2" selected={values.dob_month === '2'}>
                                            02
                                        </option>
                                        <option value="3" selected={values.dob_month === '3'}>
                                            03
                                        </option>
                                        <option value="4" selected={values.dob_month === '4'}>
                                            04
                                        </option>
                                        <option value="5" selected={values.dob_month === '5'}>
                                            05
                                        </option>
                                        <option value="6" selected={values.dob_month === '6'}>
                                            06
                                        </option>
                                        <option value="7" selected={values.dob_month === '7'}>
                                            07
                                        </option>
                                        <option value="8" selected={values.dob_month === '8'}>
                                            08
                                        </option>
                                        <option value="9" selected={values.dob_month === '9'}>
                                            09
                                        </option>
                                        <option value="10" selected={values.dob_month === '10'}>
                                            10
                                        </option>
                                        <option value="11" selected={values.dob_month === '11'}>
                                            11
                                        </option>
                                        <option value="12" selected={values.dob_month === '12'}>
                                            12
                                        </option>
                                    </select>
                                </li>
                                <li>
                                    <select
                                        name={`dob_year_${index}`}
                                        id={`root_date_year_${index}`}
                                        className="form-control"
                                        onChange={({ target }) =>
                                            handleChange({ dob_year: target.value })
                                        }
                                        onBlur={({ target }) =>
                                            handleBlur({
                                                name: 'dob_year',
                                                value: target.value,
                                            })
                                        }
                                    >
                                        <option value="">YYYY*</option>
                                        {loop(
                                            [
                                                new Date().getFullYear() - 91,
                                                new Date().getFullYear() - 16,
                                            ],
                                            (_, year) => (
                                                <option
                                                    value={year}
                                                    selected={values.dob_year === year.toString()}
                                                >
                                                    {year}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </li>
                            </ul>
                            {touched.dob_year && errors.dob_year && (
                                <small className="error-message">{errors.dob_year}</small>
                            )}
                        </div>
                    </div>

                    <div className="input-wrapper">
                        <input
                            type="text"
                            name={`firstName_${index}`}
                            id={`firstName_${index}`}
                            placeholder="First Name*"
                            value={values.firstName}
                            onChange={({ target }) => {
                                handleChange({ firstName: target.value });
                            }}
                            onBlur={({ target }) =>
                                handleBlur({ name: 'firstName', value: target.value })
                            }
                        />
                        {touched.firstName && errors.firstName && (
                            <small className="error-message">{errors.firstName}</small>
                        )}
                    </div>

                    <div className="input-wrapper">
                        <input
                            type="text"
                            placeholder="Last Name*"
                            name={`lastName_${index}`}
                            id={`lastName_${index}`}
                            value={values.lastName}
                            onChange={({ target }) => handleChange({ lastName: target.value })}
                            onBlur={({ target }) =>
                                handleBlur({
                                    name: 'lastName',
                                    value: target.value,
                                })
                            }
                        />
                        {touched.lastName && errors.lastName && (
                            <small className="error-message">{errors.lastName}</small>
                        )}
                    </div>

                    {(values.roleType === 'registered_and_main' ||
                        values.roleType === 'registered_and_addition' ||
                        values.roleType === 'main' ||
                        values.roleType === 'registered_and_addition') && (
                        <div className="input-wrapper search-wrapper">
                            <WidgetInput
                                addressFinderKey="6XJY4EAQLUFH8P93DRK7"
                                name={`addressSearch_${values.id}`}
                                id={`addressSearch_${values.id}`}
                                country="NZ"
                                autoComplete="off"
                                placeholder="Enter full residential address"
                                onSelected={(fullAddress, address) => {
                                    setValues(stateValues => ({
                                        ...stateValues,
                                        fullAddress: fullAddress,
                                        addressSearch: fullAddress,
                                        suburb: address.suburb || '',
                                        city: address?.city || address.state,
                                        streetAddress: address.line1,
                                        postalCode: address.postcode,
                                    }));
                                }}
                                onBlur={({ target }) =>
                                    handleBlur({ name: 'addressSearch', value: target.value })
                                }
                            />
                            {touched.addressSearch && errors.addressSearch && (
                                <small className="error-message">{errors.addressSearch}</small>
                            )}
                        </div>
                    )}

                    {(values.roleType === 'registered_and_main' ||
                        values.roleType === 'registered_and_addition' ||
                        values.roleType === 'main' ||
                        values.roleType === 'registered_and_addition') && (
                        <div className="input-wrapper">
                            <input
                                type="text"
                                name={`streetAddress_${index}`}
                                id={`streetAddress_${index}`}
                                placeholder="Street address*"
                                value={values.streetAddress}
                                onChange={({ target }) =>
                                    handleChange({ streetAddress: target.value })
                                }
                                onBlur={({ target }) =>
                                    handleBlur({
                                        name: 'streetAddress',
                                        value: target.value,
                                    })
                                }
                            />
                            {touched.streetAddress && errors.streetAddress && (
                                <small className="error-message">{errors.streetAddress}</small>
                            )}
                        </div>
                    )}

                    {(values.roleType === 'registered_and_main' ||
                        values.roleType === 'registered_and_addition' ||
                        values.roleType === 'main' ||
                        values.roleType === 'registered_and_addition') && (
                        <div className="input-wrapper">
                            <input
                                type="text"
                                name={`suburb_${index}`}
                                id={`suburb_${index}`}
                                placeholder="Suburb"
                                value={values.suburb}
                                onChange={({ target }) => handleChange({ suburb: target.value })}
                                // onBlur={({ target }) =>
                                //     handleBlur({ name: 'suburb', value: target.value })
                                // }
                            />
                            {touched.suburb && errors.suburb && (
                                <small className="error-message">{errors.suburb}</small>
                            )}
                        </div>
                    )}

                    {(values.roleType === 'registered_and_main' ||
                        values.roleType === 'registered_and_addition' ||
                        values.roleType === 'main' ||
                        values.roleType === 'registered_and_addition') && (
                        <div className="input-wrapper">
                            <div className="entry-fields__row no-wrap">
                                <div className="inline-input-wrapper">
                                    <input
                                        type="text"
                                        name={`city_${index}`}
                                        id={`city_${index}`}
                                        placeholder="City/region*"
                                        className="no-right-border"
                                        value={values.city}
                                        onChange={({ target }) =>
                                            handleChange({ city: target.value })
                                        }
                                        onBlur={({ target }) =>
                                            handleBlur({ name: 'city', value: target.value })
                                        }
                                    />
                                    {touched.city && errors.city && (
                                        <small className="error-message">{errors.city}</small>
                                    )}
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        name={`postalCode_${index}`}
                                        id={`postalCode_${index}`}
                                        placeholder="Postal code*"
                                        value={values.postalCode}
                                        onChange={({ target }) =>
                                            handleChange({ postalCode: target.value })
                                        }
                                        onBlur={({ target }) =>
                                            handleBlur({
                                                name: 'postalCode',
                                                value: target.value,
                                            })
                                        }
                                    />
                                    {touched.postalCode && errors.postalCode && (
                                        <small className="error-message">{errors.postalCode}</small>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {(values.roleType === 'registered_and_main' ||
                        values.roleType === 'registered_and_addition' ||
                        values.roleType === 'main' ||
                        values.roleType === 'registered_and_addition') && (
                        <div className="input-wrapper phone-section">
                            <input
                                type="number"
                                className="mobile-number-input"
                                name={`mobileCountryCode_${index}`}
                                id={`mobileCountryCode_${index}`}
                                placeholder="eg. 64*"
                                min="1"
                                max="999"
                                value={values.mobileCountryCode}
                                onChange={({ target }) =>
                                    handleChange({ mobileCountryCode: target.value })
                                }
                                onBlur={({ target }) =>
                                    handleBlur({
                                        name: 'mobileCountryCode',
                                        value: target.value,
                                    })
                                }
                            />
                            <input
                                type="tel"
                                name={`mobile_${index}`}
                                id={`mobile_${index}`}
                                placeholder="Mobile phone*"
                                value={values.mobile}
                                onChange={({ target }) => handleChange({ mobile: target.value })}
                                onBlur={({ target }) =>
                                    handleBlur({ name: 'mobile', value: target.value })
                                }
                            />
                            {errors.mobile && (
                                <small className="error-message">{errors.mobile}</small>
                            )}
                        </div>
                    )}

                    {(values.roleType === 'registered_and_main' ||
                        values.roleType === 'registered_and_addition' ||
                        values.roleType === 'main' ||
                        values.roleType === 'registered_and_addition') && (
                        <div className="input-wrapper">
                            <input
                                type="email"
                                name={`email_${index}`}
                                id={`email_${index}`}
                                placeholder="Email*"
                                value={values.email}
                                onChange={({ target }) => handleChange({ email: target.value })}
                                onBlur={({ target }) =>
                                    handleBlur({ name: 'email', value: target.value })
                                }
                            />
                            {touched.email && errors.email && (
                                <small className="error-message">{errors.email}</small>
                            )}
                        </div>
                    )}

                    {(values.roleType === 'registered_and_main' ||
                        values.roleType === 'main' ||
                        values.roleType === 'registered_and_addition' ||
                        values.roleType === 'additional_driver') && (
                        <template>
                            <div className="input-wrapper">
                                <input
                                    type="text"
                                    name={`yearsLicenseHeld_${index}`}
                                    id={`yearsLicenseHeld_${index}`}
                                    placeholder="Number of years licence held"
                                    value={values.yearsLicenseHeld}
                                    onChange={({ target }) =>
                                        handleChange({ yearsLicenseHeld: target.value })
                                    }
                                    onBlur={({ target }) =>
                                        handleBlur({
                                            name: 'yearsLicenseHeld',
                                            value: target.value,
                                        })
                                    }
                                />
                                {touched.yearsLicenseHeld && errors.yearsLicenseHeld && (
                                    <small className="error-message">
                                        {errors.yearsLicenseHeld}
                                    </small>
                                )}
                            </div>
                        </template>
                    )}

                    {(values.roleType === 'additional_driver' ||
                        values.roleType === 'registered_and_main' ||
                        values.roleType === 'main' ||
                        values.roleType === 'registered_and_addition') && (
                        <div className="input-wrapper">
                            <div className="form-group field field-boolean  field-parent">
                                <div className="field-radio-group" id="root_owner_soleOwner">
                                    <div className="radio ">
                                        <label htmlFor={`Full_licenseType_${index}`}>
                                            <span>
                                                <input
                                                    type="radio"
                                                    name={`licenseType_${index}`}
                                                    id={`Full_licenseType_${index}`}
                                                    value="full"
                                                    checked={values.licenseType === 'full'}
                                                    onChange={() =>
                                                        handleChange({ licenseType: 'full' })
                                                    }
                                                    onBlur={() =>
                                                        handleBlur({
                                                            name: 'licenseType',
                                                            value: 'full',
                                                        })
                                                    }
                                                />
                                                <span>Full</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="radio ">
                                        <label htmlFor={`Restricted_licenseType_${index}`}>
                                            <span>
                                                <input
                                                    id={`Restricted_licenseType_${index}`}
                                                    type="radio"
                                                    name={`licenseType_${index}`}
                                                    value="restricted"
                                                    checked={values.licenseType === 'restricted'}
                                                    onChange={() =>
                                                        handleChange({
                                                            licenseType: 'restricted',
                                                        })
                                                    }
                                                    onBlur={() =>
                                                        handleBlur({
                                                            name: 'licenseType',
                                                            value: 'restricted',
                                                        })
                                                    }
                                                />
                                                <span>Restricted</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="radio ">
                                        <label htmlFor={`Leaners_licenseType_${index}`}>
                                            <span>
                                                <input
                                                    id={`Leaners_licenseType_${index}`}
                                                    type="radio"
                                                    name={`licenseType_${index}`}
                                                    value="learner"
                                                    checked={values.licenseType === 'learner'}
                                                    onChange={() =>
                                                        handleChange({ licenseType: 'learner' })
                                                    }
                                                    onBlur={() =>
                                                        handleBlur({
                                                            name: 'licenseType',
                                                            value: 'learner',
                                                        })
                                                    }
                                                />
                                                <span>Learner</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="radio ">
                                        <label htmlFor={`International_licenseType_${index}`}>
                                            <span>
                                                <input
                                                    id={`International_licenseType_${index}`}
                                                    type="radio"
                                                    name={`licenseType_${index}`}
                                                    value="international"
                                                    checked={values.licenseType === 'international'}
                                                    onChange={() =>
                                                        handleChange({
                                                            licenseType: 'international',
                                                        })
                                                    }
                                                    onBlur={() =>
                                                        handleBlur({
                                                            name: 'licenseType',
                                                            value: 'international',
                                                        })
                                                    }
                                                />
                                                <span>International</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                {touched.licenseType && errors.licenseType && (
                                    <small className="error-message">{errors.licenseType}</small>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="entry-fields__row">
                    {(values.roleType === 'additional_driver' ||
                        values.roleType === 'registered_and_main' ||
                        values.roleType === 'main' ||
                        values.roleType === 'registered_and_addition') && (
                        <template>
                            <div className="input-wrapper no-padding inline-input-wrapper">
                                <input
                                    type="text"
                                    name={`licenseId_${index}`}
                                    id={`licenseId_${index}`}
                                    className="no-right-border"
                                    value={values.licenseId}
                                    placeholder={`Licence Id`}
                                    onChange={({ target }) =>
                                        handleChange({ licenseId: target.value })
                                    }
                                    onBlur={({ target }) =>
                                        handleBlur({
                                            name: 'licenseId',
                                            value: target.value,
                                        })
                                    }
                                />
                                {touched.licenseId && errors.licenseId && (
                                    <small className="error-message">{errors.licenseId}</small>
                                )}
                            </div>
                        </template>
                    )}

                    {(values.roleType === 'additional_driver' ||
                        values.roleType === 'registered_and_main' ||
                        values.roleType === 'main' ||
                        values.roleType === 'registered_and_addition') && (
                        <template>
                            <div className="form-group no-padding field field-string input-wrapper field-date date-issued">
                                <ul className="list-inline">
                                    <li>
                                        <select
                                            className="form-control"
                                            name={`date_issued_year_${index}`}
                                            id={`date_issued_year_${index}`}
                                            onChange={({ target }) =>
                                                handleChange({ date_issued_year: target.value })
                                            }
                                            onBlur={({ target }) =>
                                                handleBlur({
                                                    name: 'date_issued_year',
                                                    value: target.value,
                                                })
                                            }
                                        >
                                            <option value="">YYYY</option>
                                            {yearsRange &&
                                                yearsRange.map(year => {
                                                    return (
                                                        <option
                                                            key={`year-${year}`}
                                                            value={year}
                                                            selected={
                                                                values.date_issued_year ===
                                                                year.toString()
                                                            }
                                                        >
                                                            {year}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                        {/* {touched.date_issued_year && errors.date_issued_year && (
                                        <small className="error-message">
                                            {errors.date_issued_year}
                                        </small>
                                    )} */}
                                    </li>
                                    <li>
                                        <select
                                            name={`date_issued_month_${index}`}
                                            id={`date_issued_month_${index}`}
                                            className="form-control"
                                            onChange={({ target }) =>
                                                handleChange({ date_issued_month: target.value })
                                            }
                                            onBlur={({ target }) =>
                                                handleBlur({
                                                    name: 'date_issued_month',
                                                    value: target.value,
                                                })
                                            }
                                        >
                                            <option value="">MM</option>
                                            <option
                                                value="1"
                                                selected={values.date_issued_month === '1'}
                                            >
                                                01
                                            </option>
                                            <option
                                                value="2"
                                                selected={values.date_issued_month === '2'}
                                            >
                                                02
                                            </option>
                                            <option
                                                value="3"
                                                selected={values.date_issued_month === '3'}
                                            >
                                                03
                                            </option>
                                            <option
                                                value="4"
                                                selected={values.date_issued_month === '4'}
                                            >
                                                04
                                            </option>
                                            <option
                                                value="5"
                                                selected={values.date_issued_month === '5'}
                                            >
                                                05
                                            </option>
                                            <option
                                                value="6"
                                                selected={values.date_issued_month === '6'}
                                            >
                                                06
                                            </option>
                                            <option
                                                value="7"
                                                selected={values.date_issued_month === '7'}
                                            >
                                                07
                                            </option>
                                            <option
                                                value="8"
                                                selected={values.date_issued_month === '8'}
                                            >
                                                08
                                            </option>
                                            <option
                                                value="9"
                                                selected={values.date_issued_month === '9'}
                                            >
                                                09
                                            </option>
                                            <option
                                                value="10"
                                                selected={values.date_issued_month === '10'}
                                            >
                                                10
                                            </option>
                                            <option
                                                value="11"
                                                selected={values.date_issued_month === '11'}
                                            >
                                                11
                                            </option>
                                            <option
                                                value="12"
                                                selected={values.date_issued_month === '12'}
                                            >
                                                12
                                            </option>
                                        </select>
                                        {/* {touched.date_issued_month && errors.date_issued_month && (
                                        <small className="error-message">
                                            {errors.date_issued_month}
                                        </small>
                                    )} */}
                                    </li>
                                    <li>
                                        <select
                                            name={`date_issued_day_${index}`}
                                            id={`date_issued_day_${index}`}
                                            className="form-control"
                                            onChange={({ target }) =>
                                                handleChange({ date_issued_day: target.value })
                                            }
                                            onBlur={({ target }) =>
                                                handleBlur({
                                                    name: 'date_issued_day',
                                                    value: target.value,
                                                })
                                            }
                                        >
                                            <option value="">DD</option>
                                            <option
                                                value="1"
                                                selected={values.date_issued_day === '1'}
                                            >
                                                01
                                            </option>
                                            <option
                                                value="2"
                                                selected={values.date_issued_day === '2'}
                                            >
                                                02
                                            </option>
                                            <option
                                                value="3"
                                                selected={values.date_issued_day === '3'}
                                            >
                                                03
                                            </option>
                                            <option
                                                value="4"
                                                selected={values.date_issued_day === '4'}
                                            >
                                                04
                                            </option>
                                            <option
                                                value="5"
                                                selected={values.date_issued_day === '5'}
                                            >
                                                05
                                            </option>
                                            <option
                                                value="6"
                                                selected={values.date_issued_day === '6'}
                                            >
                                                06
                                            </option>
                                            <option
                                                value="7"
                                                selected={values.date_issued_day === '7'}
                                            >
                                                07
                                            </option>
                                            <option
                                                value="8"
                                                selected={values.date_issued_day === '8'}
                                            >
                                                08
                                            </option>
                                            <option
                                                value="9"
                                                selected={values.date_issued_day === '9'}
                                            >
                                                09
                                            </option>
                                            <option
                                                value="10"
                                                selected={values.date_issued_day === '10'}
                                            >
                                                10
                                            </option>
                                            <option
                                                value="11"
                                                selected={values.date_issued_day === '11'}
                                            >
                                                11
                                            </option>
                                            <option
                                                value="12"
                                                selected={values.date_issued_day === '12'}
                                            >
                                                12
                                            </option>
                                            <option
                                                value="13"
                                                selected={values.date_issued_day === '13'}
                                            >
                                                13
                                            </option>
                                            <option
                                                value="14"
                                                selected={values.date_issued_day === '14'}
                                            >
                                                14
                                            </option>
                                            <option
                                                value="15"
                                                selected={values.date_issued_day === '15'}
                                            >
                                                15
                                            </option>
                                            <option
                                                value="16"
                                                selected={values.date_issued_day === '16'}
                                            >
                                                16
                                            </option>
                                            <option
                                                value="17"
                                                selected={values.date_issued_day === '17'}
                                            >
                                                17
                                            </option>
                                            <option
                                                value="18"
                                                selected={values.date_issued_day === '18'}
                                            >
                                                18
                                            </option>
                                            <option
                                                value="19"
                                                selected={values.date_issued_day === '19'}
                                            >
                                                19
                                            </option>
                                            <option
                                                value="20"
                                                selected={values.date_issued_day === '20'}
                                            >
                                                20
                                            </option>
                                            <option
                                                value="21"
                                                selected={values.date_issued_day === '21'}
                                            >
                                                21
                                            </option>
                                            <option
                                                value="22"
                                                selected={values.date_issued_day === '22'}
                                            >
                                                22
                                            </option>
                                            <option
                                                value="23"
                                                selected={values.date_issued_day === '23'}
                                            >
                                                23
                                            </option>
                                            <option
                                                value="24"
                                                selected={values.date_issued_day === '24'}
                                            >
                                                24
                                            </option>
                                            <option
                                                value="25"
                                                selected={values.date_issued_day === '25'}
                                            >
                                                25
                                            </option>
                                            <option
                                                value="26"
                                                selected={values.date_issued_day === '26'}
                                            >
                                                26
                                            </option>
                                            <option
                                                value="27"
                                                selected={values.date_issued_day === '27'}
                                            >
                                                27
                                            </option>
                                            <option
                                                value="28"
                                                selected={values.date_issued_day === '28'}
                                            >
                                                28
                                            </option>
                                            <option
                                                value="29"
                                                selected={values.date_issued_day === '29'}
                                            >
                                                29
                                            </option>
                                            <option
                                                value="30"
                                                selected={values.date_issued_day === '30'}
                                            >
                                                30
                                            </option>
                                            <option
                                                value="31"
                                                selected={values.date_issued_day === '31'}
                                            >
                                                31
                                            </option>
                                        </select>
                                        {/* {touched.date_issued_day && errors.date_issued_day && (
                                        <small className="error-message">
                                            {errors.date_issued_day}
                                        </small>
                                    )} */}
                                    </li>
                                </ul>
                                {!!licenseDateError && (
                                    <small className="error-message">{licenseDateError}</small>
                                )}
                            </div>
                        </template>
                    )}

                    {/* <div className="dropZoneContainer">
                        <input
                            type="file"
                            name={`fileUpload_${index}`}
                            id={`drop_zone_${index}`}
                            className="FileUpload"
                            accept=".jpg,.png,.gif"
                            onChange={e => handleFileChange(e.target.files[0])}
                        />
                        <div className="dropZoneOverlay">Upload</div>
                    </div>
                    {touched.FileUpload && errors.FileUpload && (
                        <small className="error-message">{errors.FileUpload}</small>
                    )} */}
                </div>
                {(values.fileUpload || values.fileUpload) && (
                    <div className="entry-fields__row file-note">
                        <p>{values.fileUpload ? values.fileUpload.name : values.fileUpload.name}</p>
                    </div>
                )}
                <div className="entry-fields__row actions no-wrap">
                    <div className="action-wrapper">
                        <button
                            className="cancel"
                            type="button"
                            onClick={() => {
                                setValues(values);
                                toggleShowSaveButton(true);
                                toggleOpen(false);
                                enableButton();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="action-wrapper">
                        <button
                            className="button save"
                            type="button"
                            onClick={() => {
                                const formValidation = Object.keys(values).reduce(
                                    (acc, key) => {
                                        if (!validate(values.roleType)[key]) {
                                            return acc;
                                        }

                                        const newError = validate(values.roleType)[key](
                                            values[key]
                                        );
                                        const newTouched = { [key]: true };

                                        return {
                                            errors: {
                                                ...acc.errors,
                                                ...(newError && { [key]: newError }),
                                            },
                                            touched: {
                                                ...acc.touched,
                                                ...newTouched,
                                            },
                                        };
                                    },
                                    {
                                        errors: { ...errors },
                                        touched: { ...touched },
                                    }
                                );

                                setErrors(formValidation.errors);
                                setTouched(formValidation.touched);

                                if (
                                    !Object.keys(formValidation.errors).map(
                                        itm => formValidation.errors[itm]
                                    ).length &&
                                    Object.keys(formValidation.errors)
                                        .map(itm => formValidation.errors[itm])
                                        .every(t => t === true) &&
                                    !licenseDateError
                                ) {
                                    toggleOpen(false);
                                    enableButton();

                                    updateEntry({ ...values, isValid: true }, defaultValues.id);
                                } else {
                                    updateEntry({ ...values, isValid: false }, defaultValues.id);
                                }
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default Entry;
