import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';
import { PHONE_NUMBERS_VALIDATION_MESSAGE } from '../../../../../config';

const Damage = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('accident'));
    const formData = localData && localData.damage ? localData.damage : {};

    const onSubmit = data => {
        const damage = data.formData;

        window.dataLayer.push({
            event: 'accidentClaimSubmission',
            step: 5,
            damage_best_contact_policy_holder: damage.bestContact.isPolicyHolder,
            damage_preferred_repairer: damage.preferredRepairer.hasPreferredRepairer,
            damage_obtained_quote:
                damage.preferredRepairer.quote && damage.preferredRepairer.quote.obtainedQuote,
            damage_obtained_quote_estimate:
                damage.preferredRepairer.quote && damage.preferredRepairer.quote.quoteEstimate,
            damage_vehicle_location: damage.vehicleLocation,
            damage_with_vehicle: damage.nonVehicle.nonVehiclePropertyDamaged,
            damage_property_damage: damage.nonVehicle.nonVehiclePropertyDamageDetails,
            damage_injured: damage.injuries.anyInjuries,
            damage_emergency_services:
                damage.injuries.emergencyServices &&
                damage.injuries.emergencyServices.emergencyServicesAttended,
            damage_hospitalisation: damage.injuries.hospital,
        });

        saveLocalData({ damage });
        saveContinue();
    };

    const validate = (data, errors) => {
        const { bestContact, preferredRepairer, anotherVehicle, injuries } = data;

        if (
            !bestContact.isPolicyHolder &&
            (bestContact.phone && (bestContact.phone.length < 8 || bestContact.phone.length > 15))
        ) {
            errors.bestContact.phone.addError(PHONE_NUMBERS_VALIDATION_MESSAGE);
        }

        if (
            preferredRepairer.hasPreferredRepairer &&
            (preferredRepairer.phone &&
                (preferredRepairer.phone.length < 8 || preferredRepairer.phone.length > 15))
        ) {
            errors.preferredRepairer.phone.addError(PHONE_NUMBERS_VALIDATION_MESSAGE);
        }

        if (
            anotherVehicle.anotherVehicleDamaged &&
            (anotherVehicle.phone &&
                (anotherVehicle.phone.length < 8 || anotherVehicle.phone.length > 15))
        ) {
            errors.anotherVehicle.phone.addError(PHONE_NUMBERS_VALIDATION_MESSAGE);
        }

        if (
            injuries.anyInjuries &&
            (injuries.phones && (injuries.phones.length < 8 || injuries.phones.length > 15))
        ) {
            errors.injuries.phones.addError(PHONE_NUMBERS_VALIDATION_MESSAGE);
        }

        return errors;
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    5. Recovery and damage
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
                validate={validate}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Damage.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Damage.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Damage;
