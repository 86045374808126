export default {
    startDate: {
        date: {
            'ui:title': 'Policy Start Date*',
            'ui:description':
                "Can't be a date in the past, can't be a date more then 30 days past current date",
            classNames: 'field-date',
            'ui:widget': 'alt-date',
            'ui:options': {
                yearsRange: [new Date().getFullYear(), new Date().getFullYear()],
                hideNowButton: true,
                hideClearButton: true,
            },
        },
        stepSubheading: {
            classNames: 'field-step-note-content field-step-note-content--warning',
            'ui:title':
                'Please note that your policy will not be immediately active. We will be in touch to confirm your policy.',
        },
    },
    includeRoadsideAssist: {
        'ui:title': 'Do you want to add Roadside Rescue?*',
        'ui:description': 'Additional $33.00 per annum',
        'ui:widget': 'radio',
    },
    paymentFrequency: {
        term: {
            classNames: 'field-select field-parent',
            'ui:placeholder': 'Pick your payment frequency',
            'ui:widget': 'select',
            'ui:title': 'Payment frequency*',
        },
        stepSubheading: {
            classNames: 'field-step-note-content',
            'ui:title':
                'Installments are arranged through our instalment payments partner. Terms, conditions and fees apply.',
        },
    },
};
