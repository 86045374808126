export default {
    stepNoteHeading: { classNames: 'field-step-note-heading' },
    stepNoteContent: { classNames: 'field-step-note-content' },

    additionalInformation: {
        hasAdditionalInformation: {
            'ui:title': 'Is there any further information likely to affect this claim?',
            'ui:widget': 'radio',
            classNames: 'field-parent',
        },
        details: {
            'ui:title': 'Please provide any other relevant information to this claim',
        },
    },
};
