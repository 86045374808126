import React from 'react';

export default {
    recovery: {
        recovered: {
            classNames: 'field-parent',
            'ui:title': 'Has the vehicle been recovered?',
            'ui:widget': 'radio',
        },
        date: {
            'ui:title': 'What date was it recovered?*',
            classNames: 'field-date',
            'ui:widget': 'alt-date',
            'ui:options': {
                hideNowButton: true,
                hideClearButton: true,
                yearsRange: [new Date().getFullYear() - 2, new Date().getFullYear()],
            },
        },
        time: {
            'ui:title': 'What time was it recovered?*',
        },
        locationFound: {
            'ui:title': 'Where was it found?*',
        },
        finder: {
            'ui:title': 'Who found it?',
        },
        towed: {
            wasTowed: {
                classNames: 'field-parent',
                'ui:title': 'Was a towing company used?',
                'ui:widget': 'radio',
            },
            details: {
                'ui:title': "Please provide the towing company's details",
            },
        },
        stolenDamagedParts: {
            'ui:title': 'Please describe any stolen or damaged parts and accessories',
        },
        preferredRepairer: {
            hasPreferredRepairer: {
                classNames: 'field-parent field-radio-hidden',
                'ui:title': 'Preferred repairer',
                'ui:widget': 'radio',
                'ui:description': (
                    <div className="fineprint mt-0">
                        If you haven’t chosen your repairer yet, you can use this handy tool to find
                        an IAG approved repairer in your area.
                        <br />
                        <br />
                        <a
                            rel="noopener noreferrer"
                            href="https://suppliersearch.iag.co.nz/"
                            target="_blank"
                        >
                            https://suppliersearch.iag.co.nz/
                        </a>
                        <br />
                        (Works best in Google Chrome)
                    </div>
                ),
            },
            name: {
                'ui:title': 'Preferred repairer name',
            },
            address: {
                'ui:title': 'Preferred repairer address',
            },
            phone: {
                'ui:title': 'Preferred repairer phone',
            },
        },
        repairEstimate: {
            obtainedEstimate: {
                classNames: 'field-parent',
                'ui:title': 'Have you obtained an estimate for the repair?',
                'ui:widget': 'radio',
            },
            estimateTotal: {
                'ui:title': 'What was the estimate?',
            },
        },
        bestContact: {
            isPolicyHolder: {
                classNames: 'field-parent',
                'ui:title':
                    'Is the policy holder named at the start of the claim form the best contact if we need to arrange an inspection of the vehicle?',
                'ui:widget': 'radio',
            },
            name: {
                'ui:title': 'What is the name of the best person to contact?',
            },
            phone: {
                'ui:title': 'What is the best contact phone number?',
            },
            email: {
                'ui:title': 'What is the best contact email address?',
            },
        },
    },
    relevantInfo: {
        'ui:title': 'Please provide any other relevant information to this claim',
    },
    location: {
        'ui:title': 'Where is the vehicle now?*',
    },
};
