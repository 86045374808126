import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema.js'; // eslint-disable-line
import uiSchema from './uiSchema.js'; // eslint-disable-line

const Policy = ({ saveContinue, saveLocalData, transformErrors }) => {
    const formData = JSON.parse(window.localStorage.getItem('proposal'));

    const onSubmit = data => {
        const policy = data.formData;

        window.dataLayer.push({
            event: 'proposalFormSubmission',
            step: 4,
            insurance_start: policy.startDate.date,
            insurance_payment_frequency: policy.paymentFrequency.term,
            insurance_roadside_assist: policy.includeRoadsideAssist,
        });

        saveLocalData({ policy });
        saveContinue();
    };

    const validate = (data, errors) => {
        // Validate policy start date is less than 30 days in the future
        if (data.startDate.date) {
            const date = new Date();
            const currentDate = date.setDate(date.getDate() - 1);
            const maxCutoffDate = date.setDate(date.getDate() + 31);
            const startDate = new Date(data.startDate.date);

            if (startDate > maxCutoffDate) {
                errors.startDate.date.addError(
                    'Can’t be a date more then 30 days past current date'
                );
            } else if (startDate < currentDate) {
                errors.startDate.date.addError('Can’t be a date in the past');
            }
        }

        return errors;
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">4. Policy</h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                validate={validate}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Policy.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Policy.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Policy;
