import React from 'react';

export default {
    representedDealer: {
        classNames: 'representedDealer-wrapper',
        'ui:title': <span>Represented Dealer</span>,
        'ui:description':
            "Please enter the email of the dealer you are completing this quote for. If left blank, the quote's dealer will be this current account",
        'ui:placeholder': 'Email of the dealer you are representing',
        'ui:help': (
            <p className="notice-note">
                We take this time to advise that we are unable to provide cover via this online
                quoting tool for:
            </p>
        ),
    },
    criminalRecord: {
        'ui:title': (
            <span>
                <b>01.</b> A motor vehicle where any insured, owner or driver has any conviction (or
                charges pending) for any offence related to or involving*
            </span>
        ),
        'ui:description': (
            <ul>
                <li>Fraud or deception</li>
                <li>Theft or illegal taking of a motor vehicle</li>
                <li>Burglary</li>
                <li>Arson</li>
                <li>
                    Sexual or violent offending (other than common assault) to which the Criminal
                    Records (Clean Slate) Act, 2004 does not apply.
                </li>
            </ul>
        ),
        'ui:widget': 'radio',
    },
    insuredLastFiveYears: {
        'ui:title': (
            <span>
                <b>02.</b> A motor vehicle where any insured, owner or driver, in the last 5 years
                has:*
            </span>
        ),
        'ui:description': (
            <ul>
                <li>
                    Had more than 3 convictions (including pending charges) for any driving offence
                    involving alcohol or drugs
                </li>
                <li>Been involved in more than 3 accidents regardless of fault</li>
                <li>Suffered loss or damage in excess of $20,000 whether insured or not</li>
                <li>Had a vehicle stolen or burnt</li>
            </ul>
        ),
        'ui:widget': 'radio',
    },
    vehicleType: {
        'ui:title': (
            <span>
                <b>03.</b> A motor vehicle which is:*
            </span>
        ),
        'ui:description': (
            <ul>
                <li>Valued over $70,000</li>
                <li>Over 3.5 tonnes in weight</li>
                <li>Used for business purposes</li>
                <li>A motorcycle, trailer, bus, motorhome or caravan</li>
            </ul>
        ),
        'ui:widget': 'radio',
    },
};
