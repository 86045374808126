export default {
    type: 'object',
    required: ['recovery', 'location'],
    properties: {
        recovery: {
            type: 'object',
            required: ['recovered'],
            properties: {
                recovered: {
                    type: 'boolean',
                },
            },
            dependencies: {
                recovered: {
                    oneOf: [
                        {
                            properties: {
                                recovered: {
                                    enum: [false],
                                },
                            },
                        },
                        {
                            required: ['date', 'locationFound'],
                            properties: {
                                recovered: {
                                    enum: [true],
                                },
                                date: {
                                    type: 'string',
                                    format: 'date',
                                },
                                time: {
                                    type: 'string',
                                },
                                locationFound: {
                                    type: 'string',
                                },
                                finder: {
                                    type: 'string',
                                },
                                towed: {
                                    type: 'object',
                                    properties: {
                                        wasTowed: {
                                            type: 'boolean',
                                        },
                                    },
                                    dependencies: {
                                        wasTowed: {
                                            oneOf: [
                                                {
                                                    properties: {
                                                        wasTowed: {
                                                            enum: [false],
                                                        },
                                                    },
                                                },
                                                {
                                                    properties: {
                                                        wasTowed: {
                                                            enum: [true],
                                                        },
                                                        details: {
                                                            type: 'string',
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                stolenDamagedParts: {
                                    type: 'string',
                                },
                                preferredRepairer: {
                                    type: 'object',
                                    required: ['hasPreferredRepairer'],
                                    properties: {
                                        hasPreferredRepairer: {
                                            type: 'boolean',
                                            default: true,
                                        },
                                    },
                                    dependencies: {
                                        hasPreferredRepairer: {
                                            oneOf: [
                                                {
                                                    properties: {
                                                        hasPreferredRepairer: {
                                                            enum: [false],
                                                        },
                                                    },
                                                },
                                                {
                                                    required: ['name', 'address', 'phone'],
                                                    properties: {
                                                        hasPreferredRepairer: {
                                                            enum: [true],
                                                        },
                                                        name: {
                                                            type: 'string',
                                                        },
                                                        address: {
                                                            type: 'string',
                                                        },
                                                        phone: {
                                                            type: 'string',
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                repairEstimate: {
                                    type: 'object',
                                    properties: {
                                        obtainedEstimate: {
                                            type: 'boolean',
                                            default: false,
                                        },
                                    },
                                    dependencies: {
                                        obtainedEstimate: {
                                            oneOf: [
                                                {
                                                    properties: {
                                                        obtainedEstimate: {
                                                            enum: [false],
                                                        },
                                                    },
                                                },
                                                {
                                                    properties: {
                                                        obtainedEstimate: {
                                                            enum: [true],
                                                        },
                                                        estimateTotal: {
                                                            type: 'string',
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                bestContact: {
                                    type: 'object',
                                    properties: {
                                        isPolicyHolder: {
                                            type: 'boolean',
                                        },
                                    },
                                    dependencies: {
                                        isPolicyHolder: {
                                            oneOf: [
                                                {
                                                    properties: {
                                                        isPolicyHolder: {
                                                            enum: [true],
                                                        },
                                                    },
                                                },
                                                {
                                                    properties: {
                                                        isPolicyHolder: {
                                                            enum: [false],
                                                        },
                                                        name: {
                                                            type: 'string',
                                                        },
                                                        phone: {
                                                            type: 'string',
                                                        },
                                                        email: {
                                                            type: 'string',
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                        },
                    ],
                },
            },
        },
        relevantInfo: {
            type: 'string',
        },
        location: {
            type: 'string',
        },
    },
};
