import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';

const Drivers = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('proposal'));
    const formData = localData && localData.drivers ? localData.drivers : {};

    const onSubmit = data => {
        const drivers = data.formData;

        window.dataLayer.push({
            event: 'proposalFormSubmission',
            step: 3,
            driver_primary_holder: drivers.primaryDriver.insuredInCharge,
            driver_license_type: drivers.primaryDriver.license.type,
            driver_license_country: drivers.primaryDriver.license.country,
        });

        saveLocalData({ drivers });
        saveContinue();
    };

    const validate = (data, errors) => {
        // Validate policy holder is over 25 years of age
        const ageErrorMessage =
            'Our policies cover drivers who are under the age of 25yrs, but you will need to be approved by our underwriter first. If you would like a quote please call the team directly on 0800 805 965';

        const validAge = dob => {
            const currentYear = new Date().getFullYear();
            const cutoffDate = new Date().setFullYear(currentYear - 25);
            const dobDate = new Date(dob);

            return dobDate <= cutoffDate;
        };

        if (
            data.primaryDriver.driver &&
            data.primaryDriver.driver.dob &&
            validAge(data.primaryDriver.driver.dob) === false
        ) {
            errors.primaryDriver.driver.dob.addError(ageErrorMessage);
        }

        if (data.additionalDrivers) {
            data.additionalDrivers.forEach((additionalDriver, index) => {
                if (
                    additionalDriver.driver &&
                    additionalDriver.driver.dob &&
                    validAge(additionalDriver.driver.dob) === false
                ) {
                    errors.additionalDrivers[index].driver.dob.addError(ageErrorMessage);
                }
            });
        }

        return errors;
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    3. Driver details
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                validate={validate}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Drivers.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Drivers.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Drivers;
