export default {
    type: 'object',
    required: ['location', 'description', 'drugsAlcohol', 'police'],
    properties: {
        date: {
            type: 'string',
            format: 'date-time',
        },
        time: {
            type: 'string',
        },
        location: {
            type: 'string',
        },
        speedLimit: {
            type: 'string',
        },
        speed: {
            type: 'string',
        },
        description: {
            type: 'string',
        },

        fault: {
            type: 'object',
            properties: {
                fault: {
                    type: 'string',
                },
                // reason: {
                //     type: 'string',
                // },
            },
        },

        drugsAlcohol: {
            type: 'object',
            required: ['consumedDrugsAlcohol'],
            properties: {
                consumedDrugsAlcohol: {
                    type: 'boolean',
                },
            },
            dependencies: {
                consumedDrugsAlcohol: {
                    oneOf: [
                        {
                            properties: {
                                consumedDrugsAlcohol: {
                                    enum: [false],
                                },
                            },
                        },
                        {
                            required: ['substancesQty', 'time'],
                            properties: {
                                consumedDrugsAlcohol: {
                                    enum: [true],
                                },
                                substancesQty: {
                                    type: 'string',
                                },
                                time: {
                                    type: 'string',
                                },
                            },
                        },
                    ],
                },
            },
        },

        police: {
            type: 'object',
            required: ['policeAttended'],
            properties: {
                policeAttended: {
                    type: 'boolean',
                },
            },
            dependencies: {
                policeAttended: {
                    oneOf: [
                        {
                            properties: {
                                policeAttended: {
                                    enum: [false],
                                },
                            },
                        },
                        {
                            required: ['reference'],
                            properties: {
                                policeAttended: {
                                    enum: [true],
                                },
                                reference: {
                                    type: 'string',
                                },
                                test: {
                                    type: 'object',
                                    required: ['completed'],
                                    properties: {
                                        completed: {
                                            type: 'boolean',
                                        },
                                    },
                                    dependencies: {
                                        completed: {
                                            oneOf: [
                                                {
                                                    properties: {
                                                        completed: {
                                                            enum: [false],
                                                        },
                                                    },
                                                },
                                                {
                                                    required: ['result'],
                                                    properties: {
                                                        completed: {
                                                            enum: [true],
                                                        },
                                                        result: {
                                                            type: 'string',
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                        },
                    ],
                },
            },
        },

        sketches: {
            type: 'object',
            properties: {
                stepHeading: { type: 'null' },
                stepDescription: { type: 'null' },
                listOfFiles: {
                    type: 'array',
                    title: 'A list of files',
                    minItems: 0,
                    maxItems: 3,
                    items: {
                        type: 'object',
                        properties: {
                            file: {
                                type: 'string',
                                format: 'data-url',
                            },
                        },
                    },
                },
            },
        },

        witness: {
            type: 'object',
            required: ['independentWitness'],
            properties: {
                stepHeading: { type: 'null' },
                independentWitness: {
                    type: 'boolean',
                },
            },
            dependencies: {
                independentWitness: {
                    oneOf: [
                        {
                            properties: {
                                independentWitness: {
                                    enum: [false],
                                },
                            },
                        },
                        {
                            required: ['witnessName', 'witnessPhone', 'witnessPartyRelationships'],
                            properties: {
                                independentWitness: {
                                    enum: [true],
                                },
                                witnessName: {
                                    type: 'string',
                                },
                                witnessPhone: {
                                    type: 'string',
                                },
                                witnessPartyRelationships: {
                                    type: 'string',
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
};
