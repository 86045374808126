/* eslint-disable import/first */

// Styles
import './sass/index.scss';

require('custom-event-polyfill');
require('intersection-observer');

// Polyfills
import 'core-js/es/array';
// import 'core-js/es/string/starts-with';
import 'core-js/es/string/pad-start';
import 'core-js/es/promise/finally';

// Scripts
import './javascript/index';
import './javascript/custom';
import './javascript/addressfinder';

/* eslint-enable import/first */
