import React, { useState, useEffect } from 'react';

const Submitting = ({ submissionError }) => {
    const [step, setStep] = useState(0);

    useEffect(() => {
        setTimeout(() => setStep(1), 1500);
        setTimeout(() => setStep(2), 3000);
    }, []);

    return (
        <div className="form-submitting-state">
            {submissionError ? (
                <div className="caption">
                    <h1>There seems to be an issue</h1>
                    <div>
                        <p className="visible">
                            Please try again later or contact support@gocover.co.nz
                        </p>
                    </div>
                </div>
            ) : (
                <div className="caption">
                    <h1>Please hold tight...</h1>
                    <div>
                        <p className={step === 0 ? 'visible' : undefined}>
                            Your quote is being processed
                        </p>
                        <p className={step === 1 ? 'visible' : undefined}>
                            We are creating you quote PDF
                        </p>
                        <p className={step === 2 ? 'visible' : undefined}>
                            Your quote is on the way
                        </p>
                    </div>
                </div>
            )}

            {!submissionError && (
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )}
        </div>
    );
};

export default Submitting;
