const date = new Date();
const today = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];

export default {
    type: 'object',
    required: ['paymentOption'],
    // required: ['paymentOption', 'firstPaymentDate'],
    properties: {
        paymentOption: {
            type: 'object',
            required: ['paymentOptionValue'],
            properties: {
                paymentOptionValue: {
                    type: 'string',
                    enum: [
                        'Annual - $XXX',
                        'Monthly (12 payments) - $XXX',
                        'Fortnightly (25 payments) - $XXX',
                        'Weekly (50 payments) - $XXX',
                    ],
                },
            },
            dependencies: {
                paymentOptionValue: {
                    // required: ['accountName', 'bankDetail'],
                    oneOf: [
                        {
                            required: ['policyBankOwnerDetails', 'firstPaymentDate'],
                            properties: {
                                paymentOptionValue: {
                                    enum: [
                                        'Monthly (12 payments) - $XXX',
                                        'Fortnightly (25 payments) - $XXX',
                                        'Weekly (50 payments) - $XXX',
                                    ],
                                },
                                accountName: {
                                    type: 'string',
                                },
                                policyBankOwnerDetails: {
                                    type: 'object',
                                    required: ['policyBankOwnerSame'],
                                    properties: {
                                        policyBankOwnerSame: {
                                            type: 'boolean',
                                        },
                                    },
                                    dependencies: {
                                        policyBankOwnerSame: {
                                            oneOf: [
                                                {
                                                    properties: {
                                                        policyBankOwnerSame: {
                                                            enum: [true],
                                                        },
                                                    },
                                                },
                                                {
                                                    required: ['bankOwnerPhone', 'bankOwnerEmail'],
                                                    properties: {
                                                        policyBankOwnerSame: {
                                                            enum: [false],
                                                        },
                                                        bankOwnerPhone: {
                                                            type: 'string',
                                                        },
                                                        bankOwnerEmail: {
                                                            type: 'string',
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                bankName: {
                                    type: 'string',
                                },
                                bankDetail: {
                                    type: 'object',
                                    required: ['bank', 'branch', 'accountNumber', 'suffix'],
                                    properties: {
                                        bank: {
                                            type: 'string',
                                            minLength: 2,
                                            maxLength: 2,
                                        },
                                        branch: {
                                            type: 'string',
                                            minLength: 4,
                                            maxLength: 4,
                                        },
                                        accountNumber: {
                                            type: 'string',
                                            minLength: 7,
                                            maxLength: 7,
                                        },
                                        suffix: {
                                            type: 'string',
                                            maxLength: 3,
                                        },
                                    },
                                },
                                firstPaymentDate: {
                                    type: 'string',
                                    format: 'date',
                                    default: today,
                                    format: 'paymentDate',
                                },
                                firstPaymentDateSubheading: { type: 'null' },
                            },
                        },
                        {
                            properties: {
                                paymentOptionValue: {
                                    enum: ['Annual - $XXX'],
                                },
                            },
                        },
                    ],
                },
            },
        },
        notes: {
            type: 'string',
        },
    },
};
