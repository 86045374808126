import React from 'react';

const yearsRange = () => {
    const currentYear = new Date().getFullYear();
    const futureYear = currentYear + 2;

    return [currentYear, futureYear];
};

export default {
    policyStartDate: {
        classNames: 'field-date invert-years',
        'ui:title': (
            <span>
                <b>01.</b> Policy cover will start from*
            </span>
        ),
        format: 'date-time',
        'ui:widget': 'alt-date',
        'ui:options': {
            yearsRange: yearsRange(),
            hideNowButton: true,
            hideClearButton: true,
        },
    },
    policyType: {
        policyTypeValue: {
            classNames: 'field-parent',
            'ui:title': (
                <span>
                    <b>02.</b> Required policy type*
                </span>
            ),
            'ui:widget': 'radio',
        },
        stepSubheading: {
            classNames: 'field-step-note-content',
            'ui:title':
                'Policy owner and two specified drivers - restricted to one specified driver under the age of 25 only.',
        },
        stepSubheading2: {
            classNames: 'field-step-note-content',
            'ui:title':
                'Covers the principal driver and one other named driver. Unspecified drivers covered, but subject to an additional $300 excess, plus underage excess if applicable',
        },
    },
    roadsideAssistance: {
        'ui:title': (
            <span>
                <b>03.</b> Roadside assistance
            </span>
        ),
        'ui:widget': 'checkboxes',
    },
};
