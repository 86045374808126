const dateRange = (start, end) =>
    Array.from({ length: (end - start) / 1 + 1 }, (_, i) => start + i * 1).reverse();

export default {
    definitions: {
        vehicle: {
            type: 'object',
            required: [
                'typeOfCover',
                // 'make',
                // 'model',
                // 'manufactureYear',
                'registrationVIN',
                'value',
                'storage',
                'hasAlarm',
                'hasImmobilizer',
                'modifications',
                'financeOwning',
            ],
            properties: {
                typeOfCover: {
                    type: 'object',
                    required: ['typeOfCoverType'],
                    properties: {
                        typeOfCoverType: {
                            type: 'string',
                            enum: ['Full Comprehensive', 'Third Party Fire & Theft'],
                        },
                    },
                    dependencies: {
                        typeOfCoverType: {
                            oneOf: [
                                {
                                    properties: {
                                        typeOfCoverType: {
                                            enum: ['Third Party Fire & Theft'],
                                        },
                                    },
                                },
                                {
                                    required: ['specifiedDriver'],
                                    properties: {
                                        typeOfCoverType: {
                                            enum: ['Full Comprehensive'],
                                        },
                                        specifiedDriver: {
                                            type: 'boolean',
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                namedDriverPlusPolicy: {
                    type: 'boolean',
                },
                registrationVIN: {
                    type: 'string',
                },
                make: {
                    type: 'string',
                },
                model: {
                    type: 'string',
                },
                manufactureYear: {
                    type: 'number',
                    enum: dateRange(1950, new Date().getFullYear()),
                },
                value: {
                    type: 'string',
                },
                storage: {
                    type: 'object',
                    required: ['storageType'],
                    properties: {
                        storageType: {
                            type: 'string',
                            enum: ['Locked garage', 'Driveway/Carport', 'Street', 'Other'],
                        },
                    },
                    dependencies: {
                        storageType: {
                            oneOf: [
                                {
                                    properties: {
                                        storageType: {
                                            enum: ['Locked garage', 'Driveway/Carport', 'Street'],
                                        },
                                    },
                                },
                                {
                                    required: ['storageOther'],
                                    properties: {
                                        storageType: {
                                            enum: ['Other'],
                                        },
                                        storageOther: {
                                            type: 'string',
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                hasAlarm: {
                    type: 'boolean',
                },
                hasImmobilizer: {
                    type: 'boolean',
                },
                modifications: {
                    type: 'object',
                    required: ['modificationsRequired'],
                    properties: {
                        modificationsRequired: {
                            type: 'boolean',
                        },
                    },
                    dependencies: {
                        modificationsRequired: {
                            oneOf: [
                                {
                                    required: ['modificationsDetails'],
                                    properties: {
                                        modificationsRequired: {
                                            enum: [true],
                                        },
                                        modificationsDetails: {
                                            type: 'string',
                                        },
                                    },
                                },
                                {
                                    properties: {
                                        modificationsRequired: {
                                            enum: [false],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                financeOwning: {
                    type: 'object',
                    required: ['financeOwningRequired'],
                    properties: {
                        financeOwningRequired: {
                            type: 'boolean',
                        },
                    },
                    dependencies: {
                        financeOwningRequired: {
                            oneOf: [
                                {
                                    properties: {
                                        financeOwningRequired: {
                                            enum: [true],
                                        },
                                        financeOwningName: {
                                            type: 'string',
                                        },
                                        financeOwningDetails: {
                                            type: 'string',
                                        },
                                    },
                                },
                                {
                                    properties: {
                                        financeOwningRequired: {
                                            enum: [false],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                storageAddress: {
                    type: 'object',
                    required: ['storageAddressRequired'],
                    properties: {
                        storageAddressRequired: {
                            type: 'boolean',
                        },
                    },
                    dependencies: {
                        storageAddressRequired: {
                            oneOf: [
                                {
                                    required: [
                                        'storageAddress_addressLine1',
                                        'storageAddress_suburb',
                                        'storageAddress_city',
                                        'storageAddress_postcode',
                                    ],
                                    properties: {
                                        storageAddressRequired: {
                                            enum: [false],
                                        },
                                        storageAddress_addressLine1: {
                                            type: 'string',
                                        },
                                        storageAddress_addressLine2: {
                                            type: 'string',
                                        },
                                        storageAddress_suburb: {
                                            type: 'string',
                                        },
                                        storageAddress_city: {
                                            type: 'string',
                                        },
                                        storageAddress_postcode: {
                                            type: 'string',
                                        },
                                    },
                                },
                                {
                                    properties: {
                                        storageAddressRequired: {
                                            enum: [true],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
            },
        },
    },
    type: 'object',
    properties: {
        stepHeading: { type: 'null' },
        stepDescription: { type: 'null' },

        vehicles: {
            type: 'array',
            minItems: 1,
            maxItems: 2,
            items: {
                $ref: '#/definitions/vehicle',
            },
        },
    },
};
