export default {
    registration: {
        'ui:title': 'What is your vehicle registration number?',
        'ui:widget': 'vehicleLookup',
        'ui:placeholder': 'eg ABC123',
    },
    make: {
        'ui:title': 'Make',
        'ui:placeholder': 'e.g. Toyota',
    },
    model: {
        'ui:title': 'Model',
        'ui:placeholder': 'e.g. Corolla',
    },
    manufactureYear: {
        classNames: 'field-select',
        'ui:widget': 'select',
        'ui:title': "What was your vehicle's year of manufacture?",
        'ui:placeholder': 'Pick the year of manufacture',
    },
    conditionNote: {
        conditionNoteRequired: {
            classNames: 'field-parent',
            'ui:title': 'Did your car have any pre-existing damage prior to the theft? ',
            'ui:widget': 'radio',
        },
        conditionNote: {
            'ui:title': 'Please provide details',
        },
    },
    modifications: {
        modificationsRequired: {
            classNames: 'field-parent',
            'ui:title': 'Does your vehicle have any modifications?',
            'ui:widget': 'radio',
        },
        modifications: {
            'ui:title': 'Please provide details',
        },
    },
    owner: {
        ownerRequired: {
            classNames: 'field-parent',
            'ui:title': 'Are you the sole owner of the vehicle?*',
            'ui:widget': 'radio',
        },
        otherOwner: {
            'ui:title': 'Who else owns this vehicle?',
        },
    },
    finance: {
        financeOwing: {
            classNames: 'field-parent',
            'ui:title': 'Is there any finance owing on the vehicle?*',
            'ui:widget': 'radio',
        },

        financeCompany: {
            'ui:title': 'What is the name of the finance company',
            'ui:description': '',
        },

        financeOutstanding: {
            'ui:title': 'If known, what amount is still outstanding to the finance company?',
            'ui:description': '',
        },
    },
};
