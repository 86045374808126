export default {
    stepDescription: {
        classNames: 'field-step-description',
        'ui:title':
            'Up to two people can be policy holders. Policy holders are not necessarily a listed driver of the vehicle(s).',
    },
    policyHolder: {
        basicInfo: {
            stepSubheading: {
                classNames: 'field-step-subheading',
                'ui:title': 'Primary Policy Holder',
            },
            firstName: {
                'ui:title': 'First name*',
            },
            lastName: {
                'ui:title': 'Last name*',
            },
            dob: {
                classNames: 'field-date',
                'ui:title': 'Date of birth*',
                'ui:widget': 'alt-date',
                'ui:options': {
                    hideNowButton: true,
                    hideClearButton: true,
                },
            },
        },
        addressLine1: {
            'ui:title': 'Address line 1*',
        },
        addressLine2: {
            'ui:title': 'Address line 2 (Optional)',
        },
        suburb: {
            'ui:title': 'Suburb*',
        },
        city: {
            'ui:title': 'City*',
        },
        postCode: {
            'ui:title': 'Postcode*',
        },
        storedAtSameLocation: {
            storedAtSameLocationRequired: {
                classNames: 'field-parent',
                'ui:title': 'Will the vehicle be kept at the same address?',
                'ui:widget': 'radio',
            },
            storedAtSameLocation_addressLine1: {
                'ui:title': 'Address line 1*',
            },
            storedAtSameLocation_addressLine2: {
                'ui:title': 'Address line 2 (Optional)',
            },
            storedAtSameLocation_suburb: {
                'ui:title': 'Suburb*',
            },
            storedAtSameLocation_city: {
                'ui:title': 'City*',
            },
            storedAtSameLocation_postCode: {
                'ui:title': 'Postcode*',
            },
        },
        email: {
            'ui:title': 'Email address*',
        },
        phone: {
            'ui:title': 'Phone number*',
        },
    },
    additionalHolder: {
        additionalHolderRequired: {
            classNames: 'field-parent',
            'ui:title': 'Would you like to add another policy holder?*',
            'ui:widget': 'radio',
        },
        additionalPolicyHolders: {
            classNames: 'field-policy-holders',
            'ui:options': {
                orderable: false,
            },
            items: {
                stepSubheading: {
                    classNames: 'field-step-subheading',
                    'ui:title': 'Additional Policy Holder',
                },
                firstName: {
                    'ui:title': 'First name*',
                },
                lastName: {
                    'ui:title': 'Last name*',
                },
                dob: {
                    classNames: 'field-date',
                    'ui:title': 'Date of birth*',
                    'ui:widget': 'alt-date',
                    'ui:options': {
                        hideNowButton: true,
                        hideClearButton: true,
                    },
                },
            },
        },
    },
};
