import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';
// import convert from 'xml-js';

import schema from './schema';
import uiSchema from './uiSchema';

const Policy = ({ saveContinue, saveLocalData, transformErrors, quoteId }) => {
    // const [fetchedVins, setFetchedVins] = useState([]);
    const localData = JSON.parse(window.localStorage.getItem(`dealerQuote-${quoteId}`));
    const formData = localData && localData.policy ? localData.policy : {};

    useEffect(() => {
        const yearSelect = document.getElementById('root_policyStartDate_year');
        const options = Array.from(yearSelect.querySelectorAll('option'));

        options.reverse();
        options.forEach(o => yearSelect.appendChild(o));
    }, []);

    const validate = (validateFormData, errors) => {
        const dateNow = new Date();
        const dateSelected = new Date(validateFormData.policyStartDate);

        if (dateSelected < dateNow.setDate(dateNow.getDate() - 1)) {
            errors.policyStartDate.addError('Date Cant be in the Past');
        }
        return errors;
    };

    const onSubmit = data => {
        const policy = data.formData;

        window.dataLayer.push({
            // eslint-disable-line
            event: 'dealerQuoteSubmission',
            step: 3,
        });

        saveLocalData({ policy });
        saveContinue();
    };

    return (
        <>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                validate={validate}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Policy.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Policy.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
    quoteId: PropTypes.string.isRequired,
};

export default Policy;
