import 'isomorphic-fetch';
import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import convert from 'xml-js';
import { useMutation } from '@apollo/react-hooks';

import { StepProgress } from './components';

import { Insured, Vehicles, Drivers, Policy, Declaration } from './steps';

const ProposalFormApp = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formError, setFormError] = useState();
    const [step, setStep] = useState(0);

    const localData = JSON.parse(window.localStorage.getItem('proposal'));

    const handleSuccess = () => {
        window.dataLayer.push({
            event: 'proposalFormSubmission',
            step: 7,
        });
        window.localStorage.removeItem('proposal'); // @TODO: Enable on live - Aaron Rose

        if (window.location.href.includes('?')) {
            window.location.href += '&fs=1';
        } else {
            window.location.href += '?fs=1';
        }
    };

    const handleError = error => {
        if (error) {
            setFormError();
            console.error(error); // eslint-disable-line no-console
        }
    };

    const fetchCarData = async vin => {
        try {
            const response = await fetch(
                `https://carjam.co.nz/api/car/?key=32085C8102AEC1F33D9C83F52BBA9A28846D70D8&plate=${vin}`
            );

            let data = await response.text();
            data = convert.xml2json(data);
            data = JSON.parse(data);
            data = data.elements
                ? data.elements[0]?.elements?.find(e => e.name === 'idh')
                : { elements: [] };

            const vehicle = data?.elements?.find(e => e.name === 'vehicle').elements ?? [];
            return vehicle;
        } catch (e) {
            console.log('ERROR: ', e);
        }

        return null;
    };

    /* eslint-disable */
    const [submitForm, { mutationData }] = useMutation(
        gql`
            mutation Proposal(
                $PolicyHolders: [PersonInputType]
                $Drivers: [PersonInputType]
                $Vehicles: [VehicleInputType]
                # $AreYouNZMCAMember: Boolean!
                # $AreYouNZMCAMember_Yes_Describe: String
                # $AreYouNZResident: Boolean!
                # $IsPermanentResidence: Boolean!
                # $LikeToKnowMoreAboutAddPolicy: Boolean
                $IsPrimaryPolicyHolderADriver: Boolean!
                $PolicyStartDate: String!
                $PaymentFrequency: String!
                $includeRoadsideAssist: Boolean
                # $AnyMedicalCondition: Boolean!
                # $AnyMedicalCondition_Yes_Describe: String
                $AnyDrivingOffence: Boolean!
                $AnyDrivingOffence_Yes_Describe: String
                $HadAnyLicenceEndorsements: Boolean!
                $HadAnyLicenceEndorsements_Yes_Describe: String
                # $MadeInsuranceClaimOrAnyAccident: Boolean!
                # $MadeInsuranceClaimOrAnyAccident_Yes_Describe: String
                $HadUnderwriterDecline: Boolean!
                $HadUnderwriterDecline_Yes_Describe: String
                $AnyFurtherInformation: Boolean!
                $AnyFurtherInformation_Yes_Describe: String
                $InformationCorrect: Boolean!
                $AuthorityToObtainDetailsFromOtherParties: Boolean!
                $TandC: Boolean!
            ) {
                Proposal(
                    PolicyHolders: $PolicyHolders
                    Drivers: $Drivers
                    Vehicles: $Vehicles
                    # AreYouNZMCAMember: $AreYouNZMCAMember
                    # AreYouNZMCAMember_Yes_Describe: $AreYouNZMCAMember_Yes_Describe
                    # AreYouNZResident: $AreYouNZResident
                    # IsPermanentResidence: $IsPermanentResidence
                    # LikeToKnowMoreAboutAddPolicy: $LikeToKnowMoreAboutAddPolicy
                    IsPrimaryPolicyHolderADriver: $IsPrimaryPolicyHolderADriver
                    PolicyStartDate: $PolicyStartDate
                    PaymentFrequency: $PaymentFrequency
                    includeRoadsideAssist: $includeRoadsideAssist
                    # AnyMedicalCondition: $AnyMedicalCondition
                    # AnyMedicalCondition_Yes_Describe: $AnyMedicalCondition_Yes_Describe
                    AnyDrivingOffence: $AnyDrivingOffence
                    AnyDrivingOffence_Yes_Describe: $AnyDrivingOffence_Yes_Describe
                    HadAnyLicenceEndorsements: $HadAnyLicenceEndorsements
                    HadAnyLicenceEndorsements_Yes_Describe: $HadAnyLicenceEndorsements_Yes_Describe
                    # MadeInsuranceClaimOrAnyAccident: $MadeInsuranceClaimOrAnyAccident
                    # MadeInsuranceClaimOrAnyAccident_Yes_Describe: $MadeInsuranceClaimOrAnyAccident_Yes_Describe
                    HadUnderwriterDecline: $HadUnderwriterDecline
                    HadUnderwriterDecline_Yes_Describe: $HadUnderwriterDecline_Yes_Describe
                    AnyFurtherInformation: $AnyFurtherInformation
                    AnyFurtherInformation_Yes_Describe: $AnyFurtherInformation_Yes_Describe
                    InformationCorrect: $InformationCorrect
                    AuthorityToObtainDetailsFromOtherParties: $AuthorityToObtainDetailsFromOtherParties
                    TandC: $TandC
                ) {
                    PolicyStartDate
                }
            }
        `,
        {
            onCompleted: data => handleSuccess(data),
            onError: errors => handleError(errors),
        }
    );
    /* eslint-enable */

    useEffect(() => {
        if (localData && localData.step && localData.step < step) {
            setStep(localData.step);
        }
    });

    const steps = ['Insured', 'Vehicles', 'Drivers', 'Policy', 'Declaration'];

    const saveLocalData = data => {
        window.localStorage.setItem(
            'proposal',
            JSON.stringify({
                ...localData,
                ...data,
                step: step + 1,
            })
        );
    };

    const saveContinue = () => {
        setStep(step + 1);
        window.scrollTo(0, 0);
    };

    const saveFinish = () => {
        window.scrollTo(0, 0);
        setIsSubmitting(true);

        const { insured, vehicles, drivers, policy, declaration } = JSON.parse(
            window.localStorage.getItem('proposal')
        );

        const policyHoldersData = [
            {
                FirstName: insured.policyHolder.basicInfo.firstName,
                LastName: insured.policyHolder.basicInfo.lastName,
                BirthDate: insured.policyHolder.basicInfo.dob,
                Email: insured.policyHolder.email,
                Phone: insured.policyHolder.phone,
                Address: {
                    AddressLine1: insured.policyHolder.addressLine1,
                    AddressLine2: insured.policyHolder.addressLine2,
                    Suburb: insured.policyHolder.suburb,
                    City: insured.policyHolder.city,
                    Postcode: insured.policyHolder.postCode,
                },
            },
        ];

        if (insured.additionalPolicyHolders) {
            insured.additionalPolicyHolders.forEach(policyHolder => {
                policyHoldersData.push({
                    FirstName: policyHolder.firstName,
                    LastName: policyHolder.lastName,
                    // FullName: policyHolder.fullName,
                    BirthDate: policyHolder.dob,
                });
            });
        }

        const driversData = drivers.primaryDriver.insuredInCharge
            ? [
                  {
                      // FirstName: insured.policyHolder.basicInfo.firstName,
                      // LastName: insured.policyHolder.basicInfo.lastName,
                      FullName: insured.policyHolder.basicInfo.fullName,
                      BirthDate: insured.policyHolder.basicInfo.dob,
                      LicenceType: drivers.primaryDriver.license.type,
                      Licence_CountryOfIssue: drivers.primaryDriver.license.country,
                      //   PercentageUsed: drivers.primaryDriver.driver.percentageUsed,
                      PercentageUsed: '100',
                  },
              ]
            : [
                  {
                      // FirstName: drivers.primaryDriver.driver.firstName,
                      // LastName: drivers.primaryDriver.driver.lastName,
                      FullName: drivers.primaryDriver.driver.fullName,
                      BirthDate: drivers.primaryDriver.driver.dob,
                      LicenceType: drivers.primaryDriver.driver.licenseType,
                      Licence_CountryOfIssue: drivers.primaryDriver.driver.licenseCountry,
                      PercentageUsed: drivers.primaryDriver.driver.percentageUsed,
                  },
              ];

        if (drivers.additionalDrivers) {
            drivers.additionalDrivers.forEach(driver => {
                driversData.push({
                    // FirstName: driver.driver.firstName,
                    // LastName: driver.driver.lastName,
                    FullName: driver.driver.fullName,
                    BirthDate: driver.driver.dob,
                    LicenceType: driver.driver.licenseType,
                    Licence_CountryOfIssue: driver.driver.licenseCountry,
                    PercentageUsed: driver.driver.percentageUsed,
                });
            });
        }

        const vehiclesData = vehicles.vehicles.map(vehicle => ({
            // MeetsMinRequirements: vehicle.meetsMinRequirements,
            TypeOfCover: vehicle.typeOfCoverType,
            Make: vehicle.make,
            Model: vehicle.model,
            ManufactureYear: vehicle.manufactureYear,
            RegistrationOrVIN: vehicle.registrationVIN,
            VehicleValue: vehicle.value,
            // VehicleWeight: vehicle.weight,
            HasAlarm: vehicle.hasAlarm,
            HasImmobilizer: vehicle.hasImmobilizer,
            Modifications: vehicle.modificationsRequired,
            Modifications_Details: vehicle.modificationsDetails,
            // IsUsedByBusiness: vehicle.intendedUse.business.isBusinessUse,
            // IsUsedByBusiness_Yes_Describe: vehicle.intendedUse.business.details,
            // ForTransportationOfLiveStock: vehicle.intendedUse.livestock.isLivestockUse,
            // ForTransportationOfLiveStock_Yes_Describe: vehicle.intendedUse.livestock.details,
            // ForTransportationOfHazardous: vehicle.intendedUse.hazards.isHazardousUse,
            // ForTransportationOfHazardous_Yes_Describe: vehicle.intendedUse.hazards.details,
            FinanceOwing: vehicle.financeOwningRequired,
            FinanceOwing_Name: vehicle.financeOwningName,
            FinanceOwing_Details: vehicle.financeOwningDetails,
            // ForCarryingPassengersForHire: vehicle.intendedUse.passengers.isPassengerUse,
            // ForCarryingPassengersForHire_Yes_Describe: vehicle.intendedUse.passengers.details,
            // NoClaimDiscount: vehicle.noClaims.entitled,
            // NoClaimDiscount_Yes_Describe: vehicle.noClaims.details,
            StorageAddress: vehicle.storageAddress.storageAddressRequired,
            StorageAddress_AddressLine1: vehicle.storageAddress.storageAddress_addressLine1,
            StorageAddress_AddressLine2: vehicle.storageAddress.storageAddress_addressLine2,
            StorageAddress_Suburb: vehicle.storageAddress.storageAddress_suburb,
            StorageAddress_City: vehicle.storageAddress.storageAddress_city,
            StorageAddress_Postcode: vehicle.storageAddress.storageAddress_postcode,
            WhereVehicleStored: vehicle.storage.storageType,
            WhereVehicleStored_Details: vehicle.storage.storageOther,
        }));

        submitForm({
            variables: {
                PolicyHolders: policyHoldersData,
                Drivers: driversData,
                Vehicles: vehiclesData,
                // AreYouNZMCAMember: insured.policyHolder.nzmca.isMember,
                // AreYouNZMCAMember_Yes_Describe: insured.policyHolder.nzmca.membershipNumber,
                StoredAtSameLocation:
                    insured.policyHolder.storedAtSameLocation.storedAtSameLocationRequired,
                StoredAtSameLocation_AddressLine1:
                    insured.policyHolder.storedAtSameLocation.storedAtSameLocation_addressLine1,
                StoredAtSameLocation_AddressLine2:
                    insured.policyHolder.storedAtSameLocation.storedAtSameLocation_addressLine2,
                StoredAtSameLocation_Suburb:
                    insured.policyHolder.storedAtSameLocation.storedAtSameLocation_suburb,
                StoredAtSameLocation_City:
                    insured.policyHolder.storedAtSameLocation.storedAtSameLocation_city,
                StoredAtSameLocation_Postcode:
                    insured.policyHolder.storedAtSameLocation.storedAtSameLocation_postCode,
                // AreYouNZResident: insured.policyHolder.nzResident,
                // IsPermanentResidence: insured.policyHolder.permanentResidence.isPermanentResidence,
                // LikeToKnowMoreAboutAddPolicy: insured.policyHolder.permanentResidence.contentsInsurance,
                IsPrimaryPolicyHolderADriver: drivers.primaryDriver.insuredInCharge,
                PolicyStartDate: policy.startDate.date,
                PaymentFrequency: policy.paymentFrequency.term,
                includeRoadsideAssist: policy.includeRoadsideAssist,
                AnyAccidents: declaration.d1.value,
                AnyAccidents_Yes_Describe: declaration.d1.details,
                AnyMedicalCondition: declaration.d1.value,
                AnyMedicalCondition_Yes_Describe: declaration.d1.details,
                AnyDemerits: declaration.d2.value,
                AnyDemerits_Yes_Describe: declaration.d2.details,
                HadAnyLicenceEndorsements: declaration.d3.value,
                HadAnyLicenceEndorsements_Yes_Describe: declaration.d3.details,
                MadeInsuranceClaimOrAnyAccident: declaration.d4.value,
                MadeInsuranceClaimOrAnyAccident_Yes_Describe: declaration.d4.details,
                AnyDrivingOffence: declaration.d4.value,
                AnyDrivingOffence_Yes_Describe: declaration.d4.details,
                AnyConvictions: declaration.d5.value,
                AnyConvictions_Yes_Describe: declaration.d5.details,
                HadUnderwriterDecline: declaration.d6.value,
                HadUnderwriterDecline_Yes_Describe: declaration.d6.details,
                AnyFurtherInformation: declaration.d7.value,
                AnyFurtherInformation_Yes_Describe: declaration.d7.details,
                InformationCorrect: declaration.a1,
                AuthorityToObtainDetailsFromOtherParties: declaration.a2,
                TandC: declaration.a3,
            },
        });

        window.dataLayer.push({
            event: 'proposalFormSubmission',
            step: 6,
        });
    };

    const transformErrors = errors =>
        errors.map(error => {
            if (error.name === 'required') {
                error.message = 'Required'; // eslint-disable-line
            }

            if (error.name === 'format' && error.params.format === 'email') {
                error.message = 'Invalid email address'; // eslint-disable-line
            }

            if (error.name === 'enum') {
                if (error.property === '.startDate.date') return false;
                error.message = 'More information required'; // eslint-disable-line
            }

            if (error.name === 'oneOf') {
                error.message = ''; // eslint-disable-line
            }

            return error;
        });

    const renderFormStep = () => {
        switch (step) {
            case 0:
                return (
                    <Insured
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 1:
                return (
                    <Vehicles
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                        fetchCarData={fetchCarData}
                    />
                );
            case 2:
                return (
                    <Drivers
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 3:
                return (
                    <Policy
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 4:
                if (isSubmitting) {
                    return (
                        <div>
                            <h1>We are processing your request</h1>
                            <p>
                                We appreciate your time and in a moment you will be notified with a
                                confirmation email.
                            </p>
                        </div>
                    );
                }

                return (
                    <Declaration
                        saveContinue={saveFinish}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="l-multi-step-form">
            <StepProgress
                steps={steps}
                currentStep={step}
                setStep={targetStep => () => setStep(targetStep)}
            />
            <div className="l-multi-step-form__wrapper">
                {formError && (
                    <div>
                        <strong>Error:</strong> {formError}
                    </div>
                )}
                {renderFormStep()}
                <div className="fineprint">
                    Approved applicants only. Policy criteria, terms and conditions apply.
                    <br />
                    See our{' '}
                    <a rel="noopener noreferrer" target="_blank" href="/forms-and-policies/">
                        policy wording
                    </a>{' '}
                    for more information.
                </div>
            </div>
            <StepProgress
                steps={steps}
                currentStep={step}
                setStep={targetStep => () => setStep(targetStep)}
            />
        </div>
    );
};

export default ProposalFormApp;
