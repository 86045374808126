const formatDate = date => {
    const cleanDate = new Date(date);
    const year = cleanDate.getFullYear();
    const month = `${cleanDate.getMonth() + 1}`.padStart(2, '0');
    const day = `${cleanDate.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export default {
    type: 'object',
    required: ['includeRoadsideAssist'],
    properties: {
        startDate: {
            type: 'object',
            required: ['date'],
            properties: {
                date: {
                    type: 'string',
                    format: 'date',
                },
            },
            dependencies: {
                date: {
                    oneOf: [
                        {
                            properties: {
                                date: {
                                    enum: [
                                        formatDate(new Date()),
                                        formatDate(new Date().setDate(new Date().getDate() + 1)),
                                        formatDate(new Date().setDate(new Date().getDate() + 2)),
                                    ],
                                },
                                stepSubheading: { type: 'null' },
                            },
                        },
                    ],
                },
            },
        },

        paymentFrequency: {
            type: 'object',
            required: ['term'],
            properties: {
                term: {
                    type: 'string',
                    enum: ['Weekly', 'Fortnightly', 'Monthly', 'Annually'],
                },
            },
            dependencies: {
                term: {
                    oneOf: [
                        {
                            properties: {
                                term: {
                                    enum: ['Weekly', 'Fortnightly', 'Monthly', 'Annually'],
                                },
                            },
                        },
                    ],
                },
            },
        },

        includeRoadsideAssist: {
            type: 'boolean',
        },
    },
};
