export default {
    make: {
        'ui:title': 'Make',
    },
    model: {
        'ui:title': 'Model',
    },
    cc: {
        'ui:title': 'Engine CC',
    },
    registration: {
        'ui:title': 'Registration',
    },
    manufactureYear: {
        classNames: 'field-select',
        'ui:widget': 'select',
        'ui:title': "What was your vehicle's year of manufacture?",
        'ui:placeholder': 'Pick the year of manufacture',
    },
    wofCOF: {
        'ui:title': 'WOF/COF',
    },
    odometer: {
        'ui:title': 'Odometer reading',
    },
};
