export default {
    d1: {
        value: {
            'ui:title':
                'In the last five years have you, or anyone else who will drive this vehicle, been involved in any motor vehicle accidents, losses, damage or theft (whether a claim was made or not)?*',
            'ui:widget': 'radio',
            classNames: 'field-parent',
        },
        details: {
            'ui:title': 'Please indicate dates and approximate value*',
        },
    },

    d2: {
        value: {
            'ui:title':
                'Do you, or anyone else who will drive this vehicle currently have 50 or more demerit points?*',
            'ui:widget': 'radio',
            classNames: 'field-parent',
        },
        details: {
            'ui:title': 'Please include dates and details of any offence*',
        },
    },

    d3: {
        value: {
            'ui:title':
                'Have you or anyone else who will drive this vehicle, had special conditions imposed on a motor policy?*',
            'ui:widget': 'radio',
            classNames: 'field-parent',
        },
        details: {
            'ui:title': 'Please provide details*',
        },
    },

    d4: {
        value: {
            'ui:title':
                'Have you, or anyone else who will drive this vehicle ever had their licence cancelled, suspended, endorsed, or been disqualified from driving?*',
            'ui:widget': 'radio',
            classNames: 'field-parent',
        },
        details: {
            'ui:title': 'Please include dates and details of any offence*',
        },
    },

    d5: {
        value: {
            'ui:title':
                'Do you, or anyone else who will drive this vehicle have any criminal convictions (including driving offences) or have any prosecutions or charges pending (subject to the rights set out in the Criminal Records (Clean Slate) Act 2004)?*',
            'ui:widget': 'radio',
            classNames: 'field-parent',
        },
        details: {
            'ui:title': 'Please include dates and details of any offence',
        },
    },

    d6: {
        value: {
            'ui:title':
                'Have you, or anyone else who will drive this vehicle ever had insurance avoided, refused, cancelled, renewal not offered, special conditions imposed or a claim declined?*',
            'ui:widget': 'radio',
            classNames: 'field-parent',
        },
        details: {
            'ui:title': 'Please include dates and details*',
        },
    },
    d7: {
        value: {
            'ui:title':
                'Is there any further information likely to affect this insurance (such as bankruptcy)?*',
            'ui:widget': 'radio',
            classNames: 'field-parent',
        },
        details: {
            'ui:title': 'Please include dates and details*',
        },
    },

    privacyActHeading: { classNames: 'field-step-note-heading' },
    privacyActContent: { classNames: 'field-step-note-content' },
    disclosureHeading: { classNames: 'field-step-note-heading' },
    disclosureContent: { classNames: 'field-step-note-content' },

    agreementHeading: {
        classNames: 'field-step-heading',
        'ui:title': 'Agreement',
        'ui:description': '5.2.',
    },

    agreementNote: {
        classNames: 'field-step-note-content',
    },
};
