export default {
    date: {
        classNames: 'field-date field-date-time',
        'ui:title': 'What date and time did the incident happen?',
        'ui:widget': 'alt-datetime',
        'ui:options': {
            hideNowButton: true,
            hideClearButton: true,
            yearsRange: [new Date().getFullYear() - 2, new Date().getFullYear()],
        },
    },
    time: {
        classNames: 'field-time',
        'ui:title': 'What time did the accident happen?*',
    },
    location: {
        'ui:title': 'What was the accident location? (street name, suburb, town, post code)*',
    },
    speedLimit: {
        'ui:title': 'What was the speed limit (kph)?',
        'ui:placeholder': 'Enter the speed limit in kph',
    },
    speed: {
        'ui:title': 'If your car was moving, What speed were you going prior to braking (kph)?',
        'ui:placeholder': 'Enter your speed in kph',
    },
    description: {
        'ui:title': 'Describe the accident in detail*',
        'ui:widget': 'textarea',
        'ui:options': {
            rows: 5,
        },
    },

    fault: {
        fault: {
            'ui:title': 'Who do you consider at fault for the accident and why?',
        },
        // reason: {
        //     'ui:title': 'Why is the at fault party liable?',
        // },
    },

    drugsAlcohol: {
        consumedDrugsAlcohol: {
            classNames: 'field-parent',
            'ui:title':
                'Did the driver consume liquor and/or drugs (including medication) within 12 hours prior to the accident?*',
            'ui:widget': 'radio',
        },
        substancesQty: {
            'ui:title': 'What substances and what quantity?',
        },
        time: {
            'ui:title': 'What time did the driver take the intoxicating substances?',
        },
    },

    police: {
        policeAttended: {
            classNames: 'field-parent',
            'ui:title': 'Did the police attend?*',
            'ui:widget': 'radio',
        },
        reference: {
            'ui:title': 'Police reference number',
        },
        test: {
            completed: {
                classNames: 'field-parent',
                'ui:title': 'Was a breathalyser or blood test, or any other such test done?',
                'ui:widget': 'radio',
            },
            result: {
                'ui:title': 'What was the result?*',
            },
        },
    },

    sketches: {
        stepHeading: {
            classNames: 'field-step-heading',
            'ui:title': '4.2. Sketch plan or photographs of the accident',
            // 'ui:description': '4.2.',
        },
        stepDescription: {
            classNames: 'field-step-description',
            'ui:title':
                'Please draw and upload a sketch of the Incident. Indicate street names, direction of vehicles and any other important information. Please also upload any useful photographs of damage etc.',
        },
        listOfFiles: {
            classNames: 'field-uploads',
            items: {
                file: {
                    classNames: 'field-upload',
                    'ui:title': 'Upload file (JPG, PNG OR PDF)',
                    'ui:options': {
                        accept: ['.pdf', '.jpg', '.jpeg', '.png'],
                    },
                },
            },
        },
    },

    witness: {
        stepHeading: {
            classNames: 'field-step-heading',
            'ui:title': '4.3. Witness',
            // 'ui:description': '4.3.',
        },
        independentWitness: {
            classNames: 'field-parent',
            'ui:title': 'Was there an independent witness?*',
            'ui:widget': 'radio',
        },
        witnessName: {
            'ui:title': 'Witness name*',
        },
        witnessPhone: {
            'ui:title': 'Witness phone number*',
        },
        witnessPartyRelationships: {
            'ui:title': 'Witness relationship to driver and/or other parties*',
        },
    },
};
