import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema.js'; // eslint-disable-line
import { INCIDENT_DATE_ERROR, VALID_DATE } from '../../../../../config';

const Theft = ({ saveContinue, saveLocalData, transformErrors, fileUpload }) => {
    const localData = JSON.parse(window.localStorage.getItem('theft'));
    const formData =
        localData && localData.theft ? localData.theft : { date: new Date().toISOString() };

    const onSubmit = data => {
        const theft = data.formData;

        const ids = [];

        if (theft.sketches2.listOfFiles && theft.sketches2.listOfFiles.length) {
            theft.sketches2.listOfFiles.map(
                file =>
                    file.file &&
                    fileUpload({ variables: { file: file.file } }).then(fileData => {
                        ids.push({ file: fileData.data.FileUpload });
                        localStorage.setItem('ids', JSON.stringify(ids));
                    })
            );
        }

        theft.sketches2.listOfFiles = [];
        window.dataLayer.push({
            event: 'theftClaimSubmission',
            step: 4,
            theft_what_happened: theft.whatHappened,
            theft_date: theft.date,
            theft_time: theft.time,
            theft_discovered: theft.discovery,
            theft_address: theft.address,
            theft_last_seen: theft.lastSeen,
            vehicle_storage: theft.housed,
            vehicle_locked: theft.locked,
            theft_partial: theft.parts,
            theft_reported: theft.police.reported,
            theft_reported_date: theft.police.notified,
            theft_suspicions: theft.suspicions.haveOffenderSuspicions,
            documents_uploaded: ids,
        });

        saveLocalData({ theft });
        saveContinue();
    };

    const validate = (data, errors) => {
        const files = data.sketches2.listOfFiles;

        if (files) {
            files.forEach((item, index) => {
                if (item.file) {
                    const fileSize = parseInt(
                        (item.file.replace(/=/g, '').length * 0.75) / 1e6,
                        10
                    );
                    if (fileSize > 20) {
                        errors.sketches2.listOfFiles.addError(
                            `File ${index + 1} file size must be less than 20MB.`
                        );
                    }
                }
            });
        }

        if (data.date && VALID_DATE(data.date) === false) {
            errors.date.addError(INCIDENT_DATE_ERROR);
        }

        return errors;
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    4.1. Theft details
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                validate={validate}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Theft.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
    fileUpload: () => {},
};

Theft.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
    fileUpload: PropTypes.func,
};

export default Theft;
