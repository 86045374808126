export const PHONE_NUMBERS_VALIDATION_MESSAGE = 'Phone Number should contain 8-15 digits';

export const INCIDENT_DATE_ERROR = "Sorry, the date of the accident can't be in the future";

export const VALID_DATE = dateInput => {
    const date = dateInput.replace(/([+-]\d{2}:?\d{2}|Z)$/, '');
    const currentDate = new Date();
    const enteredDate = new Date(date);

    currentDate.setSeconds(0);
    enteredDate.setSeconds(0);

    return enteredDate <= currentDate;
};
