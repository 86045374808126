import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';
import { PHONE_NUMBERS_VALIDATION_MESSAGE } from '../../../../../config';

const Driver = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('theft'));
    const formData = localData && localData.driver ? localData.driver : {};

    const onSubmit = data => {
        const driver = data.formData;

        window.dataLayer.push({
            event: 'theftClaimSubmission',
            step: 3,
            // driver_incharge: driver.insuredInCharge,
            name: driver.fullName,
            date_of_birth: driver.dob,
            address: `${driver.addressLine1} ${driver.addressLine2}`,
            suburb: driver.suburb,
            city: driver.city,
            postcode: driver.postcode,
            email: driver.email,
            phone: driver.phone,
            vehicle_owner_consent: driver.consent,
            insurance_refused:
                driver.insuranceRefused && driver.insuranceRefused.hadInsuranceRefused,
            insurance_accidents: driver.accident && driver.accident.hadAccident,
            insurance_license_infringes: driver.convicted && driver.convicted.beenConvicted,
            insurance_license_suspended: driver.endorsements && driver.endorsements.hadEndorsements,
        });

        saveLocalData({ driver });
        saveContinue();
    };

    const validate = (data, errors) => {
        const { phone } = data;

        if (phone && (phone.length < 8 || phone.length > 15)) {
            errors.phone.addError(PHONE_NUMBERS_VALIDATION_MESSAGE);
        }

        return errors;
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    3. Person driving the insured vehicle (driver)
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={e => {
                    console.log('error', e);
                    window.scrollTo(0, 0);
                }}
                validate={validate}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Driver.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Driver.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Driver;
