import 'isomorphic-unfetch';
import React, { PureComponent } from 'react';
import { render } from 'react-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

import TheftForm from './apps/TheftForm';
import AccidentForm from './apps/AccidentForm';
import ProposalForm from './apps/ProposalForm';
import ValuationForm from './apps/ValuationForm';
import DealerQuoteForm from './apps/DealerQuoteForm';

const THEFT_FORM_EL = document.getElementById('js-react-theft-form');
const ACCIDENT_FORM_EL = document.getElementById('js-react-accident-form');
const PROPOSAL_FORM_EL = document.getElementById('js-react-proposal-form');
const VALUATION_FORM_EL = document.getElementById('js-react-valuation-form');
const DEAL_QUOTE_FORM_EL = document.getElementById('js-react-dealer-quote-form');

const client = new ApolloClient({
    fetchOptions: { fetch },
    uri: `/api`,
});

window.dataLayer = window.dataLayer || [];

class Application extends PureComponent {
    render() {
        return [
            THEFT_FORM_EL && <TheftForm key="theftForm" />,
            ACCIDENT_FORM_EL && <AccidentForm key="accidentForm" />,
            PROPOSAL_FORM_EL && <ProposalForm key="proposalForm" />,
            VALUATION_FORM_EL && <ValuationForm key="valuationForm" />,
            DEAL_QUOTE_FORM_EL && <DealerQuoteForm key="dealerQuoteForm" />,
        ];
    }
}

export default () => {
    if (
        THEFT_FORM_EL ||
        ACCIDENT_FORM_EL ||
        PROPOSAL_FORM_EL ||
        VALUATION_FORM_EL ||
        DEAL_QUOTE_FORM_EL
    ) {
        render(
            <ApolloProvider client={client}>
                <Application />
            </ApolloProvider>,
            THEFT_FORM_EL ||
                ACCIDENT_FORM_EL ||
                PROPOSAL_FORM_EL ||
                VALUATION_FORM_EL ||
                DEAL_QUOTE_FORM_EL
        );
    }
};
