export default {
    type: 'object',
    required: [
        'anyAccidentsOrLosses',
        'specialConditions',
        'licenceEndorsement',
        'convictionsOrFines',
        'insuranceDeclined',
        'criminalActivity',
        'anyFurtherInformation',
    ],
    properties: {
        anyAccidentsOrLosses: {
            type: 'object',
            required: ['anyAccidentsOrLossesRequired'],
            properties: {
                anyAccidentsOrLossesRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                anyAccidentsOrLossesRequired: {
                    oneOf: [
                        {
                            required: ['anyAccidentsOrLosses_details'],
                            properties: {
                                anyAccidentsOrLossesRequired: {
                                    enum: [true],
                                },
                                anyAccidentsOrLosses_details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                anyAccidentsOrLossesRequired: {
                                    enum: [false],
                                },
                            },
                        },
                    ],
                },
            },
        },
        specialConditions: {
            type: 'object',
            required: ['specialConditionsRequired'],
            properties: {
                specialConditionsRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                specialConditionsRequired: {
                    oneOf: [
                        {
                            required: ['specialConditions_details'],
                            properties: {
                                specialConditionsRequired: {
                                    enum: [true],
                                },
                                specialConditions_details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                specialConditionsRequired: {
                                    enum: [false],
                                },
                            },
                        },
                    ],
                },
            },
        },
        licenceEndorsement: {
            type: 'object',
            required: ['licenceEndorsementRequired'],
            properties: {
                licenceEndorsementRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                licenceEndorsementRequired: {
                    oneOf: [
                        {
                            required: ['licenceEndorsement_details'],
                            properties: {
                                licenceEndorsementRequired: {
                                    enum: [true],
                                },
                                licenceEndorsement_details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                licenceEndorsementRequired: {
                                    enum: [false],
                                },
                            },
                        },
                    ],
                },
            },
        },
        convictionsOrFines: {
            type: 'object',
            required: ['convictionsOrFinesRequired'],
            properties: {
                convictionsOrFinesRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                convictionsOrFinesRequired: {
                    oneOf: [
                        {
                            required: ['convictionsOrFines_details'],
                            properties: {
                                convictionsOrFinesRequired: {
                                    enum: [true],
                                },
                                convictionsOrFines_details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                convictionsOrFinesRequired: {
                                    enum: [false],
                                },
                            },
                        },
                    ],
                },
            },
        },
        insuranceDeclined: {
            type: 'object',
            required: ['insuranceDeclinedRequired'],
            properties: {
                insuranceDeclinedRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                insuranceDeclinedRequired: {
                    oneOf: [
                        {
                            required: ['insuranceDeclined_details'],
                            properties: {
                                insuranceDeclinedRequired: {
                                    enum: [true],
                                },
                                insuranceDeclined_details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                insuranceDeclinedRequired: {
                                    enum: [false],
                                },
                            },
                        },
                    ],
                },
            },
        },
        criminalActivity: {
            type: 'object',
            required: ['criminalActivityRequired'],
            properties: {
                criminalActivityRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                criminalActivityRequired: {
                    oneOf: [
                        {
                            required: ['criminalActivity_details'],
                            properties: {
                                criminalActivityRequired: {
                                    enum: [true],
                                },
                                criminalActivity_details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                criminalActivityRequired: {
                                    enum: [false],
                                },
                            },
                        },
                    ],
                },
            },
        },
        anyFurtherInformation: {
            type: 'object',
            required: ['anyFurtherInformationRequired'],
            properties: {
                anyFurtherInformationRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                anyFurtherInformationRequired: {
                    oneOf: [
                        {
                            required: ['anyFurtherInformation_details'],
                            properties: {
                                anyFurtherInformationRequired: {
                                    enum: [true],
                                },
                                anyFurtherInformation_details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                anyFurtherInformationRequired: {
                                    enum: [false],
                                },
                            },
                        },
                    ],
                },
            },
        },
        insuranceLast12Months: {
            type: 'string',
        },
    },
};
