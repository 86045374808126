import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';
import { PHONE_NUMBERS_VALIDATION_MESSAGE } from '../../../../../config';

const Recovery = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('theft'));
    const formData = localData && localData.recovery ? localData.recovery : {};

    const onSubmit = data => {
        const recovery = data.formData;

        saveLocalData({ recovery: data.formData });

        window.dataLayer.push({
            event: 'theftClaimSubmission',
            step: 5,
            theft_recovered: recovery.recovery.recovered,
            theft_recovered_date: recovery.recovery.date,
            theft_recovered_time: recovery.recovery.time,
            theft_recovered_location: recovery.recovery.location,
            theft_recovered_individual: recovery.recovery.finder,
            theft_recovered_towtruck: recovery.recovery.towed && recovery.recovery.towed.wasTowed,
            theft_recovered_towtruck_name:
                recovery.recovery.towed && recovery.recovery.towed.details,
            insurance_inspection_contact:
                recovery.recovery.preferredRepairer && recovery.recovery.preferredRepairer.name,
            insurance_preferred_repairer:
                recovery.recovery.preferredRepairer &&
                recovery.recovery.preferredRepairer.hasPreferredRepairer,
            insurance_preferred_repairer_quoted:
                recovery.recovery.repairEstimate &&
                recovery.recovery.repairEstimate.obtainedEstimate,
            insurance_preferred_repairer_value:
                recovery.recovery.repairEstimate && recovery.recovery.repairEstimate.estimateTotal,
        });

        saveContinue();
    };
    const validate = (data, errors) => {
        const { recovery } = data;

        if (
            recovery.recovered &&
            recovery.preferredRepairer.hasPreferredRepairer &&
            (recovery.preferredRepairer.phone &&
                (recovery.preferredRepairer.phone.length < 8 ||
                    recovery.preferredRepairer.phone.length > 15))
        ) {
            errors.recovery.preferredRepairer.phone.addError(PHONE_NUMBERS_VALIDATION_MESSAGE);
        }

        if (
            recovery.recovered &&
            !recovery.bestContact.isPolicyHolder &&
            (recovery.bestContact.phone &&
                (recovery.bestContact.phone.length < 8 || recovery.bestContact.phone.length > 15))
        ) {
            errors.recovery.bestContact.phone.addError(PHONE_NUMBERS_VALIDATION_MESSAGE);
        }

        return errors;
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    5. Recovery and damage
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
                validate={validate}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Recovery.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Recovery.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Recovery;
