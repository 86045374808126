import React from 'react';
import PropTypes from 'prop-types';
import Entries from './Entries';
// import Form from 'react-jsonschema-form';

// import schema from './schema';
// import uiSchema from './uiSchema';

const Pricing = ({ saveContinue, saveLocalData, quoteId }) => {
    // const localData = JSON.parse(window.localStorage.getItem('dealerQuote'));
    // const formData = localData && localData.ownership ? localData.ownership : {};

    // const onSubmit = data => {
    //     const pricing = data.`formData;

    //     window.dataLayer.push({
    //         event: 'valuationClaimSubmission',
    //         step: 1,
    //     });

    //     saveLocalData({ pricing });
    //     saveContinue();
    // };

    return (
        <div>
            <Entries saveContinue={saveContinue} saveLocalData={saveLocalData} quoteId={quoteId} />
        </div>
    );
};

Pricing.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    // transformErrors: () => {},
};

Pricing.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    quoteId: PropTypes.string.isRequired,
    // transformErrors: PropTypes.func,
};

export default Pricing;
