import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';

const Declaration = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('proposal'));
    const formData = localData && localData.declaration ? localData.declaration : {};

    const onSubmit = data => {
        const declaration = data.formData;

        window.dataLayer.push({
            event: 'proposalFormSubmission',
            step: 5,
            insurance_medical_condition: declaration.d1.value,
            insurance_driving_offence: declaration.d2.value,
            driver_endorsed: declaration.d3.value,
            insurance_claims_made: declaration.d4.value,
            insurance_underwriter_decline: declaration.d5.value,
            insurance_further_details: declaration.d6.value,
        });

        saveLocalData({ declaration });
        saveContinue();
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    5.1. Declarations
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Declaration.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Declaration.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Declaration;
