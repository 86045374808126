export default {
    type: 'object',
    required: ['berths', 'toilet', 'shower'],
    properties: {
        berths: {
            type: 'integer',
        },
        toilet: {
            type: 'boolean',
        },
        shower: {
            type: 'boolean',
        },
    },
};
