import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';

const Owners = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('valuation'));
    const formData = localData && localData.owner ? localData.owner : {};

    const onSubmit = data => {
        const owner = data.formData;

        window.dataLayer.push({
            event: 'valuationClaimSubmission',
            step: 1,
        });

        saveLocalData({ owner });
        saveContinue();
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">1. Owner Details</h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Owners.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Owners.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Owners;
