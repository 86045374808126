import React from 'react';
import PropTypes from 'prop-types';

const StepProgress = ({ steps, currentStep, setStep }) => (
    <div className="c-step-progress">
        {steps.map((step, index) => (
            <button
                key={step}
                className={`c-step-progress__step${index === currentStep ? ' is-current' : ''}${
                    index < currentStep ? ' is-previous' : ''
                }`}
                onClick={setStep(index)}
                type="button"
            >
                <div className="c-step-progress__step-bubble">{index + 1}</div>
                <div className="c-step-progress__step-label">{step}</div>
            </button>
        ))}
    </div>
);

StepProgress.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    currentStep: PropTypes.number.isRequired,
    setStep: PropTypes.func.isRequired,
};

export default StepProgress;
