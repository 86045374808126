export default {
    definitions: {
        licenseType: {
            type: 'string',
            enum: ['Full', 'Restricted', 'Learner', 'International'],
        },
        licenseCountry: {
            type: 'string',
            enum: [
                'New Zealand',
                'Afghanistan',
                'Albania',
                'Algeria',
                'Andorra',
                'Angola',
                'Anguilla',
                'Antigua & Barbuda',
                'Argentina',
                'Armenia',
                'Aruba',
                'Australia',
                'Austria',
                'Azerbaijan',
                'Bahamas',
                'Bahrain',
                'Bangladesh',
                'Barbados',
                'Belarus',
                'Belgium',
                'Belize',
                'Benin',
                'Bermuda',
                'Bhutan',
                'Bolivia',
                'Bosnia & Herzegovina',
                'Botswana',
                'Brazil',
                'British Virgin Islands',
                'Brunei',
                'Bulgaria',
                'Burkina Faso',
                'Burundi',
                'Cambodia',
                'Cameroon',
                'Cape Verde',
                'Cayman Islands',
                'Chad',
                'Chile',
                'China',
                'Colombia',
                'Congo',
                'Cook Islands',
                'Costa Rica',
                'Cote D Ivoire',
                'Croatia',
                'Cruise Ship',
                'Cuba',
                'Cyprus',
                'Czech Republic',
                'Denmark',
                'Djibouti',
                'Dominica',
                'Dominican Republic',
                'Ecuador',
                'Egypt',
                'El Salvador',
                'Equatorial Guinea',
                'Estonia',
                'Ethiopia',
                'Falkland Islands',
                'Faroe Islands',
                'Fiji',
                'Finland',
                'France',
                'French Polynesia',
                'French West Indies',
                'Gabon',
                'Gambia',
                'Georgia',
                'Germany',
                'Ghana',
                'Gibraltar',
                'Greece',
                'Greenland',
                'Grenada',
                'Guam',
                'Guatemala',
                'Guernsey',
                'Guinea',
                'Guinea Bissau',
                'Guyana',
                'Haiti',
                'Honduras',
                'Hong Kong',
                'Hungary',
                'Iceland',
                'India',
                'Indonesia',
                'Iran',
                'Iraq',
                'Ireland',
                'Isle of Man',
                'Israel',
                'Italy',
                'Jamaica',
                'Japan',
                'Jersey',
                'Jordan',
                'Kazakhstan',
                'Kenya',
                'Kuwait',
                'Kyrgyz Republic',
                'Laos',
                'Latvia',
                'Lebanon',
                'Lesotho',
                'Liberia',
                'Libya',
                'Liechtenstein',
                'Lithuania',
                'Luxembourg',
                'Macau',
                'Macedonia',
                'Madagascar',
                'Malawi',
                'Malaysia',
                'Maldives',
                'Mali',
                'Malta',
                'Mauritania',
                'Mauritius',
                'Mexico',
                'Moldova',
                'Monaco',
                'Mongolia',
                'Montenegro',
                'Montserrat',
                'Morocco',
                'Mozambique',
                'Namibia',
                'Nepal',
                'Netherlands',
                'Netherlands Antilles',
                'New Caledonia',
                'Nicaragua',
                'Niger',
                'Nigeria',
                'Norway',
                'Oman',
                'Pakistan',
                'Palestine',
                'Panama',
                'Papua New Guinea',
                'Paraguay',
                'Peru',
                'Philippines',
                'Poland',
                'Portugal',
                'Puerto Rico',
                'Qatar',
                'Reunion',
                'Romania',
                'Russia',
                'Rwanda',
                'Saint Pierre & Miquelon',
                'Samoa',
                'San Marino',
                'Satellite',
                'Saudi Arabia',
                'Senegal',
                'Serbia',
                'Seychelles',
                'Sierra Leone',
                'Singapore',
                'Slovakia',
                'Slovenia',
                'South Africa',
                'South Korea',
                'Spain',
                'Sri Lanka',
                'St Kitts & Nevis',
                'St Lucia',
                'St Vincent',
                'St. Lucia',
                'Sudan',
                'Suriname',
                'Swaziland',
                'Sweden',
                'Switzerland',
                'Syria',
                'Taiwan',
                'Tajikistan',
                'Tanzania',
                'Thailand',
                "Timor L'Este",
                'Togo',
                'Tonga',
                'Trinidad & Tobago',
                'Tunisia',
                'Turkey',
                'Turkmenistan',
                'Turks & Caicos',
                'Uganda',
                'Ukraine',
                'United Arab Emirates',
                'United Kingdom',
                'Uruguay',
                'Uzbekistan',
                'Venezuela',
                'Vietnam',
                'Virgin Islands (US)',
                'Yemen',
                'Zambia',
                'Zimbabwe',
            ],
        },
        driver: {
            type: 'object',
            required: ['fullName', 'dob', 'licenseType', 'licenseCountry', 'percentageUsed'],
            properties: {
                fullName: {
                    type: 'string',
                },
                dob: {
                    type: 'string',
                    format: 'date',
                },
                licenseType: {
                    $ref: '#/definitions/licenseType',
                },
                licenseCountry: {
                    $ref: '#/definitions/licenseCountry',
                },
                percentageUsed: {
                    type: 'string',
                },
            },
        },
    },
    type: 'object',
    properties: {
        stepDescription: { type: 'null' },

        primaryDriver: {
            type: 'object',
            required: ['insuredInCharge'],
            properties: {
                stepSubheading: { type: 'null' },
                insuredInCharge: {
                    type: 'boolean',
                },
            },

            dependencies: {
                insuredInCharge: {
                    oneOf: [
                        {
                            properties: {
                                insuredInCharge: {
                                    enum: [true],
                                },
                                license: {
                                    type: 'object',
                                    required: ['type', 'country'],
                                    properties: {
                                        type: {
                                            $ref: '#/definitions/licenseType',
                                        },
                                        country: {
                                            $ref: '#/definitions/licenseCountry',
                                        },
                                    },
                                },
                            },
                        },
                        {
                            properties: {
                                insuredInCharge: {
                                    enum: [false],
                                },
                                driver: {
                                    $ref: '#/definitions/driver',
                                },
                            },
                        },
                    ],
                },
            },
        },

        additionalDrivers: {
            type: 'array',
            minItems: 0,
            maxItems: 2,
            items: {
                properties: {
                    stepSubheading: { type: 'null' },
                    driver: {
                        $ref: '#/definitions/driver',
                    },
                },
            },
        },
    },
};
