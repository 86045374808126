import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';

const Vehicles = ({ saveContinue, saveLocalData, transformErrors, fetchCarData }) => {
    const localData = JSON.parse(window.localStorage.getItem('proposal'));
    const formData = localData && localData.vehicles ? localData.vehicles : {};
    const [fetchedVins, setFetchedVins] = useState([]);

    function debounce(func, wait) {
        let timeout;
        return function(...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }

    const handleKeyDown = event => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown.bind(this));
    }, []);

    const populateCarData = async (vin, i) => {
        if (fetchedVins[i] === vin) {
            return;
        }

        const carData = await fetchCarData(vin);
        const vins = fetchedVins;
        const make = carData.find(e => e.name === 'make')?.elements[0].text;
        const model = carData.find(e => e.name === 'model')?.elements[0].text;
        const yearOfManufacture = carData.find(e => e.name === 'year_of_manufacture')?.elements[0]
            .text;

        if (make) document.getElementById(`root_vehicles_${i}_make`).value = make;
        if (model) document.getElementById(`root_vehicles_${i}_model`).value = model;
        if (yearOfManufacture)
            document.getElementById(`root_vehicles_${i}_manufactureYear`).value = yearOfManufacture;

        vins[i] = vin;
        setFetchedVins(vins);
    };

    const onSubmit = data => {
        const vehicles = data.formData;

        // const vehiclesData = vehicles.vehicles.map(vehicle => ({
        //     vehicle_meets_min_requirements: vehicle.meetsMinRequirements,
        //     vehicle_make: vehicle.make,
        //     vehicle_model: vehicle.model,
        //     vehicle_year_built: vehicle.manufactureYear,
        //     vehicle_rego: vehicle.registrationVIN,
        //     vehicle_value: vehicle.value,
        //     vehicle_weight: vehicle.weight,
        //     vehicle_business_use: vehicle.intendedUse.business.isBusinessUse,
        //     vehicle_livestock_use: vehicle.intendedUse.livestock.isLivestockUse,
        //     vehicle_hazchem_use: vehicle.intendedUse.hazards.isHazardousUse,
        //     vehicle_for_hire: vehicle.intendedUse.passengers.isPassengerUse,
        //     vehicle_no_claim_discount: vehicle.noClaims.entitled,
        //     vehicle_storage: vehicle.storage.storageType,
        // }));

        // window.dataLayer.push({
        //     event: 'proposalFormSubmission',
        //     step: 2,
        //     vehicles: vehiclesData,
        // });

        vehicles.vehicles = vehicles.vehicles.map((vehicle, i) => ({
            ...vehicle,
            make: document.getElementById(`root_vehicles_${i}_make`).value,
            model: document.getElementById(`root_vehicles_${i}_model`).value,
            manufactureYear: document.getElementById(`root_vehicles_${i}_manufactureYear`).value,
        }));

        // vehicles.vehicleInformation.informationType.model = document.getElementById(
        //     `root_vehicleInformation_informationType_model`
        // ).value;

        // vehicles.vehicleInformation.informationType.year = document.getElementById(
        //     `root_vehicleInformation_informationType_year`
        // ).value;

        // vehicles.vehicleInformation.informationType.registration = document.getElementById(
        //     `root_vehicleInformation_informationType_registration`
        // ).value;

        // vehicles.vehicleInformation.informationType.type = document.getElementById(
        //     `root_vehicleInformation_informationType_type`
        // ).value;

        // vehicles.vehicleInformation.informationType.ccRating = document.getElementById(
        //     `root_vehicleInformation_informationType_ccRating`
        // ).value;

        saveLocalData({ vehicles });
        saveContinue();
    };

    return (
        <>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
                omitExtraData
                liveOmit
                onChange={debounce(value => {
                    const vins = value.formData.vehicles.map(v => v.registrationVIN);

                    if (vins) {
                        vins.forEach((vin, i) => {
                            populateCarData(vin, i);
                        });
                    }
                }, 250)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Vehicles.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
    fetchCarData: () => {},
};

Vehicles.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
    fetchCarData: PropTypes.func,
};

export default Vehicles;
