const dateRange = (start, end) =>
    Array.from({ length: (end - start) / 1 + 1 }, (_, i) => start + i * 1).reverse();

export default {
    type: 'object',
    required: ['owner', 'finance'],
    properties: {
        registration: {
            type: 'string',
        },
        make: {
            type: 'string',
        },
        model: {
            type: 'string',
        },
        manufactureYear: {
            type: 'integer',
            enum: dateRange(1900, new Date().getFullYear()),
        },
        conditionNote: {
            type: 'object',
            properties: {
                conditionNoteRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                conditionNoteRequired: {
                    oneOf: [
                        {
                            required: ['conditionNote'],
                            properties: {
                                conditionNoteRequired: {
                                    enum: [true],
                                },
                                conditionNote: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                conditionNoteRequired: {
                                    enum: [false],
                                },
                            },
                        },
                    ],
                },
            },
        },
        modifications: {
            type: 'object',
            properties: {
                modificationsRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                modificationsRequired: {
                    oneOf: [
                        {
                            required: ['modifications'],
                            properties: {
                                modificationsRequired: {
                                    enum: [true],
                                },
                                modifications: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                modificationsRequired: {
                                    enum: [false],
                                },
                            },
                        },
                    ],
                },
            },
        },
        owner: {
            type: 'object',
            properties: {
                ownerRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                ownerRequired: {
                    oneOf: [
                        {
                            required: ['otherOwner'],
                            properties: {
                                ownerRequired: {
                                    enum: [false],
                                },
                                otherOwner: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                ownerRequired: {
                                    enum: [true],
                                },
                            },
                        },
                    ],
                },
            },
        },

        finance: {
            type: 'object',
            properties: {
                financeOwing: {
                    type: 'boolean',
                },
            },
            required: ['financeOwing'],
            dependencies: {
                financeOwing: {
                    oneOf: [
                        {
                            properties: {
                                financeOwing: {
                                    enum: [false],
                                },
                            },
                        },
                        {
                            properties: {
                                financeOwing: {
                                    enum: [true],
                                },
                                financeCompany: {
                                    type: 'string',
                                },
                                financeOutstanding: {
                                    type: 'string',
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
};
