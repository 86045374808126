export default {
    type: 'object',
    required: ['owner', 'email', 'phone'],
    properties: {
        owner: {
            type: 'string',
        },
        email: {
            type: 'string',
            format: 'email',
        },
        phone: {
            type: 'string',
        },
        clientRefNo: {
            type: 'string',
        },
        nzmcaMembershipNo: {
            type: 'string',
        },
        addressLine1: {
            type: 'string',
        },
        addressLine2: {
            type: 'string',
        },
        suburb: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        postCode: {
            type: 'integer',
        },
    },
};
