import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';

const Payment = ({ saveContinue, saveLocalData, transformErrors, quoteId, getQuoteData }) => {
    const localData = JSON.parse(window.localStorage.getItem(`dealerQuote-${quoteId}`));
    const formData = localData && localData.payment ? localData.payment : {};
    const policyDetails = localData.policy;

    const [quoteValue, setQuoteValue] = useState(0);
    const [schemaData] = useState(schema);

    const loader =
        '<div class="lds-ring sm float"><div></div><div></div><div></div><div></div></div>';

    const onSubmit = async data => {
        const payment = data.formData;

        window.dataLayer.push({
            event: 'dealerQuoteSubmission',
            step: 5,
        });

        await saveLocalData({ payment });
        await saveContinue();
    };

    const formatNumber = number => (Math.round(number * 100) / 100).toFixed(2);

    useEffect(() => {
        const fields = document.querySelectorAll('.payment-enum')[0];
        const radios = fields.querySelectorAll('.radio');

        radios.forEach((radio, i) => {
            const input = radio.querySelectorAll('input')[0];
            const sibling = input.nextElementSibling;
            let text = sibling.innerHTML;
            let label = sibling.innerHTML;

            if (i === 0) {
                text = `Annual - $${formatNumber(quoteValue.total?.Annual) ?? 'XXX'}`;
                label = `Annual - $${
                    !isNaN(formatNumber(quoteValue.total?.Annual))
                        ? formatNumber(quoteValue.total?.Annual)
                        : loader
                }`;
            } else if (i === 1) {
                text = `Monthly (12 payments) - $${formatNumber(quoteValue.total?.Month) ?? 'XXX'}`;
                label = `Monthly (12 payments) - $${
                    !isNaN(formatNumber(quoteValue.total?.Month))
                        ? formatNumber(quoteValue.total?.Month)
                        : loader
                }`;
            } else if (i === 2) {
                text = `Fortnightly (25 Payments) - $${formatNumber(quoteValue.total?.Fortnight) ??
                    'XXX'}`;
                label = `Fortnightly (25 Payments) - $${
                    !isNaN(formatNumber(quoteValue.total?.Fortnight))
                        ? formatNumber(quoteValue.total?.Fortnight)
                        : loader
                }`;
            } else if (i === 3) {
                text = `Weekly (50 Payments) - $${formatNumber(quoteValue.total?.Week) ?? 'XXX'}`;
                label = `Weekly (50 Payments) - $${
                    !isNaN(formatNumber(quoteValue.total?.Week))
                        ? formatNumber(quoteValue.total?.Week)
                        : loader
                }`;
            }
            sibling.innerHTML = label;
            input.value = text;
        });
    }, [quoteValue]);

    useEffect(() => {
        getQuoteData().then(data => {
            saveLocalData({
                quoteData: {
                    raw: formatNumber(data.quote.quote_value),
                    Annual: formatNumber(data.total.Annual),
                    Month: formatNumber(data.total.Month),
                    Fortnightly: formatNumber(data.total.Fortnight),
                    Week: formatNumber(data.total.Week),
                    administrationFee: formatNumber(data.total.administrationFee),
                    totalAdvances: formatNumber(
                        data.quote.quote_value + data.total.administrationFee
                    ),
                    interestCharges: data.total.interestCharges,
                    totalToPay: data.total.totalToPay,
                },
            });

            setQuoteValue(data);
        });
    }, []);

    function getMaxDays(str) {
        return str?.split(' ')[0] === 'Monthly' ? 35 : 21;
    }

    const getPaymentDateLimit = (policyDate, maxDays) => {
        const dateParts = policyDate.split('-');
        const date = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);

        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        const limitDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + maxDays);
        limitDate.setTime(limitDate.getTime() + limitDate.getTimezoneOffset() * 60 * 1000);
        return limitDate;
    };

    const getPaymentDateMinLimit = policyDate => {
        const dateParts = policyDate.split('-');
        const date = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);

        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        let limitDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
        limitDate.setTime(limitDate.getTime() + limitDate.getTimezoneOffset() * 60 * 1000);
        return limitDate;
    };

    function validate(formData, errors) {
        if (formData.paymentOption?.paymentOptionValue?.indexOf('Annual') === -1) {
            const dateParts = formData?.paymentOption.firstPaymentDate?.split('-');
            const jsFirstPaymentDate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
            const maxDays = getMaxDays(formData.paymentOption?.paymentOptionValue);

            jsFirstPaymentDate.setTime(
                jsFirstPaymentDate.getTime() + jsFirstPaymentDate.getTimezoneOffset() * 60 * 1000
            );

            if (jsFirstPaymentDate > getPaymentDateLimit(policyDetails.policyStartDate, maxDays)) {
                errors.paymentOption.firstPaymentDate.addError(
                    "First payment date can't be more than " +
                        maxDays +
                        ' days after the policy start date'
                );
            } else if (jsFirstPaymentDate < getPaymentDateMinLimit(policyDetails.policyStartDate)) {
                errors.paymentOption.firstPaymentDate.addError(
                    "First payment date can't be within 7 days of policy start date"
                );
            }
        }

        return errors;
    }

    return (
        <>
            <Form
                schema={schemaData}
                validate={validate}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onChange={value => {
                    const bankInput = document.getElementById('root_paymentOption_bankDetail_bank');
                    const branchInput = document.getElementById(
                        'root_paymentOption_bankDetail_branch'
                    );
                    const accountNumInput = document.getElementById(
                        'root_paymentOption_bankDetail_accountNumber'
                    );
                    const suffixInput = document.getElementById(
                        'root_paymentOption_bankDetail_suffix'
                    );
                    if (bankInput && !bankInput.hasAttribute('maxlength'))
                        bankInput.setAttribute('maxlength', '2');
                    if (branchInput && !branchInput.hasAttribute('maxlength'))
                        branchInput.setAttribute('maxlength', '4');
                    if (accountNumInput && !accountNumInput.hasAttribute('maxlength'))
                        accountNumInput.setAttribute('maxlength', '7');
                    if (suffixInput && !suffixInput.hasAttribute('maxlength'))
                        suffixInput.setAttribute('maxlength', '3');
                }}
                onError={err => {
                    console.log(err);
                    window.scrollTo(0, 0);
                }}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and Finish</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Payment.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Payment.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
    quoteId: PropTypes.string.isRequired,
    getQuoteData: PropTypes.func.isRequired,
};

export default Payment;
