export default {
    type: 'object',
    required: ['d1', 'd2', 'd3', 'd4', 'd5', 'd6', 'a1', 'a2', 'a3'],
    properties: {
        d1: {
            type: 'object',
            required: ['value'],
            properties: {
                value: {
                    type: 'boolean',
                },
            },
            dependencies: {
                value: {
                    oneOf: [
                        {
                            required: ['details'],
                            properties: {
                                value: { enum: [true] },
                                details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                value: { enum: [false] },
                            },
                        },
                    ],
                },
            },
        },

        d2: {
            type: 'object',
            required: ['value'],
            properties: {
                value: {
                    type: 'boolean',
                },
            },
            dependencies: {
                value: {
                    oneOf: [
                        {
                            required: ['details'],
                            properties: {
                                value: { enum: [true] },
                                details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                value: { enum: [false] },
                            },
                        },
                    ],
                },
            },
        },

        d3: {
            type: 'object',
            required: ['value'],
            properties: {
                value: {
                    type: 'boolean',
                },
            },
            dependencies: {
                value: {
                    oneOf: [
                        {
                            required: ['details'],
                            properties: {
                                value: { enum: [true] },
                                details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                value: { enum: [false] },
                            },
                        },
                    ],
                },
            },
        },

        d4: {
            type: 'object',
            required: ['value'],
            properties: {
                value: {
                    type: 'boolean',
                },
            },
            dependencies: {
                value: {
                    oneOf: [
                        {
                            required: ['details'],
                            properties: {
                                value: { enum: [true] },
                                details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                value: { enum: [false] },
                            },
                        },
                    ],
                },
            },
        },

        d5: {
            type: 'object',
            required: ['value'],
            properties: {
                value: {
                    type: 'boolean',
                },
            },
            dependencies: {
                value: {
                    oneOf: [
                        {
                            required: ['details'],
                            properties: {
                                value: { enum: [true] },
                                details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                value: { enum: [false] },
                            },
                        },
                    ],
                },
            },
        },

        d6: {
            type: 'object',
            required: ['value'],
            properties: {
                value: {
                    type: 'boolean',
                },
            },
            dependencies: {
                value: {
                    oneOf: [
                        {
                            required: ['details'],
                            properties: {
                                value: { enum: [true] },
                                details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                value: { enum: [false] },
                            },
                        },
                    ],
                },
            },
        },
        d7: {
            type: 'object',
            required: ['value'],
            properties: {
                value: {
                    type: 'boolean',
                },
            },
            dependencies: {
                value: {
                    oneOf: [
                        {
                            required: ['details'],
                            properties: {
                                value: { enum: [true] },
                                details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                value: { enum: [false] },
                            },
                        },
                    ],
                },
            },
        },

        disclosureHeading: { type: 'null', title: 'Duty of Disclosure' },
        disclosureContent: {
            type: 'null',
            title:
                'Prior to the commencement, variation or renewal of your insurance policy you are under a duty to disclose all information that may influence the decision to insure you, and if so, on what terms and/or premium.  If you do not disclose all such information your policy may be avoided which means it will be treated as though it never existed and any claims will not be payable.  If you have any doubt as to whether a fact is material then it should be disclosed.',
        },

        privacyActHeading: { type: 'null', title: 'Privacy Act 1993' },
        privacyActContent: {
            type: 'null',
            title:
                'Your personal information has been collected in order to evaluate your insurance requirements for the purpose of deciding whether to issue insurance cover and, if so, on what terms. Failure to provide any personal information requested may result in your application for insurance being declined.  Your personal information is held by Go Cover and/or your insurer. In accordance with the Privacy Act 1993, individuals have a right to request access to and correction of their personal information.',
        },

        agreementHeading: { type: 'null' },

        a1: {
            type: 'boolean',
            title:
                'Declare to the best of my/our knowledge all information provided in support of this application for insurance is correct and complete and there is no further relevant information to be disclosed, whether asked for or not;',
        },
        a2: {
            type: 'boolean',
            title:
                'I/We agree that this proposal and declaration shall be the basis of a contract between me/us and Lumley and to accept a policy subject to the terms, exceptions and conditions prescribed by Lumley.',
        },
        a3: {
            type: 'boolean',
            title:
                'Authorise Go Cover Car Insurance and/or our insurer to give to and obtain from other parties including Insurance Companies, Insurance Brokers and the Insurance Claims Register Ltd, any information relating to this or any other insurance held or previously held by you/us, and any claim(s) made by you/us.',
        },
        agreementNote: {
            type: 'null',
            title:
                'Agree this proposal, if accepted, will form the basis of the contract between me/us and our insurer, and I am/we are willing to accept cover subject to our insurer’s policy terms, conditions, exclusions and any special terms it may require.',
        },
    },
};
