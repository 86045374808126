import React from 'react';

export default {
    stepHeading: {
        classNames: 'field-step-heading',
        'ui:title': 'Vehicle Details',
        'ui:description': '02.',
    },
    stepDescription: {
        classNames: 'field-step-description',
        'ui:title': (
            <p>
                You can insure up to two vehicles in one application. If you have 3 or more, get in
                touch with the team on <a href="tel:0800805965">0800 805 965</a>.
            </p>
        ),
    },
    vehicles: {
        classNames: 'field-vehicles',
        'ui:options': {
            orderable: false,
        },
        items: {
            typeOfCover: {
                typeOfCoverType: {
                    classNames: 'field-parent field-select',
                    'ui:title': 'Type of Cover*',
                    'ui:description': (
                        <p>
                            Need cover for 3 or more vehicles? Call us for a quote on{' '}
                            <a href="tel:0800456254">0800 456 254</a>
                        </p>
                    ),
                    'ui:placeholder': 'Please select',
                    'ui:widget': 'select',
                },
                specifiedDriver: {
                    'ui:title': 'Specified Driver*',
                    'ui:description':
                        'This policy allows for a main driver and two specified drivers. Only one specified driver can be under the age of 25.',
                    'ui:widget': 'radio',
                },
            },
            namedDriverPlusPolicy: {
                'ui:title': 'Named Driver Plus Policy*',
                'ui:description':
                    'This policy allows for two named drivers only. Unnamed drivers covered subject to an additional $300 excess.',
                'ui:widget': 'radio',
            },
            registrationVIN: {
                classNames: 'search-input',
                'ui:title': 'Registration or VIN number*',
                'ui:placeholder': 'Enter the vehicle identifier',
            },
            make: {
                'ui:title': 'Make*',
                'ui:placeholder': 'Enter the make, e.g. Toyota',
            },
            model: {
                'ui:title': 'Model*',
                'ui:placeholder': 'Enter the model e.g. Corolla',
            },
            manufactureYear: {
                classNames: 'field-select',
                'ui:widget': 'select',
                'ui:title': 'Year of Manufacture*',
                'ui:placeholder': 'Pick the year of manufacture',
            },
            value: {
                'ui:title': 'Value of Vehicle*',
            },
            storage: {
                storageType: {
                    classNames: 'field-parent field-select',
                    'ui:title': 'Where is your vehicle usually stored?*',
                    'ui:placeholder': 'Pick the vehicles main storage type',
                    'ui:widget': 'select',
                },
                storageOther: {
                    'ui:title': 'Please provide details*',
                },
            },
            hasAlarm: {
                'ui:title': 'Does this vehicle have an alarm?*',
                'ui:widget': 'radio',
            },
            hasImmobilizer: {
                'ui:title': 'Does this vehicle have an immobilizer?*',
                'ui:widget': 'radio',
            },
            modifications: {
                modificationsRequired: {
                    classNames: 'field-parent',
                    'ui:title': 'Modifications*',
                    'ui:widget': 'radio',
                },
                modificationsDetails: {
                    'ui:title': 'Please provide details*',
                },
            },
            financeOwning: {
                financeOwningRequired: {
                    classNames: 'field-parent',
                    'ui:title': 'Is there any finance owing on the vehicle?*',
                    'ui:widget': 'radio',
                },
                financeOwningName: {
                    'ui:title': 'What is the name of the finance company?',
                },
                financeOwningDetails: {
                    'ui:title': 'Please provide details',
                },
            },
            storageAddress: {
                storageAddressRequired: {
                    classNames: 'field-parent',
                    'ui:title': "Will the vehicle be kept at the primary policy holder's address?",
                    'ui:widget': 'radio',
                },
                storageAddress_addressLine1: {
                    'ui:title': 'Please provide the address of where the vehicle will be kept.',
                    'ui:description': 'What is the driver’s address?',
                },
                storageAddress_addressLine2: {
                    'ui:title': 'Address line 2 (Optional)',
                },
                storageAddress_suburb: {
                    'ui:title': 'Suburb',
                },
                storageAddress_city: {
                    'ui:title': 'City',
                },
                storageAddress_postcode: {
                    'ui:title': 'Postcode',
                },
            },
        },
    },
};
