import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import { WidgetInput } from 'react-addressfinder';
import schema from './schema';
import uiSchema from './uiSchema';
import { PHONE_NUMBERS_VALIDATION_MESSAGE } from '../../../../../config';

const Policy = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('accident'));

    const [formData, setFormData] = useState(localData && localData.policy ? localData.policy : {});
    const [address, setAddress] = useState({});
    //
    const formContext = { address, setState: setAddress };

    useEffect(() => {
        setFormData({ ...formData, ...address });
    }, [address]);

    const widgets = {
        // eslint-disable-next-line no-use-before-define
        addressLookup,
    };

    const validate = (data, errors) => {
        const { phone } = data;

        if (phone && (phone.length < 8 || phone.length > 15)) {
            errors.phone.addError(PHONE_NUMBERS_VALIDATION_MESSAGE);
        }

        return errors;
    };

    const onSubmit = data => {
        const policy = data.formData;

        window.dataLayer.push({
            event: 'accidentClaimSubmission',
            step: 1,
            name: policy.fullName,
            email: policy.email,
            phone: policy.phone,
            address: `${policy.addressLine1} ${policy.addressLine2}`,
            suburb: policy.suburb,
            city: policy.city,
            postcode: policy.postCode,
            insurance_additional_cover: policy.otherInsurance.hasOtherInsurance,
        });

        saveLocalData({ policy });
        saveContinue();
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">1. Policy Holder</h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
                widgets={widgets}
                formContext={formContext}
                validate={validate}
                onChange={data => setFormData(data.formData)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Policy.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Policy.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Policy;

export const addressLookup = props => {
    const handleInputChange = (fullAddress, address) => {
        // Use formContext to update the customField's value
        props.formContext.setState({
            fullAddress,
            addressSearch: fullAddress,
            suburb: address.suburb || '',
            city: address?.city || address.state,
            addressLine1: address.line1,
            addressLine2: address.line2,
            postCode: Number(address.postcode),
        });
    };
    return (
        <div className="autocomplete-searchwrapper">
            <WidgetInput
                addressFinderKey="6XJY4EAQLUFH8P93DRK7"
                name="addressLookup"
                id="addressLookup"
                country="NZ"
                autoComplete="off"
                placeholder="Enter full residential address"
                onSelected={(fullAddress, address) => {
                    handleInputChange(fullAddress, address);
                }}
                // onBlur={({ target }) =>
                //     handleBlur({ name: 'addressSearch', value: target.value })
                // }
            />
        </div>
    );
};
