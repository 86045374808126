import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import convert from 'xml-js';
import schema from './schema.js'; // eslint-disable-line
import uiSchema from './uiSchema';

const Vehicle = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('theft'));
    const [formData, setFormData] = useState(
        localData && localData.vehicle ? localData.vehicle : {}
    );

    const [vehicleData, setVehicleData] = useState({});

    const formContext = { formData, setFormData, vehicleData, setState: setVehicleData };

    useEffect(() => {
        setFormData({ ...formData, ...vehicleData });
    }, [vehicleData]);
    //
    const widgets = {
        // eslint-disable-next-line no-use-before-define
        vehicleLookup,
    };

    const onSubmit = data => {
        const vehicle = data.formData;

        window.dataLayer.push({
            event: 'theftClaimSubmission',
            step: 2,
            vehicle_make: vehicle.make,
            vehicle_model: vehicle.model,
            vehicle_year_built: Number(vehicle.manufactureYear),
            vehicle_rego: vehicle.registration,
            condition_note_required: vehicle.conditionNote.conditionNoteRequired,
            modifications_note_required: vehicle.modifications.modificationsNoteRequired,
            // vehicle_endorsements_required: vehicle.endorsements.endorsementsRequired,
            vehicle_sole_owner: vehicle.owner.ownerRequired,
            vehicle_finance_owed: vehicle.finance.financeOwing,
        });

        saveLocalData({ vehicle });
        saveContinue();
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    2. Insured Vehicle
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={error => {
                    console.log(error);
                    window.scrollTo(0, 0);
                }}
                widgets={widgets}
                formContext={formContext}
                onChange={data => setFormData(data.formData)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Vehicle.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Vehicle.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Vehicle;

export const vehicleLookup = props => {
    function debounce(func, wait) {
        let timeout;
        return function(...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }

    const fetchCarData = async vin => {
        try {
            const response = await fetch(
                `https://carjam.co.nz/api/car/?key=32085C8102AEC1F33D9C83F52BBA9A28846D70D8&plate=${vin}`
            );

            let data = await response.text();
            data = convert.xml2json(data);
            data = JSON.parse(data);
            data = data.elements
                ? data.elements[0]?.elements?.find(e => e.name === 'idh')
                : { elements: [] };

            const vehicle = data?.elements?.find(e => e.name === 'vehicle').elements ?? [];
            return vehicle;
        } catch (e) {
            console.log('ERROR: ', e);
        }

        return null;
    };
    const handleInputChange = async event => {
        if (event.target.value && event.target.value.length >= 3) {
            const carData = await fetchCarData(event.target.value);
            const make = carData?.find(e => e.name === 'make')?.elements[0].text;
            const model = carData?.find(e => e.name === 'model')?.elements[0].text;
            const yearOfManufacture = carData?.find(e => e.name === 'year_of_manufacture')
                ?.elements[0].text;
            // const carVin = carData?.find(e => e.name === 'vin')?.elements[0].text;
            // const type = carData?.find(e => e.name === 'vehicle_type')?.elements[0].text;
            // const ccRating = carData?.find(e => e.name === 'cc_rating')?.elements[0].text;
            let regPlate = carData?.find(e => e.name === 'plates')?.elements;
            regPlate = regPlate?.find(e => e.name === 'registration_plate')?.elements[0]?.text;
            // Use formContext to update the customField's value

            if (
                regPlate &&
                (!props.formContext.vehicleData.regPlate ||
                    props.formContext.vehicleData.regPlate !== regPlate)
            ) {
                props.formContext.setState({
                    regPlate: regPlate,
                    make: make,
                    model: model,
                    manufactureYear: Number(yearOfManufacture),
                });
            }
        }
    };

    const handleChange = event => {
        // props.onChange(event.target.value);
        props.formContext.setFormData({
            ...props.formContext.formData,
            registration: event.target.value,
        });
        debounce(handleInputChange(event), 1000);
    };

    return (
        <input
            type="text"
            className="custom"
            value={props.value}
            required={props.required}
            onChange={value => handleChange(value)}
        />
    );
};
