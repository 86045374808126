export default {
    type: 'object',
    required: [
        'purchaseAmount',
        'purchaseDate',
        'anticipatedValuationMin',
        'anticipatedValuationMax',
    ],
    properties: {
        purchaseAmount: {
            type: 'integer',
        },
        purchaseDate: {
            type: 'string',
            format: 'date',
        },
        anticipatedValuationMin: {
            type: 'integer',
        },
        anticipatedValuationMax: {
            type: 'integer',
        },
        condition: {
            type: 'string',
            enum: ['New', 'Immaculate', 'Excellent', 'Tidy', 'Generally Okay'],
        },
    },
};
