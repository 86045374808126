import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';

const Insured = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('proposal'));
    const formData = localData && localData.insured ? localData.insured : {};

    const onSubmit = data => {
        const insured = data.formData;

        const policyHoldersData = [
            {
                name: `${insured.policyHolder.basicInfo.firstName} ${insured.policyHolder.basicInfo.lastName}`,
                date_of_birth: insured.policyHolder.basicInfo.dob,
                email: insured.policyHolder.email,
                phone: insured.policyHolder.phone,
                address: `${insured.policyHolder.addressLine1} ${insured.policyHolder.addressLine2}`,
                suburb: insured.policyHolder.suburb,
                city: insured.policyHolder.city,
                postcode: insured.policyHolder.postCode,
            },
        ];

        if (insured.additionalPolicyHolders) {
            insured.additionalPolicyHolders.forEach(policyHolder => {
                policyHoldersData.push({
                    name: `${policyHolder.firstName} ${policyHolder.lastName}`,
                    date_of_birth: policyHolder.dob,
                });
            });
        }

        // window.dataLayer.push({
        //     event: 'proposalFormSubmission',
        //     step: 1,
        //     policy_holders: policyHoldersData,
        //     nzmca_member: insured.policyHolder.nzmca.isMember,
        //     nzmca_number: insured.policyHolder.nzmca.membershipNumber,
        //     nz_resident: insured.policyHolder.nzResident,
        //     vehicle_permanent_location:
        //         insured.policyHolder.permanentResidence.isPermanentResidence,
        // });

        saveLocalData({ insured });
        saveContinue();
    };

    /**
     * Validate policy holder is over 25 years of age
     * @param data
     * @param errors
     * @returns {*}
     */
    const validate = (data, errors) => {
        const ageErrorMessage =
            'Our policies cover drivers who are under the age of 25yrs, but you will need to be approved by our underwriter first. If you would like a quote please call the team directly on 0800 805 965';

        const validAge = dob => {
            const currentYear = new Date().getFullYear();
            const cutoffDate = new Date().setFullYear(currentYear - 25);
            const dobDate = new Date(dob);

            return dobDate <= cutoffDate;
        };

        if (
            data.policyHolder &&
            data.policyHolder.basicInfo.dob &&
            validAge(data.policyHolder.basicInfo.dob) === false
        ) {
            errors.policyHolder.basicInfo.dob.addError(ageErrorMessage);
        }

        if (data.additionalPolicyHolders) {
            data.additionalPolicyHolders.forEach((additionalPolicyHolder, index) => {
                if (
                    additionalPolicyHolder &&
                    additionalPolicyHolder.dob &&
                    validAge(additionalPolicyHolder.dob) === false
                ) {
                    errors.additionalPolicyHolders[index].dob.addError(ageErrorMessage);
                }
            });
        }

        return errors;
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    01. Policy Holder
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                validate={validate}
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Insured.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Insured.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Insured;
