export default {
    type: 'object',
    required: [
        'paymentDispute',
        'furtherInformation',
        'authorisation1',
        'authorisation2',
        'authorisation3',
        'authorisation4',
    ],
    properties: {
        stepNoteContent: {
            type: 'null',
            title:
                'Your personal information has been collected for the purpose of evaluating your claim in accordance with the terms of your policy. Failure to provide any correct and complete information, whether requested or not, may result in your claim being declined.',
        },

        additionalInformation: {
            type: 'object',
            required: ['hasAdditionalInformation'],
            properties: {
                hasAdditionalInformation: {
                    type: 'boolean',
                },
            },
            dependencies: {
                hasAdditionalInformation: {
                    oneOf: [
                        {
                            required: ['details'],
                            properties: {
                                hasAdditionalInformation: { enum: [true] },
                                details: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                hasAdditionalInformation: { enum: [false] },
                            },
                        },
                    ],
                },
            },
        },

        paymentDispute: {
            type: 'boolean',
            title:
                'I/We declare that to the best of my/our knowledge the information provided in support of the claim is correct and complete in all ways and there is no further information relevant to the claim.',
        },
        furtherInformation: {
            type: 'boolean',
            title: 'I/We agree to provide any further information that may be required;',
        },
        authorisation1: {
            type: 'boolean',
            title:
                'Authorise the disclosure and obtaining, of my/our personal information in respect of this claim, to and from parties including the NZ Police, insurers, insurance intermediaries and other members of the Insurance Industry, the insurance Claims Register PO Box 474, Wellington (where information is retained and made available to other insurers) and other parties relevant to your claim including those with a financial interest in, and/or involved in the repairing or replacing of, the subject matter of the claim.',
        },
        authorisation2: {
            type: 'boolean',
            title:
                'Understand I/we have certain rights of access to and correction of my/our personal information pursuant to the Privacy Act 1993; and',
        },
        authorisation3: {
            type: 'boolean',
            title:
                'All information collected will be held by Go Cover Car Insurance, 660 Great South Road, Greenlane, Auckland and/or the Insurer.',
        },
        authorisation4: {
            type: 'boolean',
            title: 'I have read and understand the above declaration',
        },
    },
};
