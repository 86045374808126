import React from 'react';

export default {
    stepDescription: {
        classNames: 'field-step-description',
        'ui:title':
            'Allow up to 2 additional drivers. For each driver we need: Full Name, DOB, Licence Type, % of use',
        'ui:description': (
            <p>
                Add up to 2 additional drivers have been added, please show message: Need cover for
                more then 3 drivers? Call us on <a href="tel:0800847847">0800 847 847</a>
            </p>
        ),
    },

    primaryDriver: {
        stepSubheading: {
            classNames: 'field-step-subheading',
            'ui:title': 'Primary Driver',
        },

        insuredInCharge: {
            classNames: 'field-parent',
            'ui:title': 'Is the primary policy holder also a driver of the vehicle?',
            'ui:widget': 'radio',
        },

        license: {
            type: {
                classNames: 'field-select',
                'ui:title': 'What type of licence is held?',
                'ui:placeholder': 'Pick the licence type',
                'ui:widget': 'select',
            },

            country: {
                classNames: 'field-select',
                'ui:title': 'What is the country of issue?',
                'ui:placeholder': 'Pick the country of issue',
                'ui:widget': 'select',
            },
        },

        driver: {
            fullName: {
                'ui:title': 'Full name*',
            },
            dob: {
                classNames: 'field-date',
                'ui:title': 'Date of Birth*',
                'ui:widget': 'alt-date',
                'ui:options': {
                    hideNowButton: true,
                    hideClearButton: true,
                },
            },
            licenseType: {
                classNames: 'field-select',
                'ui:title': 'What type of licence is held?*',
                'ui:placeholder': 'Pick the licence type',
                'ui:widget': 'select',
            },
            licenseCountry: {
                classNames: 'field-select',
                'ui:title': 'What is the country of issue?*',
                'ui:placeholder': 'Pick the country of issue',
                'ui:widget': 'select',
            },
            percentageUsed: {
                'ui:title': '% of use*',
            },
        },
    },

    additionalDrivers: {
        classNames: 'field-drivers',
        'ui:options': {
            orderable: false,
        },
        items: {
            stepSubheading: {
                classNames: 'field-step-subheading',
                'ui:title': 'Additional Driver',
            },
            driver: {
                fullName: {
                    'ui:title': 'Full name*',
                },

                dob: {
                    classNames: 'field-date',
                    'ui:title': 'Date of Birth*',
                    'ui:widget': 'alt-date',
                    'ui:options': {
                        hideNowButton: true,
                        hideClearButton: true,
                    },
                },

                licenseType: {
                    classNames: 'field-select',
                    'ui:title': 'What type of licence is held?*',
                    'ui:placeholder': 'Pick the licence type',
                    'ui:widget': 'select',
                },

                licenseCountry: {
                    classNames: 'field-select',
                    'ui:title': 'What is the country of issue?*',
                    'ui:placeholder': 'Pick the country of issue',
                    'ui:widget': 'select',
                },

                percentageUsed: {
                    'ui:title': 'Percentage of vehicle use*',
                    'ui:placeholder': 'Pick the percentage',
                },
            },
        },
    },
};
