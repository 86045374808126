export const financeCompaniesWithPlaceholder = [
    'Please choose a financing company',
    'Access Finance',
    'Ahura Imports Ltd',
    // 'Alexander Motors 2015 Ltd',
    'Alphera Finance',
    'ANZA Finance',
    'Aotea Finance',
    'Aotearoa Credit Union',
    // 'Aqua Finance',
    'ASB Bank',
    'Asset Finance',
    // 'Austin Motor Company',
    'Auto Finance Direct',
    'Auto Funds Ltd',
    'Avanti Finance Ltd',
    // 'Bankrupt Vehicle Sales & Finance',
    'Basecorp Finance',
    'Baywide Credit Union',
    // 'Bee Finance',
    'Better Finance',
    'BMW Finance',
    'Botany Finance',
    'Branded Financial Services ',
    'Bridge Rent to Buy Cars Ltd',
    'Broadlands Finance Ltd',
    'Car Finance Ltd',
    'Car Finance.co.nz',
    // 'Cash on Carlyle',
    'Central Finance',
    'Central Finance Limited',
    'CFS Money',
    'Champion Finance Ltd',
    'Community Financial Services',
    'Credit House Finance Ltd',
    'Credit Union Baywide',
    'Credit Union Baywide T/A NZCU South',
    'Credit Union Manukau',
    'Crester Credit Co Ltd',
    'Daimler Chrysler',
    'Dealer Finance',
    // 'Direct Car Imports Ltd',
    'Discount Finance',
    'Dolbak Finance',
    'Dorchester Finance Ltd',
    'Double Ten Finance',
    'DTR New Lynn',
    'East Bay Finance',
    'EFCO Ltd',
    'Embassy Finance ',
    'European Financial Services Limited',
    'Fair City Finance Ltd',
    'Falcon Advances',
    'Family Finance',
    // 'Fast Track Finance',
    'Fico Finance',
    'Finance 4 U Ltd',
    'Finance Direct',
    'Finance Holdings',
    'Finance Now',
    'Financial Holdings Ltd ',
    'First Credit Union',
    'Fisher & Paykel Credit Union',
    'Future Finance Limited',
    'GE Money',
    'Geneva Finance',
    'Global Finance',
    'Go Car Finance',
    'Gold Band Finance',
    'Harnet & Milne',
    'Heartland Bank Ltd',
    'Holden Finance',
    'I Finance',
    'IFinance Cars and Loans Ltd',
    'Instant Finance - Dunedin',
    'Instant Finance - Lower Hutt',
    'Instant Finance Auckland City',
    'Instant Finance Christchurch',
    'Instant Finance Columbo Sreet Christchurch',
    'Instant Finance Hamilton',
    'Instant Finance Henderson',
    'Instant Finance Kaikohe',
    'Instant Finance Mangere',
    'Instant Finance Manukau Ltd',
    'Instant Finance New Lynn Ltd',
    'Instant Finance NZ Limited',
    'Instant Finance Onehunga',
    'Instant Finance Otahuhu Ltd',
    'Instant Finance Panmure Ltd',
    'Instant Finance Rotorua',
    'Instant Finance Takanini Ltd',
    'Instant Finance Whangarei',
    'Jade Finance',
    'KG Auto Lease',
    'Kings Car Finance',
    'Kiwi Auto Finance Ltd',
    // 'Kiwi Car Loans Ltd',
    'Latitude Financial Services',
    // 'LEASE2GO',
    'Limelight Financial Services Limited',
    'Loan Co',
    'Loansmart',
    'Mainland Finance Ltd',
    'Marac Finance',
    'Metro Finance',
    'Mitsubishi Motors Financial Services',
    'Mojo Finance (2015) Ltd',
    'Money Shop Henderson',
    'Money Shop Otahuhu',
    'Moneyshop Manukau Limited',
    'Motor Trade Finance',
    'Motor Trade Finance - Rickit Rd',
    // 'Motorcade Ltd',
    'MTF Te Awamutu',
    'MUAZ Holdings Ltd',
    'My Finance Ltd',
    'Nectar NZ Ltd',
    'Nelson Building Society',
    'Nissan Finance',
    'Northern Capital Finance Ltd',
    'NZ Credit Union',
    'NZ Credit Union Baywide',
    'NZ Finance 4U Limited',
    'NZ Fintech Ltd',
    'NZCU Auckland',
    'NZCU Baywide trading as NZCU South',
    'NZCU South',
    'Omega Finance Ltd',
    'Oxford Finance',
    'Pacific Finance Ltd',
    'Pacific Loans Limited',
    'Panorama Finance',
    'Personal Finance Ltd',
    'Personal Loan Corporation',
    'Pioneer Finance',
    'Rothbury  Funding Ltd as Premium Funder Only',
    'Samoa NZ Finance Co Ltd',
    'Second Chance Finance Ltd',
    'South Canterbury Finance Auckland',
    'South Island Vehicle Finance',
    'South Pacific Loans',
    'Southern Finance',
    'Stadium Finance',
    'Stortford Auto Sales Ltd',
    // 'Te Rapa Wholesale Cars',
    // 'The Car Clearance Centre',
    // 'Thorn Group Financial Services',
    // 'Thorn Group Financial Services ',
    'Toyota Financial Services Ltd',
    'Treeanne Holdings Limited',
    'UDC Finance Ltd',
    'Vehicle Sales & Finance',
    'We Care Finance Ltd',
    'West Force Credit Union',
    'Westpac Bank',
    'Yes Finance',
    'ZOOMA Finance',
];

export default [
    'Access Finance',
    'Ahura Imports Ltd',
    // 'Alexander Motors 2015 Ltd',
    'Alphera Finance',
    'ANZA Finance',
    'Aotea Finance',
    'Aotearoa Credit Union',
    // 'Aqua Finance',
    'ASB Bank',
    'Asset Finance',
    // 'Austin Motor Company',
    'Auto Finance Direct',
    'Auto Funds Ltd',
    'Avanti Finance Ltd',
    // 'Bankrupt Vehicle Sales & Finance',
    'Basecorp Finance',
    'Baywide Credit Union',
    // 'Bee Finance',
    'Better Finance',
    'BMW Finance',
    'Botany Finance',
    'Branded Financial Services ',
    'Bridge Rent to Buy Cars Ltd',
    'Broadlands Finance Ltd',
    'Car Finance Ltd',
    'Car Finance.co.nz',
    // 'Cash on Carlyle',
    'Central Finance',
    'Central Finance Limited',
    'CFS Money',
    'Champion Finance Ltd',
    'Community Financial Services',
    'Credit House Finance Ltd',
    'Credit Union Baywide',
    'Credit Union Baywide T/A NZCU South',
    'Credit Union Manukau',
    'Crester Credit Co Ltd',
    'Daimler Chrysler',
    'Dealer Finance',
    // 'Direct Car Imports Ltd',
    'Discount Finance',
    'Dolbak Finance',
    'Dorchester Finance Ltd',
    'Double Ten Finance',
    'DTR New Lynn',
    'East Bay Finance',
    'EFCO Ltd',
    'Embassy Finance ',
    'European Financial Services Limited',
    'Fair City Finance Ltd',
    'Falcon Advances',
    'Family Finance',
    // 'Fast Track Finance',
    'Fico Finance',
    'Finance 4 U Ltd',
    'Finance Direct',
    'Finance Holdings',
    'Finance Now',
    'Financial Holdings Ltd ',
    'First Credit Union',
    'Fisher & Paykel Credit Union',
    'Future Finance Limited',
    'GE Money',
    'Geneva Finance',
    'Global Finance',
    'Go Car Finance',
    'Gold Band Finance',
    'Harnet & Milne',
    'Heartland Bank Ltd',
    'Holden Finance',
    'I Finance',
    'IFinance Cars and Loans Ltd',
    'Instant Finance - Dunedin',
    'Instant Finance - Lower Hutt',
    'Instant Finance Auckland City',
    'Instant Finance Christchurch',
    'Instant Finance Columbo Sreet Christchurch',
    'Instant Finance Hamilton',
    'Instant Finance Henderson',
    'Instant Finance Kaikohe',
    'Instant Finance Mangere',
    'Instant Finance Manukau Ltd',
    'Instant Finance New Lynn Ltd',
    'Instant Finance NZ Limited',
    'Instant Finance Onehunga',
    'Instant Finance Otahuhu Ltd',
    'Instant Finance Panmure Ltd',
    'Instant Finance Rotorua',
    'Instant Finance Takanini Ltd',
    'Instant Finance Whangarei',
    'Jade Finance',
    'KG Auto Lease',
    'Kings Car Finance',
    'Kiwi Auto Finance Ltd',
    // 'Kiwi Car Loans Ltd',
    'Latitude Financial Services',
    // 'LEASE2GO',
    'Limelight Financial Services Limited',
    'Loan Co',
    'Loansmart',
    'Mainland Finance Ltd',
    'Marac Finance',
    'Metro Finance',
    'Mitsubishi Motors Financial Services',
    'Mojo Finance (2015) Ltd',
    'Money Shop Henderson',
    'Money Shop Otahuhu',
    'Moneyshop Manukau Limited',
    'Motor Trade Finance',
    'Motor Trade Finance - Rickit Rd',
    // 'Motorcade Ltd',
    'MTF Te Awamutu',
    'MUAZ Holdings Ltd',
    'My Finance Ltd',
    'Nectar NZ Ltd',
    'Nelson Building Society',
    'Nissan Finance',
    'Northern Capital Finance Ltd',
    'NZ Credit Union',
    'NZ Credit Union Baywide',
    'NZ Finance 4U Limited',
    'NZ Fintech Ltd',
    'NZCU Auckland',
    'NZCU Baywide trading as NZCU South',
    'NZCU South',
    'Omega Finance Ltd',
    'Oxford Finance',
    'Pacific Finance Ltd',
    'Pacific Loans Limited',
    'Panorama Finance',
    'Personal Finance Ltd',
    'Personal Loan Corporation',
    'Pioneer Finance',
    'Rothbury  Funding Ltd as Premium Funder Only',
    'Samoa NZ Finance Co Ltd',
    'Second Chance Finance Ltd',
    'South Canterbury Finance Auckland',
    'South Island Vehicle Finance',
    'South Pacific Loans',
    'Southern Finance',
    'Stadium Finance',
    'Stortford Auto Sales Ltd',
    // 'Te Rapa Wholesale Cars',
    // 'The Car Clearance Centre',
    // 'Thorn Group Financial Services',
    // 'Thorn Group Financial Services ',
    'Toyota Financial Services Ltd',
    'Treeanne Holdings Limited',
    'UDC Finance Ltd',
    'Vehicle Sales & Finance',
    'We Care Finance Ltd',
    'West Force Credit Union',
    'Westpac Bank',
    'Yes Finance',
    'ZOOMA Finance',
];
