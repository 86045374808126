export default {
    type: 'object',
    required: ['criminalRecord', 'insuredLastFiveYears', 'vehicleType'],
    properties: {
        representedDealer: {
            type: 'string',
        },
        criminalRecord: {
            type: 'boolean',
        },
        insuredLastFiveYears: {
            type: 'boolean',
        },
        vehicleType: {
            type: 'boolean',
        },
    },
};
