const date = new Date();
const today = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];

export default {
    type: 'object',
    required: ['policyStartDate', 'policyType', 'roadsideAssistance'],
    properties: {
        policyStartDate: {
            type: 'string',
            format: 'date',
            default: today,
        },
        policyType: {
            type: 'object',
            required: ['policyTypeValue'],
            properties: {
                policyTypeValue: {
                    type: 'string',
                    enum: ['Specified', 'Named'],
                    enumNames: ['Specified Driver Policy', 'Named Driver Plus Policy'],
                },
            },
            dependencies: {
                policyTypeValue: {
                    oneOf: [
                        {
                            properties: {
                                policyTypeValue: {
                                    enum: ['Specified'],
                                },
                                stepSubheading: { type: 'null' },
                            },
                        },
                        {
                            properties: {
                                policyTypeValue: {
                                    enum: ['Named'],
                                },
                                stepSubheading2: { type: 'null' },
                            },
                        },
                    ],
                },
            },
        },
        roadsideAssistance: {
            type: 'array',
            items: {
                type: 'string',
                enum: ['Included'],
            },
            default: ['Included'],
            uniqueItems: true,
        },
    },
};
