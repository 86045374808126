export default {
    owner: {
        'ui:title': 'Full name of vehicle owner',
    },
    email: {
        'ui:title': 'Email address',
        'ui:options': {
            inputType: 'email',
        },
    },
    phone: {
        'ui:title': 'Phone number',
    },
    clientRefNo: {
        'ui:title': 'Client reference number (CL) (Optional)',
    },
    nzmcaMembershipNo: {
        'ui:title': 'NZMCA membership number (Optional)',
    },
    addressLine1: {
        'ui:title': 'Address line 1 (Optional)',
    },
    addressLine2: {
        'ui:title': 'Address line 2 (Optional)',
    },
    suburb: {
        'ui:title': 'Suburb (Optional)',
    },
    city: {
        'ui:title': 'City (Optional)',
    },
    postCode: {
        'ui:title': 'Postcode (Optional)',
    },
    otherInsurance: {
        hasOtherInsurance: {
            classNames: 'field-parent',
            'ui:title': 'Do you have any other insurance on the vehicle or accessories?',
            'ui:widget': 'radio',
        },
        otherInsuranceDetails: {
            'ui:title': 'Please describe them',
        },
    },
};
