const BannerCheckID = '.alert-flag';
const BannerWrapper = '.alertBanner';

const BANNER_NODE = document.querySelector(BannerCheckID);

const AlertBanner = {
    bindEvents: () => {
        // If banner node is undefined, return
        if (typeof BANNER_NODE === 'undefined' || BANNER_NODE === null) return;
        BANNER_NODE.addEventListener('change', ev => {
            // On checked state, set localstarage to true
            if (ev.target.checked) {
                sessionStorage.setItem('alertBanner', 'true');
                if (document.querySelector(BannerWrapper)) {
                    document.querySelector(BannerWrapper).remove();
                }
            } else {
                sessionStorage.setItem('alertBanner', 'false');
            }
        });

        // If localstorage is true, hide banner
        if (sessionStorage.getItem('alertBanner') === 'true') {
            // Set banner node to checked
            BANNER_NODE.checked = true;
            if (document.querySelector(BannerWrapper)) {
                document.querySelector(BannerWrapper).remove();
            }
        }
    },
};

export default AlertBanner;
