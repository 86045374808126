const formHandler = {
    bindEvents: () => {
        const registerButton = document.getElementById('Form_RegisterForm_action_register');
        const loginButton = document.getElementById('CustomMemberLogin_LoginForm_action_doLogin');
        const form = document.querySelector('form');

        if (form) {
            form.addEventListener(
                'submit',
                function() {
                    if (registerButton) {
                        registerButton.setAttribute('disabled', 'disabled');
                        registerButton.innerHTML = '<span>Processing</span>';
                    } else if (loginButton) {
                        loginButton.setAttribute('disabled', 'disabled');
                        loginButton.innerHTML = '<span>Processing</span>';
                    } else {
                        const buttons = this.querySelectorAll('button[type="submit"]');
                        buttons.forEach(function(button) {
                            button.setAttribute('disabled', 'disabled');

                            setTimeout(function() {
                                button.removeAttribute('disabled');
                            }, 1000);
                        });
                    }
                },
                false
            );
        }
    },
};

export default formHandler;
