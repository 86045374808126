const dateRange = (start, end) =>
    Array.from({ length: (end - start) / 1 + 1 }, (_, i) => start + i * 1).reverse();

export default {
    type: 'object',
    required: ['owner'],
    properties: {
        registration: {
            type: 'string',
        },
        make: {
            type: 'string',
        },
        model: {
            type: 'string',
        },

        manufactureYear: {
            type: 'integer',
            enum: dateRange(1900, new Date().getFullYear()),
        },

        condition: {
            type: 'object',
            properties: {
                hasDefects: {
                    type: 'boolean',
                },
            },
            dependencies: {
                hasDefects: {
                    oneOf: [
                        {
                            properties: {
                                hasDefects: {
                                    enum: [false],
                                },
                            },
                        },
                        {
                            required: ['details'],
                            properties: {
                                hasDefects: {
                                    enum: [true],
                                },
                                details: {
                                    type: 'string',
                                },
                            },
                        },
                    ],
                },
            },
        },
        modifications: {
            type: 'object',
            // required: ['modificationsRequired'],
            properties: {
                modificationsRequired: {
                    type: 'boolean',
                },
            },
            dependencies: {
                modificationsRequired: {
                    oneOf: [
                        {
                            required: ['modificationsDetails'],
                            properties: {
                                modificationsRequired: {
                                    enum: [true],
                                },
                                modificationsDetails: {
                                    type: 'string',
                                },
                            },
                        },
                        {
                            properties: {
                                modificationsRequired: {
                                    enum: [false],
                                },
                            },
                        },
                    ],
                },
            },
        },
        // endorsementsRequired: {
        //     type: 'boolean',
        // },

        owner: {
            type: 'object',
            required: ['soleOwner'],
            properties: {
                soleOwner: {
                    type: 'boolean',
                },
            },
            dependencies: {
                soleOwner: {
                    oneOf: [
                        {
                            properties: {
                                soleOwner: {
                                    enum: [true],
                                },
                            },
                        },
                        {
                            required: ['interestedParties', 'interestedPartiesContact'],
                            properties: {
                                soleOwner: {
                                    enum: [false],
                                },
                                interestedParties: {
                                    type: 'string',
                                },
                                interestedPartiesContact: {
                                    type: 'string',
                                },
                            },
                        },
                    ],
                },
            },
        },

        finance: {
            type: 'object',
            properties: {
                financeOwing: {
                    type: 'boolean',
                },
            },
            required: ['financeOwing'],
            dependencies: {
                financeOwing: {
                    oneOf: [
                        {
                            properties: {
                                financeOwing: {
                                    enum: [false],
                                },
                            },
                        },
                        {
                            properties: {
                                financeOwing: {
                                    enum: [true],
                                },
                                financeCompany: {
                                    type: 'string',
                                },
                                financeOutstanding: {
                                    type: 'string',
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
};
