export default {
    registration: {
        'ui:title': 'What is your vehicle registration number?',
        'ui:widget': 'vehicleLookup',
        'ui:placeholder': 'eg ABC123',
    },
    make: {
        'ui:title': 'Make',
        'ui:placeholder': 'Toyota',
    },
    model: {
        'ui:title': 'Model',
        'ui:placeholder': 'Corolla',
    },

    manufactureYear: {
        classNames: 'field-select',
        'ui:widget': 'select',
        'ui:title': "What was your vehicle's year of manufacture?",
        'ui:placeholder': 'Pick the year of manufacture',
    },

    condition: {
        hasDefects: {
            'ui:title': 'Did your car have any pre-existing damage prior to the accident?',
            'ui:widget': 'radio',
            classNames: 'field-parent',
        },
        details: {
            'ui:title': 'Please describe them*',
        },
    },
    modifications: {
        modificationsRequired: {
            classNames: 'field-parent',
            'ui:title': 'Does your vehicle have any modifications?',
            'ui:widget': 'radio',
        },
        modificationsDetails: {
            'ui:title': 'Please provide details*',
        },
    },
    owner: {
        soleOwner: {
            classNames: 'field-parent',
            'ui:title': 'Are you the sole owner of the vehicle?*',
            'ui:widget': 'radio',
        },

        interestedParties: {
            'ui:title': 'Who else owns this vehicle?*',
        },

        interestedPartiesContact: {
            'ui:title': 'What are the contact details of the other interested party/ies?*',
        },
    },

    finance: {
        financeOwing: {
            classNames: 'field-parent',
            'ui:title': 'Is there any finance owing on the vehicle?*',
            'ui:widget': 'radio',
        },

        financeCompany: {
            'ui:title': 'What is the name of the finance company?',
        },

        financeOutstanding: {
            'ui:title': 'If known, what amount is still outstanding to the finance company?',
        },
    },
};
