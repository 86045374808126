import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';

const Disclosures = ({ saveContinue, saveLocalData, transformErrors, quoteId }) => {
    const localData = JSON.parse(window.localStorage.getItem(`dealerQuote-${quoteId}`));
    const formData = localData && localData.disclosures ? localData.disclosures : {};

    const onSubmit = data => {
        const disclosures = data.formData;

        window.dataLayer.push({
            event: 'DealerQuoteSubmission',
            step: 4,
        });

        saveLocalData({ disclosures });
        saveContinue();
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <p>
                    Have you, your family, de facto partner or any other person or entity to be
                    covered by the insurance ever:
                </p>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
                onChange={value => {
                    const anyAccidentsOrLosses = document.getElementById(
                        'root_anyAccidentsOrLosses_anyAccidentsOrLosses_details'
                    );
                    const specialConditions = document.getElementById(
                        'root_specialConditions_specialConditions_details'
                    );
                    const licenceEndorsement = document.getElementById(
                        'root_licenceEndorsement_licenceEndorsement_details'
                    );
                    const convictionsOrFines = document.getElementById(
                        'root_convictionsOrFines_convictionsOrFines_details'
                    );
                    const insuranceDeclined = document.getElementById(
                        'root_insuranceDeclined_insuranceDeclined_details'
                    );
                    const criminalActivity = document.getElementById(
                        'root_criminalActivity_criminalActivity_details'
                    );
                    const anyFurtherInformation = document.getElementById(
                        'root_anyFurtherInformation_anyFurtherInformation_details'
                    );
                    if (anyAccidentsOrLosses && !anyAccidentsOrLosses.hasAttribute('maxlength'))
                        anyAccidentsOrLosses.setAttribute('maxlength', '100');
                    if (specialConditions && !specialConditions.hasAttribute('maxlength'))
                        specialConditions.setAttribute('maxlength', '100');
                    if (licenceEndorsement && !licenceEndorsement.hasAttribute('maxlength'))
                        licenceEndorsement.setAttribute('maxlength', '100');
                    if (convictionsOrFines && !convictionsOrFines.hasAttribute('maxlength'))
                        convictionsOrFines.setAttribute('maxlength', '100');
                    if (insuranceDeclined && !insuranceDeclined.hasAttribute('maxlength'))
                        insuranceDeclined.setAttribute('maxlength', '100');
                    if (criminalActivity && !criminalActivity.hasAttribute('maxlength'))
                        criminalActivity.setAttribute('maxlength', '100');
                    if (anyFurtherInformation && !anyFurtherInformation.hasAttribute('maxlength'))
                        anyFurtherInformation.setAttribute('maxlength', '100');
                }}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Disclosures.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Disclosures.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
    quoteId: PropTypes.string.isRequired,
};

export default Disclosures;
