import React from 'react';
import PropTypes from 'prop-types';

const FormDownload = ({ path, sentToSecuredSigning }) => {
    return (
        <>
            <div>
                {sentToSecuredSigning ? (
                    <p>
                        This document has been sent to Secured Signing. The Go Cover team will be in
                        touch within the next 7 days
                    </p>
                ) : (
                    <p>
                        This document has been sent to the customers email address. Please advise
                        them to digitally sign and return it.
                    </p>
                )}
                <embed src={path} width="500" height="775" type="application/pdf" />
            </div>
            <div className="pdf-actions">
                <a className="download-button add-button--primary" href={path} download>
                    <span>Download PDF</span>
                </a>
                <a className="return-button add-button--primary" href="/dealer">
                    <span>Return to proposals</span>
                </a>
            </div>
        </>
    );
};

FormDownload.propTypes = {
    path: PropTypes.string.isRequired,
    sentToSecuredSigning: PropTypes.bool.isRequired,
};

export default FormDownload;
