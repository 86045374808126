import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';

const Declaration = ({ saveFinish, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('accident'));
    const formData = localData && localData.declaration ? localData.declaration : {};

    const onSubmit = data => {
        const declaration = data.formData;

        window.dataLayer.push({
            event: 'accidentClaimSubmission',
            step: 6,
            declaration_additional_information:
                declaration.additionalInformation.hasAdditionalInformation,
        });

        saveLocalData({ declaration: data.formData });
        saveFinish();
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">6. Declaration</h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Declaration.defaultProps = {
    saveFinish: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Declaration.propTypes = {
    saveFinish: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Declaration;
